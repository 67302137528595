import React from "react";
import { Link } from "react-router-dom";
import "../../pages/prayerBooking/prayerbooking.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function Priests() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [priestData, setPriestData] = React.useState([]);
  const [selectedPriest, setSelectedPriest] = React.useState(null);

  const getPriests = async () => {
    const response = await getApi(apiEndPoints.getPriests);
    if (response && response.status === 200) {
      setPriestData(response?.data);
    }
  };

  React.useEffect(() => {
    getPriests();
  }, []);

  const handleClickOpen = (priest) => () => {
    setSelectedPriest(priest);
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle id="scroll-dialog-title">
            {selectedPriest?.priestname}
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <img
                  src={selectedPriest?.image}
                  alt={"image"}
                  className="img-responsive cursor"
                  width="100%"
                />
              </Grid>
              <Grid item xs={7}>
                <DialogContentText
                  className="pop-content"
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedPriest?.description,
                    }}
                  />{" "}
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Priests</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Priests</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Priests</span>
          </h1>
        </div>

        <div className="row mt-5 g-4">
          <p>
            <br />
            To have Hindu religious services performed in your home or Durga
            Temple, please contact the temple at 703-690-9355 with any of the
            aforementioned priests.
          </p>
          {priestData &&
            priestData.length > 0 &&
            priestData.map((priest, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div
                  onClick={handleClickOpen(priest)}
                  className="service-item d-block rounded text-center h-100 p-3"
                >
                  <img
                    className="img-fluid rounded mb-3 cursor"
                    src={priest?.image}
                    alt={"image"}
                  />
                  <h4 className="mb-0 cursor">{priest?.priestname}</h4>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Priests;
