import React from "react";
import "./services.css";
import { Link } from "react-router-dom";

function Services(props) {
  const { services } = props;

  return (
    <>
      <div className="container-fluid py-4 home-ser">
        <div className="container pt-3">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Temple
            </h6>
            <h1 className="mb-1">
              <span className="text-secundary">Services</span>
            </h1>
          </div>
          <div className="container py-3">
            <div className="row g-3">
              {services?.map((item) => (
                <div key={item._id} className="col-lg-2 col-md-6">
                  <Link
                    className="service-item d-block rounded text-center h-100 p-2"
                    to={
                      item.slug === "donation"
                        ? "/donation"
                        : `/services/${item.slug}`
                    }
                  >
                    <img
                      className="img-fluid rounded mb-2"
                      src={item.image}
                      alt=""
                    />
                    <h4 className="mb-0 service-item2">{item.title}</h4>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
