import React from "react";
import { Link } from "react-router-dom";

function LocationDirection() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Location / Directions
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Visit Info</Link>
          </li>
          <li className="breadcrumb-item active">Location / Directions</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Location - Directions</span>
          </h1>
        </div>

        <div className="row">
          <h2 className="temple-title">Directions from 495/I-95 South:</h2>
          <p className="mb-4">
            From I-495 S (Capital Beltway), head towards Capitol
            Beltway/Richmond/Alexandria. Take exit 57A towards Richmond onto
            I-95 S (Henry G Shirley Memorial Hwy). Take exit 163 towards
            VA-642/Lorton. Drive for 0.3 miles and then turn right onto Lorton
            Rd (VA-642 W). Next, turn right onto Silverbrook Rd (VA-600),
            followed by a right onto Hooes Rd (VA-636).Turn left onto Copper
            Leaf Way and then left onto Durga Place. Finally, turn right to
            arrive at the destination: 8400 Durga Place, Fairfax Station, VA
            22039-3079.
          </p>
          <h2 className="temple-title">Directions from 395:</h2>
          <p>
            From I-395 S for 4.1 mi. Exit towards VA-644 W/Springfield, then
            keep right onto Backlick Rd for 0.1 mi. Turn left onto Bland St,
            then onto Amherst Ave (VA-617 S) for 0.5 mi. Continue on Backlick Rd
            (VA-617 S) for 0.3 mi. Take the ramp onto Franconia-Springfield Pkwy
            (VA-289 W), stay on Fairfax County Pkwy (VA-286 N) for 2.1 mi. Take
            the ramp towards Hooes Rd/VA-636 S/Pohick Rd/VA-641 S for 0.3 mi.
            Turn left onto Hooes Rd for 1.2 mi. Right onto Copper Leaf Way, then
            left onto Durga Place.Finally, turn right to arrive at the
            destination: 8400 Durga Place, Fairfax Station, VA 22039-3079.
          </p>
          <h2 className="temple-title">Directions from Fairfax County Pkwy:</h2>
          <p>
            From VA-286 S (Fairfax County Pkwy), take the exit toward VA-123.
            Turn left onto Ox Rd (VA-123 S) and continue for about 4 miles.
            Then, turn left onto Silverbrook Rd (VA-600) and continue for about
            2 miles. Turn left onto Hooes Rd (VA-636) and continue for a short
            distance. Next, turn left onto Copper Leaf Way and then turn left
            onto Durga Place. Finally, turn right to arrive at the destination:
            8400 Durga Place, Fairfax Station, VA 22039-3079.
          </p>

          <h2 className="temple-title">
            Please also check directions on the Googe Map Link below:
          </h2>
          <a href="https://www.google.com/maps/@38.7121152,-77.2308992,14z?entry=ttu">
            https://www.google.com/maps/@38.7121152,-77.2308992,14z?entry=ttu
          </a>
        </div>
      </div>
    </>
  );
}

export default LocationDirection;
