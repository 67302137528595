import * as React from "react";
import "./forgotpassword.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ForgotPasswordSent() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Forgot Password</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/login">Login</Link>
          </li>
          <li className="breadcrumb-item active">Forgot Password</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Devotee
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Forgot Password</span>
          </h1>
        </div>
        <div className="row">
          <Container component="main" maxWidth="sm">
            <Typography variant="body1" className="mt-4">
              Enter your Username of Devotee Id below and we'll send you a link
              to reset your password.
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{ mt: 3 }}
              align="center"
              className="message-bg"
            >
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  className="mt-3 sent-color"
                >
                  Mail Sent
                </FormLabel>
                <Typography variant="body1" className="mt-1" align="center">
                  {location?.state}
                </Typography>
              </FormControl>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 2,
                }}
              >
                <Grid container className="btn-align">
                  <Button
                    className="cont-btn"
                    type="submit"
                    variant="contained"
                    onClick={() => navigate(-1)}
                    sx={{ mt: 3, mb: 2, p: 2 }}
                  >
                    Back
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordSent;
