import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

const TermsAndConditionsDialog = ({ handleTACButton }) => {
  const [open, setOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    setScrolling(bottom);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
    handleTACButton(!checked);
  };

  return (
    <>
      <Typography
        sx={{
          "& span": {
            cursor: "pointer",
            color: "var(--primary)",
          },
        }}
      >
        Please Read the
        <span onClick={handleOpen}> Terms and Conditions!</span>
      </Typography>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent
          dividers
          onScroll={handleScroll}
          style={{ height: "400px", overflowY: "auto" }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Durga Temple</p>
              <p>8400 Durga Place, Fairfax station, VA 22039</p>
            </div>
            <div>
              <p>1 February, 2023</p>
              <p>https://www.durgatemple.org</p>
              <p>Tel no: 703 690 9355</p>
            </div>
          </div>

          <div>
            <u>
              <p className="fw-bold">
                CULTURAL HALL/CLASSROOMS USE TERMS & CONDITIONS
              </p>
            </u>
            <hr />
            <span>
              <b>1. </b> Rental reservations can be made a maximum of six months
              in advance.
            </span>
            <br />
            <span>
              <b>2. </b> On Sundays, the use of the Cultural Hall and classrooms
              is designated for Education. If these facilities are available for
              rent for other use, they must be cleaned, restored to original
              configuration and Vacated by NO LATER than 3:00 PM.{" "}
            </span>
            <br />
            <span>
              <b>3. </b> Unless special permission is granted, the Hall rental
              must be vacated by 10:00 PM- Mon. thru Sat.
            </span>
            <br />
            <span>
              <b>4. </b>Rental charges includes use of tables and chairs.
            </span>
            <br />
            <span>
              <b>5. </b>Audio/Sound System are available for an extra charge.
            </span>
            <br />
            <span>
              <b>6. </b>Set up and Cleanup must be completed within the reserved
              time. Please be considerate to other users.
            </span>
            <br />
            <span>
              <b>7. </b>Chairs and Tables etc. must be properly folded and
              stacked after use against the wall in the area outside the hall
              (between the two classrooms).
            </span>
            <br />
            <span>
              <b>8. </b> Trash must be removed from all areas of use including
              kitchen, It shall be deposited In the dumpster.
            </span>
            <br />
            <span>
              <b>9. </b>All decorations for the event shall be put up and
              removed at the end of the event by the user without any damage to
              the temple property (walls, ceilings, floor, windows etc.). No
              push pins or sticky tape Is to be used.
            </span>
            <br />
            <span>
              <b>10. </b>No permanently anchored fixtures will be removed
              without permission of the management.
            </span>
            <br />
            <span>
              <b>11. </b>The guidelines by the Kitchen/Bhandara coordinator for
              kitchen use must be followed.
            </span>
            <br />
            <span>
              <b>12. </b>Alcohol and/or non-vegetarian food shall not be served
              In the hall or anywhere else on the temple premises/compound, Use
              of onion and garlic is prohibited in cooking.
            </span>
            <br />
            <span>
              <b>13. </b>The security deposit check will be returned only if
              hall/classtooms are left In good condition.
            </span>
            <br />
            <span>
              <b>14. </b> Stage is not to used other than stage shows. No sticky
              tape/nails are to be used at the stage.
            </span>
            <br />
            <span>
              <b>15. </b>Durga Temple is not responsible for any item left
              in.the temple after the event.
            </span>
          </div>
          <br />
          <div>
            <u>
              <p className="fw-bold">CANCELLATION</p>
            </u>
            <hr />
            <span>
              <b>1. </b>Should the temple need the hall or classroom for a
              temple sponsored event, a booked space may be cancelled by the
              temple management. At least four months In advance notice will be
              given and money will be refunded.
            </span>
            <br />
            <span>
              <b>2. </b>Other reservation cancellations must be made In writing,
              Rental fee will be refunded as follows: 25% If 2 weeks notice or
              less; 50% with 2. and 4 weeks notice; 75% with 5 and 9 weeks
              notice; a full refund with 10 weeks notice. All security deposit
              will be refunded In case of cancellation.
            </span>
          </div>
          <br />

          <div>
            <u>
              <p className="fw-bold">DISCLAIMER</p>
            </u>
            <hr />
            <span>
              Lessee (person/group renting the hall and/or classrooms)
              understand and expressly agree that Durga Temple (Lesser) shall
              not be responsible or liable for any loss or damage to property or
              person or injury of any kind whatsoever or loss of life to lessee
              or any person associated or may become associated with lessee in
              any manner, directly or indirectly Including but not limited to
              agent, employee, representative, any hired person or worker,
              volunteer, supporter in any capacity, helper, assistant, personal
              or business associate, partner, attendee, invitee, guest, family
              member and friend. Lessee assumes any kind and all responsibility
              for any loss.or damage to property or offense or Injury of any
              kind whatsoever, loss of life to anyone who visits Durga Temple
              premises or compound due to Lessee’s renting.or occupying the
              temple premises or compound.
            </span>
          </div>
        </DialogContent>
        <DialogActions>
          {scrolling ? (
            <FormControlLabel
              control={
                <Checkbox checked={checked} onChange={handleCheckboxChange} />
              }
              label="I have read and understood the terms"
            />
          ) : (
            "Read the whole document to the bottom"
          )}

          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsAndConditionsDialog;
