import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "../../../ApiServices/api";
import { apiEndPoints } from "../../../ApiServices/config";
import { useSelector } from "react-redux";
export const useNavigationFunctions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [donationData, setDonationData] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showDropdown, setShowDropdown] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const currentUrlPath = location.pathname;

  const dropdownRef = useRef(null);
  const cartArr = useSelector((state) => state.cartArr);
  const rentCartArr = useSelector((state) => state.rentArr);
  const totalCartCount = cartArr?.length || 0 + rentCartArr?.length || 0;
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const hasToken = localStorage.getItem("token");

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleLogout = () => {
    localStorage.removeItem("devoteeData");
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const getDonations = async () => {
    const response = await getApi(apiEndPoints.getAllDonations);
    if (response && response.status === 200) {
      setDonationData(response?.data?.data);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (showDropdown) {
      const handleLinkClick = () => setShowDropdown(false);
      document.querySelectorAll(".dropdown-item").forEach((item) => {
        item.addEventListener("click", handleLinkClick);
      });

      return () => {
        document.querySelectorAll(".dropdown-item").forEach((item) => {
          item.removeEventListener("click", handleLinkClick);
        });
      };
    }
  }, [showDropdown]);

  useEffect(() => {
    getDonations();
  }, []);

  return {
    location,
    donationData,
    windowWidth,
    showDropdown,
    dropdownRef,
    devoteeData,
    hasToken,
    expanded,
    setExpanded,
    totalCartCount,
    currentUrlPath,
    toggleDropdown,
    handleLogout,
  };
};

export const useLoginFunctions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasToken = localStorage.getItem("token");
  const [showDropdown, setShowDropdown] = useState(false);
  const currentUrlPath = location.pathname;
  const dropdownRef = useRef(null);
  const devoteeData = localStorage.getItem("devoteeData")
    ? JSON.parse(localStorage.getItem("devoteeData"))
    : "";

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("devoteeData");
    localStorage.removeItem("token");

    navigate(currentUrlPath);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return {
    hasToken,
    showDropdown,
    devoteeData,
    dropdownRef,
    currentUrlPath,
    toggleDropdown,
    handleLogout,
  };
};
