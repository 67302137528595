import React, { useEffect } from "react";
import "./balgokul.css";
import { Link } from "react-router-dom";
import useLoadMasterDataBalGokul from "./customHooks";

function BalGokul() {
  const { masterData, getMasterData } = useLoadMasterDataBalGokul();
  useEffect(() => {
    getMasterData();
  }, []);
  const htmlContent = masterData;

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">BalGokul</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Events & Activities</Link>
          </li>
          <li className="breadcrumb-item active">BalGokul</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">BalGokul</span>
          </h1>
        </div>
        <div className="row text-center">
          {/* <div className="mb-4 text-end">
            <Button variant="contained" color="success" className="me-3">
              Register Now!
            </Button>
            <Button variant="contained">Volunteer Signup!</Button>
          </div> */}
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
      </div>
    </>
  );
}

export default BalGokul;
