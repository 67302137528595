import { useState } from "react";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function useLoadMasterDataBalGokul() {
  const [masterData, setMasterData] = useState();
  const getMasterData = async () => {
    const response = await postApi(apiEndPoints.getBalGokul);
    if (response && response.status === 200) {
      setMasterData(response?.data?.data.data[0].description);
    }
  };

  return {
    masterData,
    getMasterData,
    setMasterData,
  };
}

export default useLoadMasterDataBalGokul;
