import React from "react";
import { Link } from "react-router-dom";

function PrasadamServices() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Prasadam Services
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Prasadam Services</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Prasadam
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Services</span>
          </h1>
        </div>
        <div className="row">
          <p>
            Durga Temple offers catering service for all your events in the
            temple or outside. For the complete list of catering and cafeteria
            menu items and to place an order please visit the following links.
            If you need further assistance or have any questions, please send an
            email to
            <Link to="mailto:contact@durgatemple.org">
              contact@durgatemple.org
            </Link>
            .
          </p>
          <ul className="list4">
            <li>
              <Link to="/catering">Catering Menu</Link>
            </li>
            <li>
              <Link to="/cafeteria">Cafeteria Menu</Link>
            </li>
          </ul>
          <p>
            If interested please sign up for volunteer activity and get your
            family members and friends involved. Durga Temple food committee
            member will email or call you to set up your scheduled day for food
            distribution.
          </p>
          <p>
            Cafeteria operating hours are: Friday from 6:00 pm to 9:00 pm,
            Saturday and Sunday between 9:30 am to 9:00 pm.
          </p>
        </div>
      </div>
    </>
  );
}

export default PrasadamServices;
