import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function YouthTruth() {
  const [youthTruth, setYouthTruth] = useState();
  const getYouthTruth = async () => {
    const response = await getApi(apiEndPoints.allYouthTruth);
    if (response && response.status === 200) {
      setYouthTruth(response?.data[0]);
    }
  };

  useEffect(() => {
    getYouthTruth();
  }, []);
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Youth & Truth</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Events & Activities</Link>
          </li>
          <li className="breadcrumb-item active">Youth & Truth</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Youth & Truth</span>
          </h1>
        </div>
        <div className="row text-center">
          <div dangerouslySetInnerHTML={{ __html: youthTruth?.description }} />
        </div>
      </div>
    </>
  );
}

export default YouthTruth;
