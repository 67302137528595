import React from "react";
import { Link } from "react-router-dom";
import "./errorpage.css";
import Container from "@mui/material/Container";

function Errorpage() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Page Not Found</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Page Not Found</li>
        </ol>
      </div>
      <div className="container py-5">
        {/* <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Page
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Not Found</span>
          </h1>
        </div> */}
        <div className="row py-5">
          <div className="container">
            <div className="text-center erron-cont-bdr">
              <h1 className="text-center error-h1 ">404</h1>
              <h3 className="error-h3">We can't find the page</h3>
              <p className="text-center error-cont">
                We're sorry but the page or file you're looking for is
                unavailable,
                <br /> was renamed or no longer exists. Also, please be sure
                that you're using the correct URL.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Errorpage;
