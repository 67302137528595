import React from "react";
import "./paymentreciept.css";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

function PaymentReciept() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg ">
        <h1 className="text-center display-6 breadcrumb-h1">
          Receipt of Payment
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/services/personal">Personal Services</Link>
          </li>
          <li className="breadcrumb-item active">Receipt of Payment</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Receipt of Payment</span>
          </h1>
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          <div className="receipt-bg">
            <div className="d-flex justify-content-between mb-5 header-bdr">
              <div>
                <div className="logo">
                  <img src="./images/logo.png" alt="logo" />
                </div>
              </div>
              <div className="receipt-header">
                <h2>Durga Temple</h2>
                <p>(A tax exempt non-profit organization)</p>
                <h3>
                  8400 Durga Place, Fairfax Station, VA, 22039
                  <br />
                  Phone: 703-690-9355
                </h3>
              </div>
              <div>
                <div className="logo2">
                  <img src="./images/logo2.png" alt="logo" />
                </div>
              </div>
            </div>
            <p>Dear Subrahmanyam Bandaru,</p>
            <p>
              Thank you for your donation. Your Order Number is
              <strong> 28U85300WC2173706</strong>.
            </p>
            <Table responsive striped className="trustee">
              <thead>
                <tr>
                  <th>Puja/Donation</th>
                  <th>Date</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    SatyaNarayan Puja{" "}
                    <span>Book online - attend in person</span>
                  </td>
                  <td>08/04/2024</td>
                  <td>1</td>
                  <td>$ 15</td>
                </tr>
                <tr>
                  <td colspan="4" className="total-sum">
                    Total Amount : $ 15
                  </td>
                </tr>
              </tbody>
            </Table>
            <p className="mt-4">
              <strong>Attention:</strong> If the service booked allows in person
              attendance, Please carry copy of email / printed copy of the
              receipt to the temple
            </p>
            <p>
              If you have any questions with your order, please contact &nbsp;
              <Link to="mailto:contact@durgatemple.org">
                contact@durgatemple.org
              </Link>
              .
            </p>
            <p>
              May the blessings of Lord SatyaNarayan Puja be with us always.
            </p>
            <p className="regards">
              Durga Temple
              <br />
              8400 Durga Place, Fairfax Station, VA, 22039
            </p>
            <div className="footer_clr">
              <p className="content-msg">
                Thank you for ordering puja or making Donation, please spare 5
                minutes of your time to give us feedback to help us serve you
                better.
              </p>
              <Grid container className="btn-align">
                <Button className="cont-btn" type="submit" variant="contained">
                  Start a Survey
                </Button>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentReciept;
