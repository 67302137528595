import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  cartArr: [],
  rentArr: [],
  popupTime: "",
};

export const counterSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCart: (state, action) => {
      if (!action.payload) {
        state.cartArr = [];
      } else {
        const { _id, quantity, amount, selectedDate } = action.payload;

        const existingItemIndex = state.cartArr.findIndex(
          (item) => item._id === _id
        );
        if (existingItemIndex !== -1) {
          state.cartArr[existingItemIndex].quantity = quantity || 1;
          state.cartArr[existingItemIndex].amount = amount;
          if (selectedDate) {
            state.cartArr[existingItemIndex].selectedDate =
              dayjs(selectedDate).format("YYYY-MM-DD");
          }
        } else {
          state.cartArr.push(action.payload);
        }
      }
    },
    removeFromCart: (state, action) => {
      const itemIdToRemove = action.payload;
      state.cartArr = state.cartArr.filter(
        (item) => item._id !== itemIdToRemove
      );
    },
    updateRentCart: (state, action) => {
      if (!action.payload) {
        state.rentArr = [];
      } else {
        const { _id } = action.payload;
        const existingItemIndex = state.rentArr.findIndex(
          (item) => item._id === _id
        );

        if (existingItemIndex !== -1) {
          state.rentArr[existingItemIndex] = action.payload;
        } else {
          state.rentArr.push(action.payload);
        }
      }
    },
  },
});

export const {
  updateCart,
  updateRentCart,
  removeFromCart,
  updatePrasadamCart,
  removeFromPrasadamCart,
} = counterSlice.actions;

export default counterSlice.reducer;
