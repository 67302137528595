import React from "react";
import { Link } from "react-router-dom";
import "./CommitteeCoordinators.css";
import { Table } from "react-bootstrap";
import { Button } from "@mui/material";

function CommitteeCoordinators() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Temple Committee Coordinators
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">About Us</Link>
          </li>
          <li className="breadcrumb-item active">
            Temple Committee Coordinators
          </li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Committee Coordinators</span>
          </h1>
        </div>
        <div className="row">
          <div className="mb-4 text-end">
            <Button variant="contained" color="success" className="me-3">
              Previous Year
            </Button>
            <Button variant="contained">Next Year</Button>
          </div>
          <p>
            You can contact trustees or Exec committee or Committee members by
            email as{" "}
            <a href="mailto:info@durgatemple.org">info@durgatemple.org </a>
            or at the temple phone <a href="tel:703-690-9355">
              703-690-9355
            </a>{" "}
            <br />
          </p>
          <Table responsive striped className="trustee">
            <thead>
              <tr>
                <th>Committee Name</th>
                <th>Member</th>
                <th>Member</th>
                <th>Member</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BAL GOKULUM</td>
                <td>Kusum Taneja</td>
                <td>Madhuri Prasad</td>
                <td>Saurabh Thakrar</td>
              </tr>
              <tr>
                <td>BHANDARA & KITCHEN </td>
                <td>Darshna Thakral</td>
                <td>Anu Agarwal</td>
                <td>Amol & Manjeet Sharma</td>
              </tr>
              <tr>
                <td>CULTURAL ACTIVITIES</td>
                <td>Mini Pillai</td>
                <td>Kamini Patel</td>
                <td>Monika Chopra</td>
              </tr>
              <tr>
                <td>DECORATIONS</td>
                <td>Sarada Sistla</td>
                <td>Rupa Patel</td>
                <td>Madhvi</td>
              </tr>
              <tr>
                <td>EDUCATION - HINDUISM</td>
                <td>Sampath Vanamamalai</td>
                <td>Sai Ramana</td>
                <td>Valadi Kannan</td>
              </tr>
              <tr>
                <td>FACILITY RENTALS</td>
                <td>Mahendra Chudasama</td>
                <td>Naveen Gupta</td>
                <td>Praful Patel</td>
              </tr>
              <tr>
                <td>FINANCE & ACCOUNTING</td>
                <td> Madan Gupta </td>
                <td>Mini Pillai</td>
                <td>Poonam Bansal</td>
              </tr>
              <tr>
                <td>FLYERS</td>
                <td>Sarada Sistla</td>
                <td>Sai Ramana</td>
                <td>Sangeeta</td>
              </tr>
              <tr>
                <td>FUNDRAISING</td>
                <td>Karuna Gulati</td>
                <td>Monika Chopra</td>
                <td>Sapna Rohatgi</td>
              </tr>
              <tr>
                <td>GITA DISCOURSE</td>
                <td>Jay Kalathil </td>
                <td>B. K. Kishore</td>
                <td>Kamal Bhatt</td>
              </tr>
              <tr>
                <td>HEALTH CLINIC</td>
                <td>Madan Gupta</td>
                <td>Kusum Taneja</td>
                <td>Manju Sangraula</td>
              </tr>
              <tr>
                <td>HUMAN RESOURCES</td>
                <td>Mahendra Chudasama</td>
                <td>Ranjna Sachdeva</td>
                <td>Niranjan Adhikari</td>
              </tr>
              <tr>
                <td>FLOWERS & MALA</td>
                <td>Vani</td>
                <td>Rashmi</td>
                <td>Yogita Shah</td>
              </tr>
              <tr>
                <td>IT - INTERNET & NETWORK</td>
                <td>Naveen Gupta</td>
                <td>Saurabh Thakrar</td>
                <td>Hetul Patel</td>
              </tr>
              <tr>
                <td>INTERFAITH/INTERCULTURAL DIVERSITY</td>
                <td>Chander Sharma</td>
                <td>Valadi Kannan</td>
                <td>Romal Patel</td>
              </tr>
              <tr>
                <td>JAGRAN & MATA KI CHOWKI</td>
                <td>Raj Garg</td>
                <td>Karuna Gulati</td>
                <td>Sudhir Oberoi</td>
              </tr>
              <tr>
                <td>MAINTENANCE & UPKEEP</td>
                <td> Raj Kapoor</td>
                <td>Pankaj Wadhwa</td>
                <td>Chander Sharma</td>
              </tr>
              <tr>
                <td>NEWSLETTER</td>
                <td>Sai Ramana</td>
                <td>Ashwini Lata</td>
                <td>Sakshi Sharma</td>
              </tr>
              <tr>
                <td>PARKING ACCOMMODATIONS</td>
                <td>Himanshu Patel</td>
                <td>Mitesh Patel</td>
                <td>Manish Shah</td>
              </tr>
              <tr>
                <td>RELIGIOUS ACTIVITIES</td>
                <td>Sarada Sistla</td>
                <td>Mahendra Chudasama</td>
                <td>Rohit Vashist</td>
              </tr>
              <tr>
                <td>SAT & TJ SCHOOL PREP.</td>
                <td>Archana Vardhan</td>
                <td>Monika Chopra</td>
                <td>Meena Srinivas</td>
              </tr>
              <tr>
                <td>SAFETY & SECURITY</td>
                <td>Mahendra Chudasama</td>
                <td>Raj Kapoor</td>
                <td>Vinay Narang</td>
              </tr>
              <tr>
                <td>SENIOR CITIZENS</td>
                <td>Sapna Rohatgi</td>
                <td>Karuna Gulati</td>
                <td>Madhulika Sharma</td>
              </tr>
              <tr>
                <td>SOCIAL MEDIA</td>
                <td>Mini Pillai</td>
                <td>Ashwini Lata</td>
                <td>Gaurav Sharma</td>
              </tr>
              <tr>
                <td>SOUND SYSTEMS</td>
                <td>Devang Patel</td>
                <td>Saurabh Thakrar</td>
                <td>Bhusan Desai</td>
              </tr>
              <tr>
                <td>VENDOR COORDINATOR</td>
                <td>Mahendra Chudasama</td>
                <td>Rupa Patel</td>
                <td>Sejal Soni</td>
              </tr>
              <tr>
                <td>VOLUNTEER COORDINATOR</td>
                <td>Sarada Sistla</td>
                <td>Bhusan Desai</td>
                <td>Rupa Patel</td>
              </tr>
              <tr>
                <td>WEBSITE ADMINISTRATOR</td>
                <td>Mahendra Chudasama</td>
                <td>Ranjna Sachdeva</td>
                <td>Bhusan Desai</td>
              </tr>
              <tr>
                <td>YOGA SADHANA</td>
                <td>Jay Kalathil</td>
                <td>Dhananjay Shevlikar</td>
                <td>Shaishav Shah</td>
              </tr>
              <tr>
                <td>YOUTH OUTREACH </td>
                <td>Anu Agarwal</td>
                <td>Suniti Sharma</td>
                <td>Sakshi Sharma</td>
              </tr>
              <tr>
                <td>PHOTOS & VIDEOS</td>
                <td>Dhaval & Dishant Shah </td>
                <td>Ashwini Lata </td>
                <td>Rajesh Khatri</td>
              </tr>
            </tbody>
          </Table>
        </div>{" "}
      </div>
    </>
  );
}

export default CommitteeCoordinators;
