import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./login.css";
import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import * as yup from "yup";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import {
  AddCircle,
  Remove,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
const login = yup.object().shape({
  username: yup.string().required("Please enter your username"),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});
const defaultTheme = createTheme();
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get("redirect");
  const [showPassword1, setShowPassword1] = useState(false);
  const {
    watch,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(login),
  });
  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };
  const onSubmit = async (data) => {
    try {
      const response = await postApi(apiEndPoints.devoteeLogin, data);
      if (response?.data?.status === 0) {
        toast.error(
          "Please use valid credentials or reset password using Forget Password"
        );
      } else if (response?.data?.status === 1) {
        localStorage.setItem("devoteeData", JSON.stringify(response.data.data));
        toast.success(response?.data?.message);
        navigate(redirect);
      } else {
        toast.error(
          "Please use valid credentials or reset password using Forget Password"
        );
      }
    } catch (error) {
      toast.error(
        "Please use valid credentials or reset password using Forget Password"
      );
    }
  };
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Login</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Login</li>
        </ol>
      </div>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="text-center mb-4">
              <h6 className="section-title text-center text-primary text-uppercase title-tl">
                Durga Temple
              </h6>
              <h1 className="mb-3">
                <span className="text-secundary">Login</span>
              </h1>
            </div>
            <ThemeProvider theme={defaultTheme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    sx={{
                      marginTop: 1,
                      marginBottom: 6,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Controller
                      control={control}
                      defaultValue={"username"}
                      name="login_by"
                      render={({ field }) => (
                        <FormControl>
                          <FormLabel id="login-by-label">
                            Login by{" "}
                            <span
                              className="mt-0"
                              variant="body2"
                              style={{
                                fontSize: "12px",
                                color: "var(--primary)",
                              }}
                            >
                              (Login Credentials are case sensitive)
                            </span>
                          </FormLabel>

                          <RadioGroup
                            {...field}
                            row
                            aria-labelledby="login-by-label"
                          >
                            <FormControlLabel
                              value="username"
                              control={<Radio />}
                              label="Username"
                            />
                            <FormControlLabel
                              value="devotee_id"
                              control={<Radio />}
                              label="Devotee Id"
                            />
                            <FormControlLabel
                              value="email"
                              control={<Radio />}
                              label="Email"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                    {/* <Box className="mt-0" variant="body2" style={{ fontSize: '12px', color: "var(--primary)" }}><b>Note</b> : Login credentials including email are case sensitive.</Box> */}
                    <Box sx={{ mt: 1 }}>
                      <TextField
                        {...register("username")}
                        margin="normal"
                        fullWidth
                        label={
                          watch("login_by") == "devotee_id"
                            ? "DevoteeId"
                            : watch("login_by") == "email"
                            ? "Email*"
                            : "Username*"
                        }
                        error={errors?.username?.message}
                        helperText={errors?.username?.message}
                      />
                      {/* <TextField
                        {...register("password")}
                        margin="normal"
                        fullWidth
                        label="Password*"
                        error={errors?.password?.message}
                        helperText={errors?.password?.message}
                      /> */}
                      <TextField
                        {...register("password")}
                        type={showPassword1 ? "text" : "password"}
                        margin="normal"
                        fullWidth
                        label="Password *"
                        error={!!errors?.password}
                        helperText={errors?.password?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility1}
                                edge="end"
                              >
                                {showPassword1 ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Sign In
                      </Button>
                      <Grid container>
                        <Grid item xs>
                          <Link to="/forgotpassword" variant="body2">
                            Forgot password?
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link to="/registration" variant="body2">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </form>
              </Container>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </>
  );
}
