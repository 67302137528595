import React, { useEffect, useState } from "react";
import "../../pages/event/events.css";
import { Button, ListGroup, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiEndPoints } from "../../ApiServices/config";
import { getApi, postApi } from "../../ApiServices/api";
import moment from "moment";

function IndividualNews() {
  const params = useParams();
  const slug = params.slug;
  const [event, setEvent] = useState();

  // fetching events based on event id
  const getEvent = async () => {
    const response = await postApi(apiEndPoints.getIndividualNews, {
      slug: slug,
    });
    if (response && response.status === 200) {
      setEvent(response?.data?.data);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Latest News</h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active">News</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h1 className="mb-3">
            <span className="text-secundary">{event?.title}</span>
          </h1>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="d-flex justify-content-between mb-4">
              <div>
                <div className="p-2 timep">
                  Starting Date :{" "}
                  <span>
                    {moment(event?.date)
                      .tz("America/New_York")
                      .format("MM / DD / YY")}
                  </span>
                </div>
              </div>
            </div>
            <h3 className="ttl-h2"> {event?.title || "BalGokul"}</h3>
            <p dangerouslySetInnerHTML={{ __html: event?.description }}>
              {/* BalGokul is a place for children to come together, learn practice
              Hindu culture and heritage, understand Bharat (India) and make
              good friends with similar interest and background. */}
            </p>
            {/* <p>
              Gokul is the place where Lord Krishna spent his childhood days.
              Gokul is the place where talents, mischiefs, strength and also the
              divinity of Krishna blossomed. People realized that he was not an
              ordinary boy, but an incarnation of God. "Bal" means Child. Bal
              Gokul is the place for the children to enjoy together and also
              discover their potential and blossom to the fullest extent.
            </p>
            <p>
              Durga Temple Parents and Volunteers run BalGokul for our children
            </p>
            <h3 className="sub-ttl-h2">Vision</h3>
            <p>
              To ignite, foster, revere and preserve ancient Sanatan (Hindu)
              Dharma, Culture, Values and Way of Life amongst children.
            </p>
            <h3 className="sub-ttl-h2">Mission</h3>
            <p>
              To provide a fun, social and educational environment that enables
              children to acquire a healthy understanding, and subsequently a
              pride and reverence for their ancient Sanatan religion, culture,
              and a core value system so that the children are inspired to apply
              them into their daily life, exchange thoughts and ideas with
              peers, develop a confident self-identity, and sense of
              belongingness to the community.
            </p>

            <h3 className="ttl-h2">DBG Overview</h3>
            <ul>
              <li>Total Duration: approximately 9 months, September - June</li>
              <li>Weekly Location: Durga Temple Lower level classrooms</li>
              <li>Weekly Timing: Sundays 10:00 AM - 12:30 PM</li>
            </ul>
            <h3 className="ttl-h2">Classroom Groupings</h3>
            <Table responsive className="trustee table table-striped">
              <thead>
                <tr>
                  <th scope="col">Classroom</th>
                  <th scope="col">Age</th>
                  <th scope="col">Grade Guidelines</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Prithvi</td>
                  <td>4 - 6 yrs.</td>
                  <td>Pre-k - K</td>
                </tr>
                <tr>
                  <td>Jal</td>
                  <td>6 - 8 yrs.</td>
                  <td>1, 2</td>
                </tr>
                <tr>
                  <td>Vayu</td>
                  <td>8 - 10 yrs.</td>
                  <td>3, 4</td>
                </tr>
              </tbody>
            </Table>

            <h3 className="ttl-h2">BalGokulum Calendar 2023 - 2024.</h3>
            <h4 className="ttl-h4">DBG Weekly Recurring Activities</h4>
            <ul className="list2">
              <li>Bhajans</li>
              <li>Religious Class</li>
              <li>Language (Hindi)</li>
              <li>Arts & Crafts and Games</li>
              <li>
                Children get DBG library membership as part of the program where
                they can check out 250+ Amar Chitra Katha books on various Hindu
                religious and historical characters
              </li>
            </ul>
            <h4 className="ttl-h4">
              Religious Class and Bhajan Curriculum Goals
            </h4>
            <ul className="list2">
              <li>
                Facilitate children to appreciate, learn, and practice Hindu
                Dharma, Values and Way of life
              </li>
              <li>
                Instill pride and confidence in Hindu children about their
                identity
              </li>
              <li>
                Understand our Hindu way of life by exposure to aspects such as
                prayers, bhajans and stories
              </li>
              <li>
                Reinforce concepts children through participation in cultural
                activities, role plays, skits, Q/A sessions, quizzes and class
                discussions
              </li>
              <li>
                Develop students' leadership skills and sense of community by
                having them volunteer during events to do setup, help in temple
                activities, and help in general DBG activities
              </li>
            </ul>
            <h4 className="ttl-h4">DBG Staff and Volunteers</h4>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li">
                All DBG classes and activities are staffed with either Fixed or
                Rotating volunteer resources
              </ListGroup.Item>
              <ListGroup.Item as="li">
                All Activities have a Primary and a Secondary staff
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Rotating Activities are opportunities for Parents' Volunteering
                to assist with
              </ListGroup.Item>
            </ListGroup>

            <div className="d-flex justify-content-between mt-5">
              <div className="p-2 timep2">
                Coordinator : <span>Madhuri Prasad</span>
              </div>
              <div className="p-2 timep2">
                Coordinator Phone: <span>+91 9164740678</span>
              </div>
              <div className="p-2 timep2">
                Coordinator Email: <span>prasad@digiteratech.com</span>
              </div>
              <div className="p-2 timep2">
                Fee: <span>$50</span>
              </div>
            </div> */}
          </div>
          <div className="col-lg-4 col-md-4">
            <img
              className="balgokul-img img-res"
              src={event?.image}
              alt="balgokul"
            />
          </div>
          {/* <Link to="/eventdetails">
            <Button variant="warning text-white btn-slider slider-btn col-lg-2 col-md-2 mt-3 py-3">
              Submit
            </Button>
          </Link> */}
        </div>
      </div>
    </>
  );
}

export default IndividualNews;
