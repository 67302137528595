import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { updateCart } from "../../actions";
import { toast } from "react-hot-toast";

export const useServicesCommonFunctions = () => {
  const [quantityCounts, setQuantityCounts] = useState({});
  const [selectedDate, setSelectedDate] = useState({});
  const cartArr = useSelector((state) => state.cartArr);
  const dispatch = useDispatch();

  const increaseQuantity = (itemId) => {
    setQuantityCounts((prevCounts) => ({
      ...prevCounts,
      [itemId]: (prevCounts[itemId] || 0) + 1,
    }));
  };

  const decreaseQuantity = (itemId) => {
    if (quantityCounts[itemId] > 1) {
      setQuantityCounts((prevCounts) => ({
        ...prevCounts,
        [itemId]: prevCounts[itemId] - 1,
      }));
    }
  };

  const handleOrderPuja = (item, serviceType, page) => {
    if (item.amount !== 0) {
      if (!selectedDate[item._id]) {
        toast.error("Please select a date.");
        return;
      }

      const existingCartItem = cartArr.find(
        (cartItem) => cartItem._id === item._id
      );
      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          type_id: 1,
          page: page,
          quantity: quantityCounts[item._id] || 1,
          selectedDate: selectedDate[item._id],
        };
        dispatch(updateCart(updatedItem));
        toast.success("Your puja/donation has been successfully updated.");
      } else {
        const newItem = {
          ...item,
          type_id: 1,
          page: page,
          quantity: quantityCounts[item._id] || 1,
          selectedDate: selectedDate[item._id],
        };
        dispatch(updateCart(newItem));
        toast.success(
          "Your puja/donation has been successfully added to the puja basket."
        );
      }
    } else {
      toast.error(
        "You can't book this puja. For more information, please contact the temple."
      );
    }
  };

  return {
    selectedDate,
    quantityCounts,
    setSelectedDate,
    handleOrderPuja,
    increaseQuantity,
    decreaseQuantity,
  };
};

export const useMonthlyServicesFunctions = () => {
  const [serviceData, setServiceData] = useState();
  const slug = "monthly";
  const getMonthlyServicesData = async () => {
    try {
      const response = await postApi(apiEndPoints.weeklyServices, { slug });
      setServiceData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMonthlyServicesData();
  }, []);

  return {
    serviceData,
  };
};

export const useAnnualServicesFunctions = () => {
  const [serviceData, setServiceData] = useState();
  const slug = "annual";
  const getAnnualServicesData = async () => {
    try {
      const response = await postApi(apiEndPoints.weeklyServices, { slug });
      setServiceData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAnnualServicesData();
  }, []);

  return {
    serviceData,
  };
};

export const usePersonalServicesFunctions = () => {
  const [serviceData, setServiceData] = useState();
  const slug = "personal";
  const getPersonalServicesData = async () => {
    try {
      const response = await postApi(apiEndPoints.weeklyServices, { slug });
      setServiceData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPersonalServicesData();
  }, []);

  return {
    serviceData,
  };
};

export const useReligiousServicesFunctions = () => {
  const [religiousServices, setReligiousServices] = useState();
  const slug = "daily";

  const getReligiousServices = async () => {
    const response = await postApi(apiEndPoints.religiousServices, { slug });
    if (response && response.status === 200) {
      setReligiousServices(response?.data?.data);
    }
  };

  useEffect(() => {
    getReligiousServices();
  }, []);

  return {
    religiousServices,
  };
};

export const useSpecialServicesFunctions = () => {
  const [serviceData, setServiceData] = useState();
  const slug = "special";

  const myFilter = (d, service) => {
    const startDate = new Date(parseInt(service.start));
    const endDate = new Date(parseInt(service.end));

    if (
      (startDate.getDate() === d.getDate() &&
        startDate.getMonth() === d.getMonth() &&
        startDate.getFullYear() === d.getFullYear()) ||
      (endDate.getDate() === d.getDate() &&
        endDate.getMonth() === d.getMonth() &&
        endDate.getFullYear() === d.getFullYear()) ||
      (d > startDate && d < endDate)
    ) {
      return true;
    }
    return false;
  };

  const getMonthlyServicesData = async () => {
    try {
      const response = await postApi(apiEndPoints.weeklyServices, { slug });
      setServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMonthlyServicesData();
  }, []);

  function convertUTCtoLocal(utcDateString) {
    const utcDate = new Date(utcDateString);
    const offsetMinutes = utcDate.getTimezoneOffset();
    const localTime = new Date(utcDate.getTime() - offsetMinutes * 60000);
    return localTime;
  }

  return {
    myFilter,
    serviceData,
    convertUTCtoLocal,
  };
};

export const useVahanSevasServicesFunctions = () => {
  const [serviceData, setServiceData] = useState();
  const slug = "vahana_sevas";

  const getVahanSevasServicesData = async () => {
    try {
      const response = await postApi(apiEndPoints.weeklyServices, { slug });
      setServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVahanSevasServicesData();
  }, []);

  return {
    serviceData,
  };
};

export const useWeeklyServicesFunctions = () => {
  const [serviceData, setServiceData] = useState();
  const slug = "weekly";
  const getWeeklyServicesData = async () => {
    try {
      const response = await postApi(apiEndPoints.weeklyServices, { slug });
      setServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWeeklyServicesData();
  }, []);

  return {
    serviceData,
  };
};
