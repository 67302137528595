import { CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import "./gallery.css";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import PhotoGallerySlider from "./PhotoGallerySlider";
import { apiEndPoints } from "../../ApiServices/config";
import Masonry from "@mui/lab/Masonry";
import { postApi } from "../../ApiServices/api";

export default function Photos() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(false);
  const [galleryPhotos, setGalleryPhotos] = React.useState([]);
  const { slug } = useParams();

  const handleOpenSlider = (index) => {
    setOpen(true);
    setPhotoIndex(index);
  };

  const getPhotos = async () => {
    setIsLoading(true);
    const payload = {
      slug: slug,
    };
    const getPhotos = await postApi(apiEndPoints.galleryPhotos, payload);

    getPhotos?.data?.status == 1
      ? setGalleryPhotos(getPhotos?.data?.data?.photos)
      : setGalleryPhotos([]);

    setIsLoading(false);
  };

  useEffect(() => {
    getPhotos();
  }, []);

  return (
    <>
      <div className="container-fluid gallery-bg py-5">
        <div className="container pt-4">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Temple Photos
            </h6>
            <h1 className="mb-5">
              <span className="text-secundary">Gallery</span>
            </h1>
          </div>
          <IconButton onClick={() => navigate(-1)} className="mb-5">
            <ArrowBack />
          </IconButton>
          <Grid container justifyContent="center">
            {isLoading ? (
              <CircularProgress />
            ) : galleryPhotos?.length > 0 ? (
              <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
                {galleryPhotos?.map((photo, index) => (
                  <div
                    className="gallery-background-image"
                    onClick={() => handleOpenSlider(index)}
                  >
                    <img src={photo.image} alt="" loading="lazy" />
                  </div>
                ))}
              </Masonry>
            ) : (
              "Currently there are no event photos, please check in future for all updated event photos."
            )}
          </Grid>
        </div>
      </div>
      {galleryPhotos?.length > 0 ? (
        <PhotoGallerySlider
          open={open}
          setOpen={setOpen}
          photos={galleryPhotos}
          index={photoIndex}
        />
      ) : null}
    </>
  );
}
