/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import "../../pages/registration/registration.css";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button } from "react-bootstrap";
import { MuiTelInput } from "mui-tel-input";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { apiEndPoints } from "../../ApiServices/config";
import { getApi, postApi } from "../../ApiServices/api";
import { AddCircle } from "@mui/icons-material";
import ConsentDialog from "../../components/common/ConsentDialog";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
function Sankalpam() {
  const [masterData, setMasterData] = useState({
    languageData: null,
    countryData: null,
    statesData: null,
    nakshatraData: null,
    rashiData: null,
  });
  const [sankalpMembersNakshatra, setSankalpMembersNakshatra] = useState([]);
  const [sankalpMembersRashi, setSankalpMembersRashi] = useState([]);
  const [fields, setFields] = useState([{}]);
  const [childIndex, setChildIndex] = useState(0);
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();
  const serviceCartArr = useSelector((state) => state.cartArr);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({});
  const handleMobileChange = (key, value) => {
    setMobile(value);
  };
  const getMasterData = async () => {
    const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
    const payload = {
      country_id: devoteeData?.country
        ? devoteeData?.country
        : "611ce96de2cd656171314e2a",
    };
    const result = await Promise.all([
      getApi(apiEndPoints.languages),
      getApi(apiEndPoints.countries),
      postApi(apiEndPoints.state, {
        country_id: payload?.country_id,
      }),
      getApi(apiEndPoints.nakshatram),
      getApi(apiEndPoints.rashi),
    ]);

    const sankalpmembers = devoteeData?.persons?.map((devotee, index) => {
      return {
        first_name:
          index === 0
            ? devoteeData.first_name
            : devotee?.first_name || devoteeData?.first_name,
        last_name:
          index === 0
            ? devoteeData.last_name
            : devotee?.last_name || devoteeData?.last_name,
        gotram: devotee?.gotram || "",
        nakshatram: devotee?.nakshatram || "",
      };
    }) || [
      {
        first_name: devoteeData?.first_name || "",
        last_name: devoteeData?.last_name || "",
        gotram: "",
        nakshatram: "",
      },
    ];
    reset({
      email: devoteeData?.email || "",
      sankalpmembers: sankalpmembers || [],
      country: devoteeData?.country || "",
      address: devoteeData?.address || "",
      city: devoteeData?.city || "",
      zip_code: devoteeData?.zipCode || "",
      state: devoteeData?.state || "",
      home_phone: String(devoteeData?.contact_number || ""),
    });
    setSankalpMembersNakshatra((prev) => {
      const updatedChildrenNakshatra = [...prev];
      updatedChildrenNakshatra[childIndex] = result[3]?.data?.data;
      return updatedChildrenNakshatra;
    });
    setSankalpMembersRashi((prev) => {
      const updatedChildrenRashi = [...prev];
      updatedChildrenRashi[childIndex] = result[4]?.data?.data;
      return updatedChildrenRashi;
    });

    setMasterData({
      languageData: result[0]?.data?.data,
      countryData: result[1]?.data?.data,
      statesData: result[2]?.data?.data,
      nakshatraData: result[3]?.data?.data,
      rashiData: result[4]?.data?.data,
    });
  };
  useEffect(() => {
    getMasterData();
  }, []);

  const onSubmit = async (data) => {
    try {
      data.home_phone = Number(data.home_phone.replace(/[^\d]/g, ""));
      const apiresponse = await postApi(apiEndPoints.addSankalp, data);

      if (apiresponse?.status === 200) {
        const productData = serviceCartArr?.map((item) => ({
          total: serviceCartArr.reduce(
            (total, item) => total + Number(item.amount) * (item.quantity || 1),
            0
          ),
          items: {
            _id: item._id,
            type: item.type,
            type_id: item.type_id,
            name: item.title,
            quantity: item.quantity || 1,
            price: item.amount,
            date: item.selectedDate || new Date(),
            cut_off_hours: item.cut_off_hours,
            cut_off_service_time: item.cut_off_service_time,
            cut_off_type: item.cut_off_type,
            location: item.location,
            booking_config: item.booking_config,
            row_data: {},
            page: item.page,
          },
        }));
        data.sankalp = apiresponse.data._id;
        data.user_id = null;
        const response = await postApi(apiEndPoints.stripePayment, {
          productData: productData,
          customerData: data,
          bookingId: "",
        });
        if (response?.data?.status === 1) {
          localStorage.setItem("orderId", response.data.orderData._id);
          localStorage.removeItem("devoteeData");
          setTimeout(() => {
            window.location.href = response?.data?.url;
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(`Registration failed: , ${error}`);
    }
  };

  return (
    <>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">
              SANKALPAM AND MAILING ADDRESS
            </span>
          </h1>
          Please enter your Sankalpam Details (Name, Gotram, Nakshatram) and
          Mailing address, contact email, phone number for communication purpose
          as you are using Guest Checkout feature.
        </div>
        <Box
          sx={{
            "& .MuiTextField-root": {
              minWidth: "100% !important",
            },
            "& .dropdown-margin-top": {
              marginTop: "15px !important",
            },
            "& .accordion-background": {
              boxShadow: "none !important",
            },
            "& .accordion-background::before": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <Box display="flex" justifyContent="space-between">
                <Typography>
                  <h4 className="registration-h4">Sankalpam Detail</h4>
                </Typography>
              </Box>
              {fields.map((item, index) => (
                <div key={item.id} className="row">
                  <ConsentDialog
                    index={index}
                    onAgree={(indexToRemove) => {
                      setFields(
                        fields.filter((_, index) => index !== indexToRemove)
                      );
                    }}
                  />
                  <div className="col-lg-4 col-md-6">
                    <TextField
                      {...register(`sankalpmembers[${index}].first_name`)}
                      margin="normal"
                      fullWidth
                      label="First Name *"
                      value={watch(`sankalpmembers[${index}].first_name`)}
                      InputLabelProps={{
                        shrink:
                          !!watch(`sankalpmembers[${index}].first_name`) ||
                          watch(`sankalpmembers[${index}].first_name`) === "", // Shrink label when value is present
                      }}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <TextField
                      {...register(`sankalpmembers[${index}].middle_name`)}
                      margin="normal"
                      fullWidth
                      label="Middle Name"
                      value={watch(`sankalpmembers[${index}].middle_name`)}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <TextField
                      {...register(`sankalpmembers[${index}].last_name`)}
                      margin="normal"
                      fullWidth
                      label="Last Name *"
                      value={watch(`sankalpmembers[${index}].last_name`)}
                      InputLabelProps={{
                        shrink:
                          !!watch(`sankalpmembers[${index}].last_name`) ||
                          watch(`sankalpmembers[${index}].last_name`) === "", // Shrink label when value is present
                      }}
                    />
                  </div>
                  {index == 0 && (
                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`sankalpmembers[${index}].gotram`)}
                        margin="normal"
                        fullWidth
                        label="Gotra"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                  <div className="col-lg-4 col-md-6">
                    <FormControl fullWidth className="dropdown-margin-top">
                      <InputLabel id="demo-simple-select-label">
                        Nakshatram
                      </InputLabel>
                      <Select
                        {...register(`sankalpmembers.${index}.nakshatram`)}
                        labelId="demo-simple-select-label"
                        value={watch(`sankalpmembers.${index}.nakshatram`)} // Ensure this is correct
                        id="demo-simple-select"
                        label="Nakshatram"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={async (e) => {
                          const selectedNakshatram = e.target.value;
                          setValue(
                            `sankalpmembers.${index}.nakshatram`,
                            selectedNakshatram
                          );
                          try {
                            const responseData =
                              selectedNakshatram == 0
                                ? await getApi(apiEndPoints.rashi)
                                : await postApi(
                                    apiEndPoints.rashibynakshatramid,
                                    {
                                      _id: selectedNakshatram,
                                    }
                                  );

                            setSankalpMembersRashi((prev) => {
                              const updatedSankalpMembersRashi = [...prev];
                              updatedSankalpMembersRashi[index] =
                                responseData?.data.data;
                              return updatedSankalpMembersRashi;
                            });
                          } catch (error) {
                            console.error("Error fetching state data:", error);
                          }
                        }}
                      >
                        {sankalpMembersNakshatra[index]?.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                        <MenuItem value="0">unknown</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <FormControl fullWidth className="dropdown-margin-top">
                      <InputLabel id="demo-simple-select-label">
                        Rashi
                      </InputLabel>

                      <Select
                        {...register(`sankalpmembers.${index}.rashi`)}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Rashi"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={async (e) => {
                          setValue(
                            `sankalpmembers.${index}.rashi`,
                            e.target.value
                          );
                          try {
                            const responseData =
                              e.target.value == 0
                                ? await getApi(apiEndPoints.nakshatram)
                                : await postApi(
                                    apiEndPoints.nakshatrambyrashi,
                                    {
                                      _id: e.target.value,
                                    }
                                  );

                            setSankalpMembersNakshatra((prev) => {
                              const updatedSankalpMembersNakshatra = [...prev];
                              updatedSankalpMembersNakshatra[index] =
                                responseData?.data?.data;
                              return updatedSankalpMembersNakshatra;
                            });
                          } catch (error) {
                            console.error("Error fetching state data:", error);
                          }
                        }}
                      >
                        {sankalpMembersRashi[index]?.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.rashi_name}
                          </MenuItem>
                        ))}
                        <MenuItem value="0">unknown</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ))}
              <Box display="flex" justifyContent="end">
                <IconButton
                  type="button"
                  color="success"
                  onClick={async () => {
                    setFields([...fields, {}]);
                    const result = await Promise.all([
                      getApi(apiEndPoints.nakshatram),
                      getApi(apiEndPoints.rashi),
                    ]);

                    setSankalpMembersNakshatra((prev) => {
                      const updatedSankalpMembersNakshatra = [...prev];
                      updatedSankalpMembersNakshatra[childIndex + 1] =
                        result[0]?.data?.data;
                      return updatedSankalpMembersNakshatra;
                    });
                    setSankalpMembersRashi((prev) => {
                      const updatedSankalpMembersRashi = [...prev];
                      updatedSankalpMembersRashi[childIndex + 1] =
                        result[1]?.data?.data;
                      return updatedSankalpMembersRashi;
                    });
                    setChildIndex(childIndex + 1);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
            </div>
            <hr />
            <h4 className="registration-h4">Contact Information</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("address", { required: "Address is required" })}
                  margin="normal"
                  fullWidth
                  label="Street Address *"
                  error={errors?.address?.message}
                  helperText={errors?.address?.message}
                  value={watch("address")}
                  InputLabelProps={{
                    shrink: !!watch("address") || watch("address") === "", // Shrink label when value is present
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  rules={{ required: "Country is required" }}
                  name="country"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select Country *
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Select Country *"
                          value={watch("country")}
                          onChange={async (e) => {
                            field.onChange(e.target.value);
                            try {
                              const responseData = await postApi(
                                apiEndPoints.state,
                                {
                                  country_id: e.target.value,
                                }
                              );

                              setMasterData((prevState) => ({
                                ...prevState,
                                statesData: responseData?.data.data,
                              }));
                            } catch (error) {
                              console.error(
                                "Error fetching state data:",
                                error
                              );
                            }
                          }}
                        >
                          {masterData?.countryData?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="state"
                  rules={{ required: "State is required" }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select State *
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Select State *"
                          defaultValue={field.value}
                        >
                          {masterData?.statesData?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("city", { required: "City is required" })}
                  margin="normal"
                  fullWidth
                  label="City *"
                  error={errors?.city?.message}
                  helperText={errors?.city?.message}
                  InputLabelProps={{
                    shrink: !!watch("city") || watch("city") === "", // Shrink label when value is present
                  }}
                  value={watch("city")}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("zip_code", {
                    required: "Zip Code is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="Zip Code *"
                  error={errors?.zip_code?.message}
                  helperText={errors?.zip_code?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="home_phone"
                  render={({ field }) => {
                    return (
                      <MuiTelInput
                        {...field}
                        defaultCountry="US"
                        sx={{ mt: "16px", mb: "8px" }}
                        fullWidth
                        label="Home Phone"
                        error={errors?.home_phone?.message}
                        helperText={errors?.home_phone?.message}
                      />
                    );
                  }}
                />
              </div>

              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("email")}
                  margin="normal"
                  fullWidth
                  value={watch("email")}
                  label="Email"
                  error={errors?.secondary_email?.message}
                  helperText={errors?.secondary_email?.message}
                  InputLabelProps={{
                    shrink: !!watch("email") || watch("email") === "", // Shrink label when value is present
                  }}
                />
              </div>
            </div>

            <Button
              variant="warning text-white btn-slider slider-btn col-lg-3 col-md-6 mt-3 py-3"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
      </div>
    </>
  );
}

export default Sankalpam;
