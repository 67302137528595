import React, { useEffect, useState } from "react";
import "./index.css";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

const CustomAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ServicesProductDisplay() {
  const isDevoteeLoggedIn = localStorage.getItem("token");
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const serviceCartArr = useSelector((state) => state.cartArr);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = React.useState({});
  const [country, setCountry] = useState();

  useEffect(() => {
    const total = serviceCartArr.reduce(
      (total, item) => total + Number(item.amount) * (item.quantity || 1),
      0
    );
    setTotalAmount(total);
  }, [serviceCartArr]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };

  const getCountries = async () => {
    const response = await getApi(apiEndPoints.countries);
    if (response && response.status === 200) {
      setCountry(response?.data?.data);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const productData = serviceCartArr?.map((item) => ({
    total: item.amount * (item.quantity || 1),
    productName: item.title,
    orderData: {
      name: item.title,
      date: item.selectedDate,
      quantity: item.quantity || 1,
      totalAmount: item.amount,
    },
  }));

  const stripeData = {
    productData: productData,
    totalAll: totalAmount,
  };

  localStorage.setItem("serviceStripData", JSON.stringify(stripeData));

  // all form related implementations
  const { watch, register, control, reset, formState } = useForm();

  useEffect(() => {
    if (isDevoteeLoggedIn && watch("use_for_billing") === true) {
      reset({
        first_name: devoteeData?.first_name,
        last_name: devoteeData?.last_name,
        country: devoteeData?.country,
        address: devoteeData?.address,
        city: devoteeData?.city,
      });
    } else {
      reset({
        first_name: "",
        last_name: "",
        country: "",
        address: "",
        city: "",
      });
    }
  }, [watch("use_for_billing")]);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Payment Details</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Proceed to Pay</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Payment
          </h6>
          <h1 className="mb-5">
            <span className="text-secundary">Details</span>
          </h1>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4">
            {!isDevoteeLoggedIn && (
              <p>
                Returning Devotee?{" "}
                <Link to={"/login"}>Click here to login.</Link>
              </p>
            )}

            <div className="d-flex justify-content-between">
              <h5 className="sub-title-h5 mb-2">Customer Details</h5>
            </div>
            <form>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="country"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select Country
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Select Country *"
                          defaultValue={field.value}
                        >
                          {country?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </FormControl>
              <TextField
                fullWidth
                {...register("first_name")}
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: isDevoteeLoggedIn ? true : false,
                }}
              />
              <TextField
                {...register("last_name")}
                fullWidth
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: isDevoteeLoggedIn ? true : false,
                }}
              />
              <TextField
                {...register("company_name")}
                fullWidth
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
              />
              <TextField
                {...register("address")}
                fullWidth
                id="outlined-basic"
                label="Address"
                variant="outlined"
                InputLabelProps={{
                  shrink: isDevoteeLoggedIn ? true : false,
                }}
              />
              <TextField
                {...register("city")}
                fullWidth
                id="outlined-basic"
                label="City"
                variant="outlined"
                InputLabelProps={{
                  shrink: isDevoteeLoggedIn ? true : false,
                }}
              />
              {isDevoteeLoggedIn && (
                <FormControlLabel
                  {...register("use_for_billing")}
                  control={<Checkbox defaultChecked />}
                  label="Use Devoteee Details for billing"
                />
              )}
            </form>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="container religious-service-product">
              <div className="d-flex justify-content-between">
                <h5 className="font-weight-semi-bold mb-2">Payment Details</h5>
                <div className="empty-btn">Empty Puja Basket</div>
              </div>
              <div className="custom-control custom-checkbox mb-2">
                {isDevoteeLoggedIn ? (
                  <>
                    {serviceCartArr?.length > 0 &&
                      serviceCartArr?.map((item) => (
                        <>
                          <CustomAccordion
                            expanded={
                              expanded[item._id] == undefined
                                ? true
                                : expanded[item._id]
                            }
                            onChange={handleChange(item._id)}
                          >
                            <CustomAccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography className="product-title">
                                {" "}
                                {item?.title}
                              </Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                              <Typography>
                                <ul className="payment-detail">
                                  <li>
                                    {item?.title}
                                    <span>$ {item?.amount}</span>
                                  </li>
                                  <li>
                                    Date{" "}
                                    <span>
                                      {" "}
                                      {moment(item?.selectedDate).tz('America/New_York').format(
                                        "YYYY-DD-MM"
                                      )}
                                    </span>
                                  </li>
                                  <li>
                                    Category{" "}
                                    <span>
                                      {`${item.category} (service at temple)` ||
                                        "Service outside temple"}
                                    </span>
                                  </li>
                                  <li>
                                    Quantity <span>{item?.quantity || 1}</span>
                                  </li>
                                  <li>
                                    Amount{" "}
                                    <span>
                                      ${item.amount * (item.quantity || 1)}
                                    </span>
                                  </li>

                                  <li className="service-details-book">
                                    {item?.booking_config}
                                  </li>
                                </ul>
                              </Typography>
                            </CustomAccordionDetails>
                          </CustomAccordion>
                        </>
                      ))}
                  </>
                ) : null}

                <ul className="detail-list">
                  <li className="service-details-total">
                    Total Amount: <span>$ {totalAmount}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-6 col-md-6">
                <h5 className="sub-title-h5 mb-2">Puja Basket Totals</h5>
                <ul className="detail-list">
                  <li className="service-details-total">
                    Cart Subtotal <span>$ {totalAmount}</span>
                  </li>
                  <li className="service-details-total">
                    Services <span>{serviceCartArr.length}</span>
                  </li>
                  <li className="service-details-total">
                    Order Total <span>$ {totalAmount}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
