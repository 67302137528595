/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "@mui/material";
import "./search.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SearchIcon from "@mui/icons-material/Search";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { toast } from "react-hot-toast";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const Search2 = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

function Search() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [eventData, setEventData] = useState();
  const [newsData, setNewsData] = useState();
  const [servicesTempleData, setServiceTempleData] = useState();
  const [servicesOutSideTempleData, setServiceOutSideTempleData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const getSearchData = async (query) => {
    try {
      const response = await getApi(
        `${apiEndPoints.getSearchData}?query=${encodeURIComponent(query)}`
      );
      setEventData(response?.data?.event);
      setNewsData(response?.data?.news);
      setServiceOutSideTempleData(response?.data?.serviceOutsideTemple);
      setServiceTempleData(response?.data?.serviceTemple);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      getSearchData(searchQuery);
    }
  }, [searchQuery]);
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Search Results</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Search Results</li>
        </ol>
      </div>
      <div className="container-fluid py-5">
        <div className="container pt-4">
          <div className="row g-4">
            <div className="col-lg-9 col-md-9">
              <Search2 className="search-bar">
                <SearchIconWrapper>
                  <SearchIcon className="search-icon" />
                </SearchIconWrapper>
                <StyledInputBase
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Sri LakshmiNarayana Homam-August 8-11, 2024"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search2>
              <hr />
              <div>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={handleChange}>
                        <Tab className="search-tab" label="News" value="1" />
                        <Tab className="search-tab" label="Events" value="2" />
                        <Tab
                          className="search-tab"
                          label="Services at the Temple"
                          value="3"
                        />
                        <Tab
                          className="search-tab"
                          label="Service Outside Temple"
                          value="4"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1" className="search-border">
                      {newsData?.map((news) => (
                        <>
                          <h1 className="search-title">{news?.title}</h1>
                          <Stack spacing={2}>
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                              <Link
                                underline="hover"
                                key="1"
                                color="inherit"
                                href="https://durgatemple.org/"
                              >
                                home
                              </Link>
                              ,
                              <Link
                                underline="hover"
                                key="2"
                                color="inherit"
                                href="https://durgatemple.org/news"
                              >
                                news
                              </Link>
                              ,
                              <Link
                                underline="hover"
                                key="2"
                                color="inherit"
                                href={`https://durgatemple.org/knowmorenews/${news?.slug}`}
                              >
                                {news?.slug}
                              </Link>
                              ,
                            </Breadcrumbs>
                          </Stack>

                          <h5 className="search-link">
                            <Link
                              href={`https://durgatemple.org/knowmorenews/${news?.slug}`}
                            >
                              https://durgatemple.org/knowmorenews/{news?.slug}
                            </Link>
                          </h5>
                          <p>{news?.sub_title}</p>
                          <p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: news?.description,
                              }}
                            />
                          </p>
                          <div class="devider">
                            <img
                              src="./images/devider.png"
                              alt="devider"
                              class="img-max"
                            />
                          </div>
                        </>
                      ))}
                    </TabPanel>
                    <TabPanel value="2" className="search-border">
                      <h1 className="search-title">Events</h1>
                      <div className="col-lg-8 col-md-8">
                        <div className="row">
                          {eventData &&
                            eventData?.map((item) => (
                              <div className="col-lg-9 col-md-9">
                                <div
                                  className="row"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="col-lg-4 col-md-6">
                                    <img
                                      src={item.image}
                                      alt="Events"
                                      className="img-max"
                                    />
                                  </div>
                                  <div className="col-lg-8 col-md-6">
                                    <div className="events-content">
                                      <div className="d-flex justify-content-between mb-4">
                                        <div className="p-2 timep">
                                          Date :
                                          <span>
                                            {new Date(
                                              item.event_start
                                            ).toUTCString()}
                                          </span>
                                        </div>
                                      </div>
                                      <h2 className="ttl2-h2"> {item.title}</h2>
                                      <Stack spacing={2}>
                                        <Breadcrumbs
                                          separator="›"
                                          aria-label="breadcrumb"
                                        >
                                          <Link
                                            underline="hover"
                                            key="1"
                                            color="inherit"
                                            href="https://durgatemple.org/"
                                          >
                                            home
                                          </Link>
                                          ,
                                          <Link
                                            underline="hover"
                                            key="2"
                                            color="inherit"
                                            href="https://durgatemple.org/events"
                                          >
                                            events
                                          </Link>
                                          ,
                                          <Link
                                            underline="hover"
                                            key="2"
                                            color="inherit"
                                            href={`https://durgatemple.org/knowmoreevents/${item?.slug}`}
                                          >
                                            {item?.slug}
                                          </Link>
                                          ,
                                        </Breadcrumbs>
                                      </Stack>
                                      <h5 className="search-link">
                                        <Link
                                          href={`https://durgatemple.org/knowmoreevents/${item?.slug}`}
                                        >
                                          https://durgatemple.org/knowmoreevents/
                                          {item?.slug}
                                        </Link>
                                      </h5>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                                <div className="devider">
                                  <img
                                    src="./images/devider.png"
                                    alt="devider"
                                    className="img-max"
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value="3" className="search-border">
                      <h1 className="search-title">Services at the Temple</h1>
                      <div className="row">
                        {servicesTempleData?.map((i, index) => (
                          <div key={i._id} className="col-lg-4 col-md-6">
                            <div className="religious-services-box">
                              <div className="religious-service-item d-block rounded text-center h-100 p-3">
                                <img
                                  className="img-fluid rounded mb-2"
                                  src={i.image}
                                  alt={i.title}
                                />
                                <h4 className="mb-1 religious-services-title">
                                  {i.title}
                                </h4>
                                <Stack spacing={2}>
                                  <Breadcrumbs
                                    separator="›"
                                    aria-label="breadcrumb"
                                  >
                                    <Link
                                      underline="hover"
                                      key="1"
                                      color="inherit"
                                      href="https://durgatemple.org/"
                                    >
                                      home
                                    </Link>
                                    ,
                                    <Link
                                      underline="hover"
                                      key="2"
                                      color="inherit"
                                      href="https://durgatemple.org/servicesattemple"
                                    >
                                      services-at-temple
                                    </Link>
                                    ,
                                    <Link
                                      underline="hover"
                                      key="2"
                                      color="inherit"
                                      href={`https://durgatemple.org/services/${i?.category_name}`}
                                    >
                                      {i?.category_name}
                                    </Link>
                                    ,
                                  </Breadcrumbs>
                                </Stack>
                                <h5 className="search-link">
                                  <Link
                                    href={`https://durgatemple.org/services/${i?.category_name}`}
                                  >
                                    https://durgatemple.org/services/
                                    {i?.category_name}
                                  </Link>
                                </h5>
                                <div
                                  className="service-content-bdr truncate"
                                  dangerouslySetInnerHTML={{
                                    __html: i?.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                    <TabPanel value="4" className="search-border">
                      <h1 className="search-title">Service Outside Temple</h1>
                      <div className="row">
                        {servicesOutSideTempleData?.map((i, index) => (
                          <div key={i._id} className="col-lg-4 col-md-6">
                            <div className="religious-services-box">
                              <div className="religious-service-item d-block rounded text-center h-100 p-3">
                                <img
                                  className="img-fluid rounded mb-2"
                                  src={i.image}
                                  alt={i.title}
                                />
                                <h4 className="mb-1 religious-services-title">
                                  {i.title}
                                </h4>
                                <Stack spacing={2}>
                                  <Breadcrumbs
                                    separator="›"
                                    aria-label="breadcrumb"
                                  >
                                    <Link
                                      underline="hover"
                                      key="1"
                                      color="inherit"
                                      href="https://durgatemple.org/"
                                    >
                                      home
                                    </Link>
                                    ,
                                    <Link
                                      underline="hover"
                                      key="2"
                                      color="inherit"
                                      href="https://durgatemple.org/outsidetemple"
                                    >
                                      outside-temple-services
                                    </Link>
                                  </Breadcrumbs>
                                </Stack>
                                <h5 className="search-link">
                                  <Link href="https://durgatemple.org/outsidetemple">
                                    https://durgatemple.org/outsidetemple
                                  </Link>
                                </h5>
                                <div
                                  className="service-content-bdr truncate"
                                  dangerouslySetInnerHTML={{
                                    __html: i?.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
            <div class="col-lg-3 col-md-3">
              <ul class="latest-news">
                <li>
                  <a href="/outsidetemple">Service Outside Temple</a>
                </li>
                <li>
                  <a href="/servicesattemple">Services at Temple</a>
                </li>
                <li>
                  <a href="/donation">Donation Now</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Search;
