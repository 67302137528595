import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./volunteer.css";
import { useForm, Controller } from "react-hook-form";
import { apiEndPoints } from "../../ApiServices/config";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { postApi } from "../../ApiServices/api";
import { FormHelperText } from "@mui/material";
import toast from "react-hot-toast";

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border:1px solid #ccc;
           }  
  `
);

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

function Volunteer() {
  const [devoteeData, setDevoteeData] = React.useState(
    JSON.parse(localStorage.getItem("devoteeData")) || null
  );
  const [committeeArray, setCommitteeArray] = React.useState([]);
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (committeeArray.length == 0) {
      setError("committee", {
        type: "required",
        message: "Please Select Committee",
      });
    } else {
      clearErrors();
      const payload = {
        devotee_id: devoteeData?.user_id,
        volunteerType: data?.volunteerType,
        selectedCommittee: committeeArray,
        comment: data?.comment,
      };

      try {
        const response = await postApi(apiEndPoints.volunteer, payload);
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
        } else if (response?.data?.status === 1) {
          toast.success(response?.data?.message);
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(`Registration failed: , ${error}`);
      }
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Volunteer Sign Up
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Volunteer Sign Up</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Volunteer
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Sign Up Form</span>
          </h1>
        </div>
        <div className="row mb-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={3}
              sx={{
                p: 2,
                margin: "auto",
                maxWidth: 900,
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <FormGrid item xs={12} md={6}>
                <FormLabel htmlFor="first-name" required className="label-text">
                  First name
                </FormLabel>
                <OutlinedInput
                  id="first-name"
                  name="first-name"
                  type="name"
                  placeholder="first"
                  autoComplete="first name"
                  required
                  value={devoteeData?.first_name}
                  disabled
                />
              </FormGrid>
              <FormGrid item xs={12} md={6}>
                <FormLabel htmlFor="last-name" required className="label-text">
                  Last name
                </FormLabel>
                <OutlinedInput
                  id="last-name"
                  name="last-name"
                  type="last-name"
                  placeholder="last"
                  autoComplete="last name"
                  required
                  disabled
                  value={devoteeData?.last_name}
                />
              </FormGrid>
              <FormGrid item xs={12}>
                <FormLabel htmlFor="address1" className="label-text">
                  Address line 1
                </FormLabel>
                <OutlinedInput
                  id="address1"
                  name="address1"
                  type="address1"
                  placeholder="Address line 1"
                  autoComplete="address-line1"
                  required
                  disabled
                  value={devoteeData?.address}
                />
              </FormGrid>
              <FormGrid item xs={12}>
                <FormLabel htmlFor="address2" className="label-text">
                  Address line 2
                </FormLabel>
                <OutlinedInput
                  id="address2"
                  name="address2"
                  type="address2"
                  placeholder="Apartment, suite, unit, etc."
                  autoComplete="address-line2"
                  required
                  disabled
                />
              </FormGrid>
              <FormGrid item xs={4}>
                <FormLabel htmlFor="city" className="label-text">
                  City
                </FormLabel>
                <OutlinedInput
                  id="city"
                  name="city"
                  type="city"
                  placeholder="City"
                  autoComplete="City"
                  required
                  disabled
                  value={devoteeData?.city}
                />
              </FormGrid>
              <FormGrid item xs={4}>
                <FormLabel htmlFor="state" className="label-text">
                  State
                </FormLabel>
                <OutlinedInput
                  id="state"
                  name="state"
                  type="state"
                  placeholder="State"
                  autoComplete="State"
                  required
                  disabled
                  value={devoteeData?.state}
                />
              </FormGrid>
              <FormGrid item xs={4}>
                <FormLabel htmlFor="zip" className="label-text">
                  Zip
                </FormLabel>
                <OutlinedInput
                  id="zip"
                  name="zip"
                  type="zip"
                  placeholder="Zip"
                  autoComplete="postal-code"
                  required
                  disabled
                  value={devoteeData?.zipcode}
                />
              </FormGrid>
              <FormGrid item xs={6}>
                <FormLabel htmlFor="phone" required className="label-text">
                  Phone
                </FormLabel>
                <OutlinedInput
                  id="phone"
                  name="phone"
                  type="phone"
                  placeholder="Phone"
                  autoComplete="phone"
                  required
                  disabled
                  value={devoteeData?.contact_number}
                />
              </FormGrid>
              <FormGrid item xs={6}>
                <FormLabel htmlFor="email" required className="label-text">
                  Email
                </FormLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  autoComplete="email"
                  required
                  disabled
                  value={devoteeData?.email}
                />
              </FormGrid>
              <FormGrid item xs={12}>
                <FormControl>
                  <FormLabel htmlFor="email" required className="label-text">
                    Volunteer Type
                  </FormLabel>
                  <Controller
                    name="volunteerType"
                    control={control}
                    rules={{ required: "Please Select Volunteer Type" }}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                      >
                        <FormControlLabel
                          value="school-student"
                          control={<Radio />}
                          label="School Student"
                        />
                        <FormControlLabel
                          value="college-student"
                          control={<Radio />}
                          label="College Student"
                        />
                        <FormControlLabel
                          value="adult"
                          control={<Radio />}
                          label="Adult"
                        />
                        <FormControlLabel
                          value="senior-citizen"
                          control={<Radio />}
                          label="Senior Citizen"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                <FormHelperText error>
                  {errors?.volunteerType?.message}
                </FormHelperText>
              </FormGrid>

              <FormGrid item xs={12} className="mt-1">
                <FormLabel htmlFor="email" required className="label-text">
                  I would like to participate in the following Committee(s):
                  (Please check the applicable boxes)
                </FormLabel>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([...committeeArray, "Cultural"])
                            }
                          />
                        }
                        label="Cultural"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Decorations",
                              ])
                            }
                          />
                        }
                        label="Decorations"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Devotee Database Services",
                              ])
                            }
                          />
                        }
                        label="Devotee Database Services"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Engineering",
                              ])
                            }
                          />
                        }
                        label="Engineering"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Flowers (Garland Making)",
                              ])
                            }
                          />
                        }
                        label="Flowers (Garland Making)"
                      />
                      <FormControlLabel control={<Checkbox />} label="Garden" />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Hundi Collection",
                              ])
                            }
                          />
                        }
                        label="Hundi Collection"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Information Technology",
                              ])
                            }
                          />
                        }
                        label="Information Technology"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Public Relations",
                              ])
                            }
                          />
                        }
                        label="Public Relations"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Publications",
                              ])
                            }
                          />
                        }
                        label="Publications"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Religious",
                              ])
                            }
                          />
                        }
                        label="Religious"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              setCommitteeArray([
                                ...committeeArray,
                                "Youth (Aakaar)",
                              ])
                            }
                          />
                        }
                        label="Youth (Aakaar)"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <FormHelperText error>
                  {errors?.committee?.message}
                </FormHelperText>
              </FormGrid>
              <FormGrid item xs={12}>
                <FormLabel htmlFor="Comments">Comments or Questions</FormLabel>
                <Controller
                  name="volunteerType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Textarea
                      aria-label="Comments"
                      minRows={4}
                      placeholder="Comments"
                    />
                  )}
                />
              </FormGrid>
              <FormGrid item xs={3}>
                <Button variant="contained" className="btn" type="submit">
                  Submit
                </Button>
              </FormGrid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}

export default Volunteer;
