import React from "react";
import { Link } from "react-router-dom";

function Guidelines() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Visitor & Devotee Guidelines
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/location">Visit Info</Link>
          </li>
          <li className="breadcrumb-item active">
            Visitor & Devotee Guidelines
          </li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Visitor & Devotee Guidelines</span>
          </h1>
        </div>

        <div className="row">
          <p className="faq-sub">
            To ensure a respectful, comfortable, and safe experience for all
            visitors and devotees, please adhere to the following guidelines
            when visiting Durga Temple:
          </p>
          <h2 className="temple-title mt-4">
            1. What are the Temple Darshan hours?{" "}
          </h2>
          <p>
            The Durga Temple's opening hours vary depending on the season and
            day of the week. Generally, during spring and summer, the temple is
            open from 9:00 AM to 9:00 PM, while during winter, it operates from
            9:00 AM to 8:00 PM.
          </p>
          <p>
            To get the most accurate and up-to-date information on Darshan hours
            and any possible changes, it's recommended to visit the official
            Durga Temple website at{" "}
            <Link to="https://durgatemple.org/">www.durgatemple.org</Link>
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            2. What are the dress code guidelines when visiting Durga Temple?
          </h2>
          <ul className="list4">
            <li>
              Everyone must cover their shoulders and knees while in the temple.
            </li>
            <li>
              Shorts are not permitted, and skirts or dresses should be
              ankle-length.
            </li>
            <li>
              Prior to entering the temple, remove any hats and sunglasses.{" "}
            </li>
            <li>Traditional Indian attire is appreciated and preferred.</li>
          </ul>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            3. Is there a ritual cleansing recommended before entering Durga
            Temple?
          </h2>
          <p>
            Yes, as a sign of respect and cleanliness, we kindly request that
            you bathe at home before coming to the temple. For further
            information, please reach out to the priests or officer manager.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            4. What are some common courtesy rules to follow while visiting
            Durga Temple?
          </h2>
          <ul className="list4">
            <li>Silence cell phones.</li>
            <li>
              Maintain a quiet and respectful demeanor during bhajans or aarti
              timings.
            </li>
            <li>Polite and courteous behavior is essential.</li>
            <li>Avoid loud conversations, laughter, or crying.</li>
            <li>Preserve an atmosphere of devotion and spirituality.</li>
            <li>Chewing gum is not allowed.</li>
            <li>Do not consume food in the main temple hall area.</li>
            <li>Dispose of trash in designated trash cans.</li>
            <li>Smoking is prohibited on temple grounds.</li>
          </ul>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            5. What is the protocol for footwear when entering Durga Temple?
          </h2>
          <p>
            Please remove all shoes before entering Durga Temple and leave them
            at the designated front entrance area to show respect and maintain
            cleanliness.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            6. What types of offerings can I bring to the temple?
          </h2>
          <p>
            Devotees may offer flowers, fruits, sweets, or cash to the Gods. If
            unsure about the appropriateness of your offering, consult the
            Office Manager or Priests.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            7. How should I greet Durga Mata and the Gods at the temple?
          </h2>
          <p>
            Upon seeing the statue, perform a respectful "Namaskara" gesture by
            joining the palms of your hands near your heart and bowing.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            8. Is touching shrines or statues allowed?
          </h2>
          <p>
            No, touching Temple idols is prohibited. Please respect this rule to
            maintain the sanctity of the space.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            9. What should I do if I encounter disruptive behavior?
          </h2>
          <p>
            If you witness someone being disruptive or disrespectful, please
            inform the Office Manager, Priests, or temple staff.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            10. What behavior is expected of children at the temple?
          </h2>
          <p>
            Children are expected to exhibit the same level of respect and good
            behavior as other visitors in the Temple. Parents and guardians
            should ensure that their children behave accordingly, maintaining a
            peaceful and harmonious environment for all.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            11. Are visitors allowed to take pictures of idols/statues within
            Durga Temple?
          </h2>
          <p>
            No, photography of idols/statues is generally prohibited. However,
            for educational or cultural purposes, please obtain permission from
            the Office Manager or Priests.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">12. How can I donate to the temple?</h2>
          <p>
            Donation boxes are located throughout the temple for cash or checks.
            Alternatively, scan the QR code to donate via PayPal or visit the
            Durga Temple website.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            13. What are the timings for temple bhandara?
          </h2>
          <p>
            Bhandara Prasad is served on Sunday evenings after Aarti. Also, it
            is served during auspicious festivals throughout the year.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            14. Can I accept items or gifts from the Priest?
          </h2>
          <p>
            Yes, you may accept offerings of Prasad from the Priests. However,
            consume Prasad outside the Main Temple Hall area.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            15. What should new visitors do upon arrival?
          </h2>
          <p>
            We welcome new visitors to register on our portal to stay updated
            with the latest event information and announcements. Please follow
            the link to access the Registration Form:
            <Link to="https://durgatemple.org/login?redirect=/">
              https://durgatemple.org/login?redirect=/
            </Link>
            .By signing up, you will have convenient access to all relevant
            updates and news from our community.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            16. Where can I park during my visit?
          </h2>
          <p>
            When visiting, please park only in areas that are not designated for
            Priest, Staff, or Car Pujas.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            17. What should I do if I need medical assistance?
          </h2>
          <p>
            In case of a medical emergency, immediately inform the Temple Staff
            for assistance.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            18. What safety measures are in place at Durga Temple?
          </h2>
          <p>
            Durga Temple prioritizes the safety and security of its visitors,
            devotees, and public by implementing the following measures:
          </p>
          <ul className="list4">
            <li>
              Random Security Checks: Our security team may conduct checks on
              visitors and staff at the entrance to ensure everyone's safety.
            </li>
            <li>
              Report Suspicious Activity: If you notice any suspicious behavior,
              inform Temple Priests, the Office Manager, or staff via email at{" "}
              <Link to="mailto:contact@durgatemple.org">
                contact@durgatemple.org
              </Link>{" "}
              or call <Link to="tel:703-690-9355">703-690-9355</Link>. In an
              emergency, dial <Link to="tel:911">911</Link>.
            </li>
            <li>
              Prohibited Items: Weapons, such as firearms, knives, and chemical
              sprays, are not allowed on temple grounds.
            </li>
            <li>
              Identifying Individuals with Mental Health Concerns: If you
              observe signs of mental instability or harmful behavior, notify
              Temple Priests, the Office Manager, or staff.
            </li>
            <li>
              Active Shooter Response: In case of an active shooting, prioritize
              your safety by taking shelter, running, hiding, or evacuating
              through emergency exits.
            </li>
            <li>
              Temple staff and volunteers have the authority to ask individuals
              violating temple rules or posing a threat to leave the premises or
              involve law enforcement when necessary.
            </li>
          </ul>
          <p className="sub-content">
            Note: If an individual violates Durga Temple&#39;s rules and staff
            or volunteers feel threatened, they may take necessary measures to
            ensure the safety and security of all temple visitors. These actions
            may include requesting the visitors to leave the premises
            immediately or contacting law enforcement for assistance.
            Maintaining a safe and peaceful environment at the Temple is our top
            priority.
          </p>
        </div>
      </div>
    </>
  );
}

export default Guidelines;
