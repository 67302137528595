import React, { useEffect, useState } from "react";
import Slider from "./components/slider/Slider";
import AboutTemple from "./components/abouttemple/AboutTemple";
import Sportlight from "./components/spotlight/Sportlight";
import Panchangam from "./components/panchangam/Panchangam";
import Gallery from "./components/gallery/Gallery";
import Services from "./components/home-services/Services";
import Templetime from "./components/temple-time/Templetime";
import LatestUpdates from "./components/latestupdates/LatestUpdates";
import Yogaclass from "./components/yogaclass/Yogaclass";
import FloatingSidemenu from "./components/floatingmenu/FloatingSidemenu";
import PopupPage from "./components/popupLoad/PopupPage";
import useLoadMasterDataHome from "./customHooks";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import NewsEventsSection from "./components/newsEvent/NewsEventsSection";
import ScheduleAndUpcommingSection from "./components/ScheduleUpcomingEvents/ScheduleUpcommingEventsSections";

function Home() {
  const {
    masterData,
    getMasterData,
    gallery,
    todaySchedule,
    setTodaySchedule,
    handleChange,
    updateDate,
    formatDateForDisplay,
    tab,
    setTab,
    date,
  } = useLoadMasterDataHome();
  const [templeConfig, setTempleConfig] = useState([]);

  const getTempleConfig = async () => {
    const response = await getApi(
      `${apiEndPoints?.getTempleConfigByCode}/${process.env.REACT_APP_TEMPLE_CODE}`
    );
    setTempleConfig(response?.data?.data?.sections);
  };

  useEffect(() => {
    getMasterData();
    getTempleConfig();
  }, []);

  const getSubsectionStatus = (
    sectionName,
    subSectionName,
    TempleConfigData
  ) => {
    const section = TempleConfigData?.find((item) => item?.name == sectionName);
    const subsection = section?.subsections?.find(
      (item) => item?.name == subSectionName
    );

    return section?.status && subsection?.status;
  };

  return (
    <>
      {/* <PopupPage /> */}
      <Slider slider={masterData.slider} />
      <LatestUpdates
        monthlyNewsletter={masterData.monthlyNewsletter}
        annualCalendar={masterData.annualCalendar}
        latestNews={masterData.latestNews}
      />
      <ScheduleAndUpcommingSection
        upcomingEvents={masterData.upcomingEvents}
        todaySchedule={todaySchedule}
        setTodaySchedule={setTodaySchedule}
        handleChange={handleChange}
        updateDate={updateDate}
        formatDateForDisplay={formatDateForDisplay}
        tab={tab}
        setTab={setTab}
        date={date}
      />

      <NewsEventsSection
        dailyNews={masterData.dailyNews}
        allEvents={masterData.allEvents}
      />

      <Services services={masterData.services} />
      <Sportlight spotlight={masterData.spotlight} />
      <AboutTemple />
      <Panchangam dailyPanchangam={masterData.dailyPanchangam} />
      <Gallery gallery={gallery} />
      <Templetime />
      {/* <Yogaclass /> */}
      <FloatingSidemenu
        liveStreamingUrlData={masterData?.liveStreamingUrlData}
      />
    </>
  );
}

export default Home;
