import "./index.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import toast from "react-hot-toast";
import PrasadmMessage from "./PrasadmMessage";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import "./index.css";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  styled,
} from "@mui/material";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { getApi } from "../../ApiServices/api";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { yupResolver } from "@hookform/resolvers/yup";

// Define yup schema for form validation
const schema = yup.object().shape({
  email: yup.string().email().required("Please Enter your Email"),
  country: yup.string().required("Country is required"),
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
});

const CustomAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ServicesPaymentGateway() {
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const isDevoteeLoggedIn = localStorage.getItem("token");
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const prasadamArr = useSelector((state) => state.prasadamArr);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = React.useState({});
  const [country, setCountry] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const total = prasadamArr.reduce((accumulator, item) => {
      return accumulator + item.amount * item?.itemQuantitiy;
    }, 0);

    setTotalAmount(total);
  }, [prasadamArr]);

  useEffect(() => {
    if (searchParams.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
      dispatch(updateCart());
    }

    if (searchParams.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const productData = prasadamArr?.map((item) => ({
    total: totalAmount,
    items: {
      _id: item._id,
      name: item.itemName,
      quantity: item.itemQuantitiy || 1,
      price: item.amount,
      date: new Date(),
    },
  }));
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await schema.validate(data);
      data.user_id = await devoteeData?.user_id;
      const response = await postApi(
        apiEndPoints.stripePaymentForPrasadamServices,
        {
          productData: productData,
          customerData: data,
          bookingId: "",
        }
      );
      if (response?.data?.status === 1) {
        setIsLoading(false);
        localStorage.setItem("orderId", response.data.orderData._id);
        setTimeout(() => {
          window.location.href = response?.data?.url;
        }, 1000);
      } else {
        toast.error("Something went wrong");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // service product display related data-----------------------------

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };
  const getCountries = async () => {
    const response = await getApi(apiEndPoints.countries);
    if (response && response.status === 200) {
      setCountry(response?.data?.data);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);
  // all form related implementations
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setError,
    reset,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log(errors);

  useEffect(() => {
    if (isDevoteeLoggedIn && watch("use_for_billing") === true) {
      reset({
        email: devoteeData?.email,
        first_name: devoteeData?.first_name,
        last_name: devoteeData?.last_name,
        address: devoteeData?.address,
        city: devoteeData?.city,
      });
    }
  }, [isDevoteeLoggedIn, watch("use_for_billing"), reset]);

  const handleEmptyPoojaBasket = () => {
    dispatch(updateCart());
  };
  console.log(message);

  return (
    <section className="w-100 justify-content-center align-items-center">
      {message ? (
        <PrasadmMessage />
      ) : (
        <div className="mb-5">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <>
              <div className="container-fluid page-header py-4 breadcrumb-bg">
                <h1 className="text-center display-6 breadcrumb-h1">
                  Payment Details
                </h1>
                <ol className="breadcrumb justify-content-center mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Proceed to Pay</li>
                </ol>
              </div>
              <div className="container py-5">
                <div className="text-center mb-4">
                  <h6 className="section-title text-center text-primary text-uppercase title-tl">
                    Payment
                  </h6>
                  <h1 className="mb-5">
                    <span className="text-secundary">Details</span>
                  </h1>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    {!isDevoteeLoggedIn && (
                      <p>
                        Returning Devotee?{" "}
                        <Link to={"/login"}>Click here to login.</Link>
                      </p>
                    )}

                    <div className="d-flex justify-content-between">
                      <h5 className="sub-title-h5 mb-2">
                        {isDevoteeLoggedIn
                          ? "Devotee Details"
                          : "Guest Details"}
                      </h5>
                    </div>
                    <form>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="country"
                          render={({ field, fieldState }) => {
                            return (
                              <FormControl
                                key={field.value}
                                fullWidth
                                error={errors && errors.country ? true : false}
                                className="dropdown-margin-top"
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Select Country
                                </InputLabel>
                                <Select
                                  {...field}
                                  labelId="demo-simple-select-label"
                                  label="Select Country *"
                                  defaultValue={field.value}
                                >
                                  {country?.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors && errors.country && (
                                  <FormHelperText error>
                                    {errors &&
                                    errors.country &&
                                    errors.country.message
                                      ? errors.country.message
                                      : ""}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                      </FormControl>
                      <TextField
                        {...register("email")}
                        margin="normal"
                        fullWidth
                        label="Email *"
                        error={errors && errors.email && errors?.email?.message}
                        helperText={
                          errors && errors.email && errors?.email?.message
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        className="mt-2"
                        fullWidth
                        {...register("first_name")}
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        error={
                          errors &&
                          errors.first_name &&
                          errors.first_name.message
                        }
                        helperText={
                          errors &&
                          errors.first_name &&
                          errors.first_name.message
                            ? errors.first_name.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        className="mt-2"
                        {...register("last_name")}
                        fullWidth
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        error={
                          errors && errors.last_name && errors.last_name.message
                            ? errors.last_name.message
                            : ""
                        }
                        helperText={
                          errors && errors.last_name && errors.last_name.message
                            ? errors.last_name.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <TextField
                        {...register("address")}
                        fullWidth
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        error={
                          errors && errors.address && errors.address.message
                            ? errors.address.message
                            : ""
                        }
                        helperText={
                          errors && errors.address && errors.address.message
                            ? errors.address.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        {...register("city")}
                        fullWidth
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        error={
                          errors && errors.city && errors.city.message
                            ? errors.city.message
                            : ""
                        }
                        helperText={
                          errors && errors.city && errors.city.message
                            ? errors.city.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <FormControlLabel
                        {...register("use_for_billing")}
                        control={<Checkbox defaultChecked />}
                        label={`Use ${
                          isDevoteeLoggedIn
                            ? "Devoteee Details"
                            : "Guest Details"
                        }  for billing`}
                      />
                    </form>
                  </div>
                  <div className="col-lg-8 col-md-8">
                    <div className="container religious-service-product">
                      <div className="d-flex justify-content-between">
                        <h5 className="font-weight-semi-bold mb-2">
                          Payment Details
                        </h5>
                        <div
                          className="empty-btn"
                          onClick={handleEmptyPoojaBasket}
                        >
                          Empty Puja Basket
                        </div>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <>
                          {prasadamArr?.length > 0 &&
                            prasadamArr?.map((item) => (
                              <>
                                <CustomAccordion
                                  expanded={
                                    expanded[item._id] == undefined
                                      ? true
                                      : expanded[item._id]
                                  }
                                  onChange={handleChange(item._id)}
                                >
                                  <CustomAccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                  >
                                    <Typography className="product-title">
                                      {item?.title}
                                    </Typography>
                                  </CustomAccordionSummary>
                                  <CustomAccordionDetails>
                                    <Typography>
                                      <ul className="payment-detail">
                                        <li>
                                          {item?.itemName}
                                          <span>$ {item?.amount}/unit</span>
                                        </li>
                                        <li>
                                          Date
                                          <span>
                                            {moment(
                                              item?.selectedDate
                                                ? new Date(
                                                    item?.selectedDate
                                                  ).toUTCString()
                                                : item?.date
                                            )
                                              .tz("America/New_York")
                                              .format("dddd, MMMM Do YYYY")}
                                          </span>
                                        </li>
                                        {item.category && (
                                          <li>
                                            Category{" "}
                                            <span>{`${item.category}`}</span>
                                          </li>
                                        )}

                                        <li>
                                          Quantity
                                          <span>{item?.quantity || 1}</span>
                                        </li>
                                        <li>
                                          Total Amount{" "}
                                          <span>
                                            $
                                            {item.amount * (item.quantity || 1)}
                                          </span>
                                        </li>

                                        <li className="service-details-book">
                                          {item?.booking_config}
                                        </li>
                                      </ul>
                                    </Typography>
                                  </CustomAccordionDetails>
                                </CustomAccordion>
                              </>
                            ))}
                        </>

                        <ul className="detail-list">
                          <li className="service-details-total">
                            Order Total Amount: <span>$ {totalAmount}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-6 col-md-6">
                        <h5 className="sub-title-h5 mb-2">
                          Prasadam Cart Total
                        </h5>
                        <ul className="detail-list">
                          {prasadamArr?.length > 0 &&
                            prasadamArr?.map((item) => (
                              <>
                                <li className="service-details-total">
                                  {item.itemName} <span>$ {item.amount}</span>
                                </li>
                              </>
                            ))}

                          <li className="service-details-total">
                            Order Total <span>$ {totalAmount}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            {/* //service product display-------------------------------------------------------------------  */}
            <button
              className="btn d-flex align-items-center"
              type="submit"
              disabled={isLoading}
            >
              Pay Now
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  className="mx-2 text-center"
                  size={24}
                />
              )}
            </button>
          </form>
        </div>
      )}
    </section>
  );
}
