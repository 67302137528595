import * as React from "react";
import { Link } from "react-router-dom";
import "./prasadam.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, CardHeader } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  DialogTitle,
} from "@mui/material";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { useDispatch, useSelector } from "react-redux";
import { updatePrasadamCart } from "../../actions";
import toast from "react-hot-toast";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import CommoQuantityInput from "../../components/common/inputs/CommonQuantityInput";
import { Controller, useForm } from "react-hook-form";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Add, Remove } from "@mui/icons-material";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useCateringFunctions, useServicesCommonFunctions } from "./customHook";

function Catering() {
  const { data } = useCateringFunctions();

  const {
    quantityCounts,
    handleOrderPuja,
    increaseQuantity,
    decreaseQuantity,
  } = useServicesCommonFunctions();

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Catering</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Catering</li>
        </ol>
      </div>

      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Services
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Catering</span>
          </h1>
        </div>

        <div className="row">
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <div className="col">
                <nav className="navbar">
                  <form
                    className="container-fluid justify-content-start ng-untouched ng-pristine ng-valid"
                    noValidate
                  >
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Sweet
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Snack
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2"
                      type="button"
                    >
                      Tiffin
                    </button>
                    <button
                      className="tag-btn btn-outline-warning me-2"
                      type="button"
                    >
                      Rice
                    </button>
                    <button
                      className="tag-btn btn-outline-dark me-2"
                      type="button"
                    >
                      South Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2"
                      type="button"
                    >
                      North Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-success me-2"
                      type="button"
                    >
                      Pickles / Pachidi
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Beverages
                    </button>
                    <button
                      className="tag-btn btn-outline-danger me-2"
                      type="button"
                    >
                      Greens
                    </button>
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Others
                    </button>
                  </form>
                </nav>
              </div>

              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" width="12%" className="catering-td">
                          <CardMedia
                            component="img"
                            height="128"
                            image={row?.image}
                            alt="Paella dish"
                          />
                        </TableCell>
                        <TableCell width="12%" className="catering-td catrg-ttl">{row?.title}</TableCell>
                        <TableCell width="34%" className="catering-td">
                          <Typography
                            className="des-content"
                            variant="body2"
                            color="text.secondary"
                            dangerouslySetInnerHTML={{
                              __html: row?.description,
                            }}
                          ></Typography>
                        </TableCell>

                        {row?.quantity_options?.map((item) => (
                          <>
                            <TableCell width="14%" className="catering-td">
                              <Typography className="tray-align">
                                {`${item?.servingPortion} ${item?.unit}`}
                              </Typography>
                              <Typography className="catering-price">
                              {`Price: $${item?.amount}`}
                              </Typography>
                              <div className="qty-align">
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                >
                                  <Remove
                                    className="decrease-btn qty-dec"
                                    onClick={() =>
                                      decreaseQuantity(
                                        `${row._id}${item?.servingPortion}${item?.unit}`
                                      )
                                    }
                                  />
                                </IconButton>
                                {quantityCounts[
                                  `${row._id}${item?.servingPortion}${item?.unit}`
                                ] || 1}
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                >
                                  <Add
                                    className="increase-btn qty-add"
                                    onClick={() =>
                                      increaseQuantity(
                                        `${row._id}${item?.servingPortion}${item?.unit}`
                                      )
                                    }
                                  />
                                </IconButton>
                              </div>
                              <Button
                                variant="contained"
                                color="primary"
                                className="qty-btn-add"
                                onClick={() => {
                                  const data = {
                                    _id: `${row._id}${item?.servingPortion}${item?.unit}`,
                                    title: `${row.title}-${item?.servingPortion}${item?.unit}`,
                                    amount: item.amount,
                                    quantity:
                                      quantityCounts[
                                        `${row._id}${item?.servingPortion}${item?.unit}`
                                      ] || 1,
                                  };
                                  handleOrderPuja(data, "Catering", "catering");
                                }}
                              >
                                Add
                              </Button>
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Stack spacing={2} marginTop={2} className="pagination">
                  <Pagination count={5} color="primary" />
                </Stack>
              </TableContainer>
            </Grid>
            <Grid item xs={3}>
              <PoojaBasket />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Catering;
