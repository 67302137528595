import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Typography,
  Table,
} from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./messages.css";
import { animated, useSpring } from "react-spring";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";
import moment from "moment";

export default function PrasadmMessage() {
  const orderId = localStorage.getItem("orderId");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const payeeDetails = orderDetails && JSON.parse(orderDetails?.payout);
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));

  useEffect(() => {
    if (orderId) {
      getOrderDetails();
    }
  }, [orderId]);

  const animationProps = useSpring({
    to: async (next) => {
      while (processing) {
        await next({ opacity: 1, transform: "translateY(0%)" });
        await next({ opacity: 0.5, transform: "translateY(-10%)" });
        await next({ opacity: 1, transform: "translateY(0%)" });
      }
    },
    from: { opacity: 0, transform: "translateY(-20%)" },
  });

  const getOrderDetails = async () => {
    try {
      const response = await postApi(apiEndPoints.getOrderDetails, {
        order_id: orderId,
      });

      const orderStatus = response.data.data.order_status;

      if (orderStatus !== "paid") {
        setProcessing(true);
        setOrderDetails(response.data.data);
        setTimeout(getOrderDetails, 3000);
      } else {
        setProcessing(false);
        setOrderDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const registerOrderDetails = async (orderData) => {
    try {
      const response = await postApi(apiEndPoints.registerOrderDetails, {
        user_id: devoteeData?.user_id ? devoteeData?.user_id : null,
        sankalp_id: orderData?.sankalp ? orderData?.sankalp : null,
        order_id: orderData._id,
        items: orderData?.items,
      });
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    if (orderDetails?.order_status === "paid") {
      registerOrderDetails(orderDetails);
    }
  }, [orderDetails]);

  return (
    <section className="w-100">
      <Container className="breadcrumb-bg" maxWidth="xl" sx={{ py: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          className="breadcrumb-h1"
          gutterBottom
        >
          Payment Status
        </Typography>
        <Box component="ol" className="breadcrumb justify-content-center mb-4">
          <Link href="/" component="li" className="breadcrumb-item">
            Home
          </Link>
          <Typography component="li" className="breadcrumb-item active">
            Payment Status
          </Typography>
        </Box>
      </Container>

      <Container>
        <Box component="main" noValidate sx={{ mt: 3 }} align="center">
          {searchParams.get("success") ? (
            <FormControl>
              {orderDetails &&
              orderDetails.order_status === "paid" &&
              !processing ? (
                <Container sx={{ py: 5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      className="receipt-bg"
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: 2,
                        padding: 3,
                        backgroundColor: "#f9f9f9",
                        maxWidth: 800,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 5,
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <Box>
                          <img
                            src="./images/logo.png"
                            alt="logo"
                            style={{ maxWidth: 185 }}
                          />
                        </Box>
                        <Box className="receipt-header" textAlign="center">
                          <Typography variant="h4">Durga Temple</Typography>
                          <Typography variant="body2">
                            (A tax exempt non-profit organization)
                          </Typography>
                          <Typography variant="body1">
                            8400 Durga Place, Fairfax Station, VA, 22039
                            <br />
                            Phone: 703-690-9355
                          </Typography>
                        </Box>
                        <Box>
                          <img
                            src="./images/logo2.png"
                            alt="logo2"
                            style={{ maxWidth: 100 }}
                          />
                        </Box>
                      </Box>
                      <Typography variant="body1" gutterBottom>
                        Dear{" "}
                        {`${payeeDetails.first_name} ${payeeDetails.last_name}`}
                        ,
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Thank you for ordering . Your Order Number is
                        <strong>{orderDetails?.order_id}</strong>.
                      </Typography>
                      <TableContainer sx={{ mt: 3, mb: 2 }}>
                        <Table
                          responsive="true"
                          striped="true"
                          className="trustee"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                Items Ordered
                              </TableCell>
                              <TableCell align="center">Date</TableCell>
                              <TableCell align="center">Quantity</TableCell>
                              <TableCell align="center">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails?.items.map((service, index) => (
                              <TableRow key={index}>
                                <TableCell align="center">
                                  {service?.name}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(service.date)
                                    .tz("America/New_York")
                                    .format("MM-DD-YYYY")}
                                </TableCell>
                                <TableCell align="center">
                                  {service?.quantity}
                                </TableCell>
                                <TableCell align="center">
                                  ${service?.price}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell
                                colSpan={4}
                                align="right"
                                className="total-sum"
                              >
                                Total Amount: ${orderDetails?.amount}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Typography variant="body1" sx={{ mt: 4 }}>
                        <strong>Attention:</strong> If the service booked allows
                        in-person attendance, please carry a copy of the email /
                        printed copy of the receipt to the temple.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        If you have any questions about your order, please
                        contact{" "}
                        <Link href="mailto:contact@durgatemple.org">
                          contact@durgatemple.org
                        </Link>
                        .
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        May the blessings of Goddess Durga be with us always.
                      </Typography>
                      <Box sx={{ mt: 4 }}>
                        <Typography variant="body1" className="regards">
                          Regards, Durga Temple 8400 Durga Place, Fairfax
                          Station, VA, 22039
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Container>
              ) : (
                <Box sx={{ mt: 5, textAlign: "center" }}>
                  <animated.div style={animationProps}>
                    <Typography
                      variant="h6"
                      component="div"
                      className="mt-2 text-info"
                    >
                      Payment in Progress...
                    </Typography>
                  </animated.div>
                  <Typography variant="body1" className="mt-2">
                    Please wait while we process your payment. Don't press back
                    or refresh the page while processing payment.
                  </Typography>
                  <Typography variant="body1" className="mt-2">
                    Processing...
                  </Typography>
                </Box>
              )}
            </FormControl>
          ) : (
            <Box sx={{ mt: 5, textAlign: "center" }}>
              <Typography
                variant="h6"
                component="div"
                className="mt-2 text-danger"
              >
                Payment Unsuccessful!
              </Typography>
              <Typography variant="body1" className="mt-2">
                Your payment was unsuccessful. Please try again.
              </Typography>
              {!processing && (
                <Grid
                  container
                  justifyContent="center"
                  className="btn-align mt-4"
                >
                  <Button variant="contained" onClick={() => navigate("/")}>
                    Back to Home
                  </Button>
                </Grid>
              )}
            </Box>
          )}
          <CssBaseline />
        </Box>
      </Container>
    </section>
  );
}
