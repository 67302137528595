import { CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import "./gallery.css";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";

export default function Videos() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [galleryVideos, setGalleryVideos] = React.useState([]);
  const { slug } = useParams();

  const getPhotos = async () => {
    setIsLoading(true);
    const payload = {
      slug: slug,
    };
    const getVideos = await postApi(apiEndPoints.galleryVideos, payload);

    getVideos?.data?.status == 1
      ? setGalleryVideos(getVideos?.data?.data?.videos)
      : setGalleryVideos([]);

    setIsLoading(false);
  };

  useEffect(() => {
    getPhotos();
  }, []);

  return (
    <>
      <div className="container-fluid gallery-bg py-5">
        <div className="container pt-4">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Temple Videos
            </h6>
            <h1 className="mb-5">
              <span className="text-secundary">Gallery</span>
            </h1>
          </div>
          <IconButton onClick={() => navigate(-1)} className="mb-5">
            <ArrowBack />
          </IconButton>

          <Grid container gap={2} justifyContent="center">
            {isLoading ? (
              <CircularProgress />
            ) : galleryVideos?.length > 0 ? (
              galleryVideos?.map((video) => (
                <div
                  className="gallery-event-video"
                  dangerouslySetInnerHTML={{ __html: video.url }}
                />
              ))
            ) : (
              "Currently there are no event videos, please check in future for all updated event videos."
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
