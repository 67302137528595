import React from "react";
import "./templetime.css";

function Templetime() {
  return (
    <>
      <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Temple
            </h6>
            <h1 className="mb-4">
              <span className="text-secundary">Timings</span>
            </h1>
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-2">Mondays</h4>
                <ul>
                  <li>Morning : 7:00 AM - 12 noon</li>
                  <li>Evening : 4:00 PM - 9:00 PM</li>
                  <li>Aarati : 10:00 AM & 8:00 PM</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-2">Tuesdays - Fridays</h4>
                <ul>
                  <li>Morning : 9:00 AM - 12 noon</li>
                  <li>Evening : 4:00 PM - 9:00 PM</li>
                  <li>Aarati : 10:00 AM & 8:00 PM</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-2">Saturdays & Holidays</h4>
                <ul>
                  <li>Morning : 9:00 AM - 9:00 PM</li>
                  <li>Aarati : 10:00 AM & 8:00 PM</li>
                  <li>Darshan Close : 1:00 PM - 2:00 PM</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-2">Sundays</h4>
                <ul>
                  <li>Morning : 9:00 AM - 9:00 PM</li>
                  <li>Aarati : 10:00 AM & 6:30 PM</li>
                  <li>Darshan Close : 1:00 PM - 2:00 PM</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Templetime;
