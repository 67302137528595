import React from "react";
import "./slider.css";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "react-bootstrap";

const Slider = (props) => {
  const { slider } = props;

  return (
    <>
      <Carousel data-bs-theme="dark">
        {slider?.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 res-img"
              src={item?.image}
              alt="durga temple"
            />
            <Carousel.Caption>
              <h1 className="text-light slider-h1">{item?.title}</h1>
              {/* <h3 className="slider-h3">{item?.sub_title}</h3> */}
              <div
                className="sub-title"
                dangerouslySetInnerHTML={{
                  __html: item?.sub_title,
                }}
              />
              <div className="d-flex justify-content-center gap-2">
                {item?.button_label_1 && (
                  <Button
                    target="_blank"
                    variant="warning text-white btn-slider slider-btn"
                    href={item?.button_url_1}
                  >
                    {item?.button_label_1}
                  </Button>
                )}
                {item?.button_label_2 && (
                  <Button
                    target="_blank"
                    variant="warning text-white btn-slider slider-btn"
                    href={item?.button_url_2}
                  >
                    {item?.button_label_2}
                  </Button>
                )}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default Slider;
