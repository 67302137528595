import React from "react";
import "../../pages/templeHistory/templehistory.css";
import { Link } from "react-router-dom";

function TheTemple() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">The Temple</h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">About Us</Link>
          </li>
          <li className="breadcrumb-item active">The Temple</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            About
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">The Temple</span>
          </h1>
        </div>

        <div className="row">
          <p>
            The Durga Temple of Virginia is the first temple in the United
            States dedicated to Bhagwati Durgaji. It welcomes people of all
            Hindu faiths to worship together. The temple places great importance
            on youth involvement, local communities, educational programs, and
            outreach. It collaborates with other religious institutions to
            promote peace and unity.
          </p>
          <p>
            The temple houses various deities, including Durgaji, Shiv-Parivar,
            Shri Radha-Krishna, Ram Darbar, Hanumanji, Lakshmi-Narayanji,
            Balaji, Shri Mahavirji, Shri Jhulelalji, Shri Shridi Saibaba, and
            Maa Santoshi. The priests offer religious services both at the
            temple and at other locations. Major Hindu festivals are celebrated,
            and the temple hosts a variety of educational and cultural
            activities.
          </p>
          <p>
            Financial support helps the Durga Temple create new programs for
            youth engagement, senior citizens, and community building, allowing
            it to better serve its congregation and the wider community.
          </p>
        </div>
      </div>
    </>
  );
}

export default TheTemple;
