import React from "react";
import "./Checkavailability.css";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { toast } from "react-hot-toast";

function CheckAvailability() {
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const rentCartArr = useSelector((state) => state.rentArr);
  const navigate = useNavigate();

  const handleCheckAvailability = async () => {
    const data = {
      id: devoteeData?.user_id,
      items: rentCartArr.map((item) => ({
        ...item,
        date: moment(item.date).format("YYYY/MM/DD"),
        cleaning: item.rawData.cleaning_amount,
        name: item.rawData.title,
        price: item.rawData.amount,
        duration: item.hour,
        start_time: moment(item.start_time).format("h:mm A"),
        end_time: moment(item.end_time).format("h:mm A"),
        totalCost: item.total,
        type: "rental",
      })),
      total: rentCartArr?.reduce((total, item) => total + item.total, 0),
    };

    try {
      const response = await postApi(apiEndPoints.checkAvailability, data);
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else if (response?.data?.status === 1) {
        navigate("/checkavailabilitysent");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(`Something Went Wrong: , ${error}`);
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Check Availability
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Check Availability</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Check
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Availability</span>
          </h1>
        </div>
        <div className="row">
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 4,
              }}
            >
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <p>
                      <strong>
                        Dear{" "}
                        {`${devoteeData?.first_name} ${devoteeData?.middle_name} ${devoteeData?.last_name}`}
                        ,
                      </strong>
                      <br />
                      You are trying to enquire about
                    </p>
                    <p>
                      <strong>
                        (Rental){" "}
                        {rentCartArr.map((item) => `${item?.rawData.title} ,`)}{" "}
                      </strong>
                      <br />

                      {rentCartArr?.length > 1 ? (
                        <>
                          {rentCartArr?.map((item) => {
                            return (
                              <>
                                {" "}
                                <b>{item.rawData.title}</b> -{" "}
                                {moment(new Date(item?.date))
                                  .tz("America/New_York")
                                  .format("YYYY-DD-MM")}
                                , from time{" "}
                                {moment(item.start_time).format("h:mm A")} to
                                time {moment(item.end_time).format("h:mm A")} (
                                {item.hour} Hour's).
                                <br />
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {moment(new Date(rentCartArr[0]?.date))
                            .tz("America/New_York")
                            .format("YYYY-DD-MM")}
                          , from time{" "}
                          {moment(new Date(rentCartArr[0]?.start_time)).format(
                            "h:mm A"
                          )}{" "}
                          to time{" "}
                          {moment(new Date(rentCartArr[0]?.end_time)).format(
                            "h:mm A"
                          )}{" "}
                          ({rentCartArr[0]?.hour} Hour's).
                        </>
                      )}
                    </p>
                    <p>
                      <strong>
                        Amount: ${" "}
                        {rentCartArr?.reduce(
                          (total, item) => total + item.total,
                          0
                        )}
                      </strong>
                      <br />
                      Temple Operations manager will check availability and get
                      back to you by email or phone.
                    </p>
                    <Button
                      className="cont-btn me-3"
                      type="button"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, p: 2 }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Modify
                    </Button>
                    <Button
                      className="cont-btn"
                      type="button"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, p: 2 }}
                      onClick={handleCheckAvailability}
                    >
                      Check Availability
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}

export default CheckAvailability;
