import React from "react";
import { Link } from "react-router-dom";
import "./eventdetails.css";
import { Button } from "react-bootstrap";

function EventDetails() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Classes & Camps</h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Classes & Camps</Link>
          </li>
          <li className="breadcrumb-item active">Registration Details</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Registration
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Details</span>
          </h1>
        </div>

        <div className="row">
          <p>
            Your registring for the following the events, please check event
            details participant details and submit registration.
          </p>
          <ul className="eventdetails-list">
            <li>
              <span>Event Name :</span> Balgokul
            </li>
            <li>
              <span>Start Date : </span>02/28/2024
            </li>
            <li>
              <span>End Date : </span>03/30/2024
            </li>
            <li>
              <span>Start Time : </span>6:00PM
            </li>
            <li>
              <span>End Time : </span>7:30PM
            </li>
            <li>
              <span>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="invalidCheck2"
                    required
                  />
                  <label className="form-check-label" for="invalidCheck2">
                    Particepant Details
                  </label>
                </div>
              </span>
            </li>
          </ul>
          <h2 className="ttl-h2">Particepant Details</h2>
          <ul className="eventdetails-list">
            <li>
              <span>Particepant Details</span>
              <select className="form-select select-w" id="specificSizeSelect">
                <option selected>Balgokul</option>
                <option value="1">Yoga Class</option>
                <option value="2">Geeta Satsung</option>
                <option value="3">Samskritam Classes</option>
              </select>
            </li>
            <li>
              <span>Firt Name : </span>Mokshagna
            </li>
            <li>
              <span>Last Name : </span>Yarlagadda
            </li>
            <li>
              <span>Age : </span>10
            </li>
            <li>
              <span>Mobile : </span>+91 9164740678
            </li>
            <li>
              <span>Email : </span>prasad@digiteratech.com
            </li>
          </ul>
          <Button variant="warning text-white btn-slider slider-btn col-lg-2 col-md-2 mt-3 py-3">
            Submit Registration
          </Button>
        </div>
      </div>
    </>
  );
}

export default EventDetails;
