import * as React from "react";
import { Link } from "react-router-dom";
import "./contactus.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { FormHelperText, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Textarea from "@mui/joy/Textarea";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";

function ContactUs() {
  const recaptchaRef = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const validationSchema = yup.object().shape({
    name: yup.string().required("Please enter your name"),
    phone: yup
      .string()
      .required("Please enter your phone number")
      .matches(/^\d+$/, "Phone number must contain only digits"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter your email address"),
    subject: yup.string().required("Please select a subject"),
    message: yup.string().required("Please enter your message"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    data.recaptchaReactive = recaptchaToken;

    try {
      const response = await postApi(apiEndPoints.contactUs, data);
      if (response.data.status === 1) {
        toast.success(response.data.message);
        reset();
      }
    } catch (error) {
      console.log(error);
      toast.error(`"Something Went Wrong"`);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Contact Us</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Contact Us</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Contact Us</span>
          </h1>
        </div>
        <div className="row">
          <Container component="main" maxWidth="sm">
            <Typography variant="body1">
              To contact the Durga Temple, please send an email to{" "}
              <a href="mailto:contact@durgatemple.org">
                contact@durgatemple.org{" "}
              </a>
              , call <a href="tel:703-690-9355">703-690-9355</a>, Website issues
              :
              <a href="mailto:dt_website_support@durgatemple.org">
                dt_website_support@durgatemple.org
              </a>{" "}
              or complete the contact form below. We will get back to you within
              48 hours.
            </Typography>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...register("name")}
                      autoComplete="given-name"
                      required
                      fullWidth
                      label="Name"
                      autoFocus
                      error={errors?.name?.message}
                      helperText={errors?.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...register("phone")}
                      required
                      fullWidth
                      label="Phone"
                      autoComplete="phone"
                      error={errors?.phone?.message}
                      helperText={errors?.phone?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                    <TextField
                      {...register("email")}
                      fullWidth
                      label="Email"
                      autoComplete="email"
                      error={errors?.email?.message}
                      helperText={errors?.email?.message}
                    />
                  </Grid>
                </Grid>
                <FormControl fullWidth className="select-drp">
                  <InputLabel id="demo-simple-select-label">
                    Please Select Subject
                  </InputLabel>
                  <Select
                    {...register("subject")}
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Please Select Subject"
                    error={Boolean(errors?.subject)}
                    helperText={errors?.subject?.message}
                  >
                    <MenuItem value={"Suggestions"}>Suggestions</MenuItem>
                    <MenuItem value={"Feedback"}>Feedback</MenuItem>
                    <MenuItem value={"Website Issues"}>Website Issues</MenuItem>
                    <MenuItem value={"Technical Comments"}>
                      Technical Comments
                    </MenuItem>
                    <MenuItem value={"Reservations"}>Reservations</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
                {errors?.subject ? (
                  <FormHelperText className="m-3" error>
                    {errors?.subject.message}
                  </FormHelperText>
                ) : null}{" "}
                <Textarea
                  required
                  {...register("message")}
                  minRows={5}
                  placeholder="Message"
                  className="text-area"
                  error={errors?.message?.message}
                  helperText={errors?.message?.message}
                />
                {errors?.message ? (
                  <FormHelperText className="m-3" error>
                    {errors?.message.message}
                  </FormHelperText>
                ) : null}{" "}
                <div className="mt-2">
                  <ReCAPTCHA
                    sitekey="6Lf09rwpAAAAAIpAMtEjoqFyZIECcrK6A-HU7Uu5"
                    ref={recaptchaRef}
                    onChange={handleRecaptchaChange}
                  />
                </div>
                <Button
                  className="cont-btn"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, p: 2 }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
