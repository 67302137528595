import { useState } from "react";
import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function useLoadMasterData(childIndex) {
  const [masterData, setMasterData] = useState({
    languageData: null,
    countryData: null,
    statesData: null,
    nakshatraData: null,
    rashiData: null,
  });
  const [childnakshatra, setchildNakshatra] = useState([]);
  const [childrenrashi, setChildrenRashi] = useState([]);
  const [spousechildnakshatra, setspouseNakshatra] = useState();
  const [spouserashi, setSpouseRashi] = useState();

  const getMasterData = async () => {
    const payload = {
      country_id: "611ce96de2cd656171314e2a",
      rashi_id: "611ce9cde2cd656171314e4f",
      rashi_id: "611cec0ee2cd656171314edf",
    };
    const result = await Promise.all([
      getApi(apiEndPoints.languages),
      getApi(apiEndPoints.countries),
      postApi(apiEndPoints.state, {
        country_id: payload?.country_id,
      }),
      getApi(apiEndPoints.nakshatram),
      getApi(apiEndPoints.rashi),
    ]);

    setchildNakshatra((prev) => {
      const updatedChildrenNakshatra = [...prev];
      updatedChildrenNakshatra[childIndex] = result[3]?.data?.data;
      return updatedChildrenNakshatra;
    });
    setChildrenRashi((prev) => {
      const updatedChildrenRashi = [...prev];
      updatedChildrenRashi[childIndex] = result[4]?.data?.data;
      return updatedChildrenRashi;
    });

    setspouseNakshatra((childnakshatra[0] = result[3]?.data?.data));
    setSpouseRashi(result[4]?.data?.data);
    setMasterData({
      languageData: result[0]?.data?.data,
      countryData: result[1]?.data?.data,
      statesData: result[2]?.data?.data,
      nakshatraData: result[3]?.data?.data,
      rashiData: result[4]?.data?.data,
    });
  };
  return {
    masterData,
    setMasterData,
    childnakshatra,
    setchildNakshatra,
    childrenrashi,
    setChildrenRashi,
    spouserashi,
    setSpouseRashi,
    spousechildnakshatra,
    setspouseNakshatra,
    getMasterData,
  };
}

export default useLoadMasterData;
