import * as React from "react";
import { Link } from "react-router-dom";
import "./donation.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Table from "react-bootstrap/Table";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 0,
  width: 26,
  height: 26,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "8px auto rgba(19,124,189,.6)",
    outlineOffset: 8,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 26,
    height: 26,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

function LandDonation(props) {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Garbhalaya Land Donation
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Donation</Link>
          </li>
          <li className="breadcrumb-item active">Garbhalaya Land Donation</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Garbhalaya
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Land Donation</span>
          </h1>
        </div>

        <div className="row">
          <p>
            Elders say that due to our Purva Janma Punyam or Purva Janma
            Sukrutham we have been bestowed with an opportunity to participate
            in good deeds or good service in this birth. It is believed that
            among many forms of Daanam and Dharmam, Bhomi Daanam is considered
            as one of the dearest acts that pleases the Lord and your progeny
            will be blessed for many many generations. This can be dedicated to
            any family member/kids or to Lord Srinivasa on the auspicious
            occasion of the SriVari Temple Sankustapanam guided by TTD.
          </p>
          <p>
            PuraaNas speak highly of this deed and that the Lord would protect
            such donors and their family for generations! Here is a great
            opportunity to get involved in the noble deed. Bhoomi Daanam is a
            great way by which everyone can play a part of the future Temple's
            development. Your SVLT has opened up Bhoomi Daanam, a seva just for
            you in a highly affordable way. It will be offered on a first come,
            first served basis, so please hurry.
          </p>
          <p>
            Building a temple is once in a life time opportunity and this mighty
            endeavor needs your financial support. Your generosity will help
            bring this noble cause to completion. With your continued support,
            we will not only have a place of worship, but also a venue to
            promote our culture and heritage for generations to come.
          </p>
          <hr className="hr-gap" />
          <div>
            <Table responsive className="table-align">
              <tbody>
                <tr>
                  <td>
                    <Tooltip title="20 X 24 - A1">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A2">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <BootstrapTooltip title="Prasad - A3">
                      <BpCheckbox disabled />
                    </BootstrapTooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A4">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A5">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A6">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A7">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A8">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A9">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <BootstrapTooltip title="Rahul - A10">
                      <BpCheckbox disabled />
                    </BootstrapTooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A11">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A12">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A13">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A14">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24 - A15">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24 - B1">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <BootstrapTooltip title="Vedhant - D6">
                      <BpCheckbox disabled />
                    </BootstrapTooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <BootstrapTooltip title="Vikash - G12">
                      <BpCheckbox disabled />
                    </BootstrapTooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <BootstrapTooltip title="Srinivas - D15">
                      <BpCheckbox disabled />
                    </BootstrapTooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <BootstrapTooltip title="Maha - I12">
                      <BpCheckbox disabled />
                    </BootstrapTooltip>
                  </td>
                  <td>
                    <BootstrapTooltip title="Venkat - I13">
                      <BpCheckbox disabled />
                    </BootstrapTooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title="20 X 24">
                      <BpCheckbox />
                    </Tooltip>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" className="display-ttl">
                Name to Display
              </Typography>
              <Box component="form" noValidate sx={{ mt: 2 }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography component="h1" variant="h5">
                      Number of Yards: 0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography component="h1" variant="h5">
                      Amount: $0
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="more-btn"
                    type="submit"
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Donate
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandDonation;

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
