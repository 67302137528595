import React from "react";
import "./templehistory.css";
import { Link } from "react-router-dom";

function TempleHistory() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Temple's History
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">About Us</Link>
          </li>
          <li className="breadcrumb-item active">Temple's History</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            About Us
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Temple's History</span>
          </h1>
        </div>

        <div className="row">
          <h2 className="temple-title">A Short History</h2>
          <p>
            Since 1999, the Hindu community in the Washington D.C. metropolitan
            area has embodied the American ideals of freedom, liberty, and
            opportunity. Drawn by these promises, immigrants from India have
            enriched the cultural landscape of the region. Their diverse food,
            languages, music, and Hindu traditions have become vibrant threads
            woven into the Indian American tapestry. This multicultural society
            continues to flourish, fostering a dynamic and enriching community.
          </p>

          <hr className="hr-gap" />
          <h2 className="temple-title">The Place of Religion</h2>
          <p>
            Religion plays a crucial role in shaping individuals and communities
            by guiding their beliefs, values, and daily practices. These
            practices have traditionally been encouraged in houses of worship -
            synagogues, mosques, temples, churches - and cultural centers,
            creating spaces for spiritual growth and communal connection.
          </p>
          <hr className="hr-gap" />
          <h2 className="temple-title">The Establishment of Durga Temple</h2>
          <p>
            Hindu migration to the United States increased significantly
            following the independence of South Asian and other former colonial
            nations in the mid-20th century. The Washington D.C. metropolitan
            area attracted numerous professionals, some of whom started
            successful businesses under American law. These professionals and
            entrepreneurs united to establish Siva Vishnu, Hindu, Jain, and
            Rajdhani temples. In 1989, with the support of pioneering Hindu
            businesspeople, the Durga Temple was founded, becoming a vital
            center for the growing Hindu community.
          </p>
          <hr className="hr-gap" />
          <h2 className="temple-title">Challenges in the Growth Process</h2>
          <p>
            Like any volunteer-based organization, Durga Temple faced
            difficulties in aligning diverse personalities and viewpoints around
            a shared mission. This process was time-consuming and, at times,
            contentious, particularly when disagreements arose about the
            organization's overall goals.
          </p>
          <p>
            Additional challenges included navigating architectural design by
            Dewberry and Davis, obtaining zoning permits from Fairfax County,
            and addressing neighborhood concerns regarding zoning compliance.
            Despite these obstacles, the temple persevered and overcame these
            hurdles over time.
          </p>

          <hr className="hr-gap" />
          <h2 className="temple-title">Foundation Ceremony and Construction</h2>
          <p>
            After much anticipation, the foundation ceremony took place in
            October 1994 with grand festivities and religious rituals.
            Construction commenced in October 1996, financed by a $2.5 million
            loan from First Virginia Bank. Dr. Jai Gupta provided a $250,000
            personal guarantee to secure the project's funding.
          </p>
          <hr className="hr-gap" />
          <h2 className="temple-title">The Blessings of the Lord</h2>
          <p>
            A wiseman once said, “In all adversities, there is always a treasure
            of spiritual blessings secretly hidden in its depth.” For Durga
            Temple, this treasure was Dr. Jai N. Gupta assuming the role of
            construction manager. His involvement led to significant cost
            savings and a professional, methodical approach to the construction
            process.
          </p>
          <p>
            Timely financial contributions from key supporters, including Dr.
            Jai N. Gupta, Mr. Chander Narang, Mr. B.B. Sahay, and Mr. Girish
            Jindia, were instrumental in ensuring the project's success. Today,
            the temple continues to thrive with the ongoing support of its 27
            contributing trustees.
          </p>
          <hr className="hr-gap" />
          <h2 className="temple-title">The Opening of the Temple</h2>
          <p>
            The temple's inauguration on March 21, 1999, was a joyous occasion
            celebrated with religious ceremonies and rituals. Thousands of
            devotees gathered to witness the auspicious unveiling of the Durga
            Mata idol.
          </p>
          <p>
            The Durga Temple organization was established in early 1991, with
            the first general meeting and adoption of bylaws taking place in
            September of the same year. Key milestones included the
            groundbreaking ceremony in April 1996, the Bhoomi Pooja in October
            1996, and the completion of the dome construction in March 2006.
          </p>
          <hr className="hr-gap" />
          <h2 className="temple-title">Spiritual Guidelines</h2>
          <p>
            Durga Temple's Board members are dedicated to preserving and
            promoting Hindu culture in America. While America's religious
            freedom allows everyone to practice their faith freely, the temple
            emphasizes the importance of understanding the deeper meaning behind
            rituals. These practices help us cultivate a closer connection with
            the Divine through our thoughts, words, and actions.
          </p>
          <p>
            The ancient Hindu texts, The Upanishads, emphasize that spiritual
            growth comes from true knowledge and a deep connection with God.
            While we inherit cultural values from our families, it is essential
            to explore and strengthen our understanding of the universal
            principles that govern our lives.
          </p>
          <hr className="hr-gap" />
          <h2 className="temple-title">The Philosophy of the Temple</h2>
          <p>
            The concept of God plays a central role in our Vedas and Upanishads,
            serving as a guiding principle toward a deeper understanding of the
            divine. Constructing a temple serves as an initial step in the
            journey to connect with the deeper aspects of the divine. This
            progression from the material to the spiritual realm mirrors the
            journey from the earth to water, fire, air, and ether - a journey
            that recognizes the presence of all five elements within us.
          </p>
          <p>
            Embracing this path helps us transition from the material to the
            spiritual, although the material world is more easily understood.
            This approach enables us to transcend cultural and religious
            limitations, promoting individual growth and potential. The temple
            serves as a center for personal development, providing an
            environment for self-improvement and spiritual enrichment that
            benefits society.
          </p>
          <p>
            Durga Temple's ultimate aim is to become a center where individuals
            can deepen their understanding of themselves, their communities, and
            their spiritual connection. The temple will enrich the values,
            culture, and beliefs of its members, cultivating unity and love
            under divine guidance. Individuals of all faiths who believe in God
            are invited to participate in this spiritual journey.
          </p>
        </div>
      </div>
    </>
  );
}

export default TempleHistory;
