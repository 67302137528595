import { useState } from "react";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function useLoadMasterDataEvent(slug) {
  const [masterData, setMasterData] = useState();

  const getMasterData = async () => {
    const response = await postApi(apiEndPoints.getSpecificEvent, {
      slug: slug,
    });
    if (response && response.status === 200) {
      setMasterData(response?.data?.data[0]);
    }
  };

  return {
    masterData,
    getMasterData,
    setMasterData,
  };
}

export default useLoadMasterDataEvent;
