import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function PopUpNavigate() {
  const location = useLocation();
  const currentUrlPath = location.pathname;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [timeoutId, setTimeoutId] = useState(null);
  const dialogTimeout = 30 * 60 * 1000; // 30 minutes
  const consentTimeout = 2 * 60 * 1000; // 2 minutes

  useEffect(() => {
    if (token) {
      resetTimeout();
    }
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem("devoteeData");
    localStorage.removeItem("token");
    navigate(currentUrlPath);
  };

  const resetTimeout = () => {
    clearTimeout(timeoutId);

    const dialogId = setTimeout(() => {
      const newId = setTimeout(() => {
        handleLogout();
        window.location.href = currentUrlPath;
      }, consentTimeout);
      confirmAlert({
        title: "Logout!",
        message: "Are you sure you want to logout?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              handleLogout();
              clearTimeout(newId);
            },
          },
          {
            label: "No",
            onClick: () => {
              resetTimeout();
              clearTimeout(newId);
            },
          },
        ],
      });
    }, dialogTimeout);

    setTimeoutId(dialogId);
  };

  return;
}

export default PopUpNavigate;
