import React, { useEffect, useState } from "react";
import "../../../pages/services/components/religiousservices.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import toast from "react-hot-toast";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { updateRentCart } from "../../../actions";
import { useNavigate } from "react-router";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function RentalBasket() {
  const isDevoteeLoggedIn = localStorage.getItem("token");
  const rentCartArr = useSelector((state) => state.rentArr);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = React.useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const total = rentCartArr.reduce((total, item) => total + item.total, 0);
    setTotalAmount(total);
  }, [rentCartArr]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };

  // Empty Pooja Basket
  const handleEmptyPoojaBasket = () => {
    dispatch(updateRentCart());
  };

  // handleCheckAvailability
  const handleCheckAvailability = () => {
    isDevoteeLoggedIn
      ? navigate("/checkavailability")
      : toast.error("Cannot Check Availability, Please Login to continue");
  };

  return (
    <div className="border-bottom mb-4 pb-4 details-bg religious-service-product">
      <div className="d-flex justify-content-between mt-4">
        <h5 className="font-weight-semi-bold mb-3">Details</h5>
        <div className="empty-btn" onClick={handleEmptyPoojaBasket}>
          Empty Puja Basket
        </div>
      </div>
      <div className="custom-control custom-checkbox mb-3">
        <>
          {rentCartArr?.length > 0 &&
            rentCartArr?.map((item) => (
              <>
                <Accordion
                  expanded={
                    expanded[item._id] == undefined ? true : expanded[item._id]
                  }
                  onChange={handleChange(item._id)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography> {item?.rawData?.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul className="detail-list">
                        <li>
                          {item?.rawData?.title}
                          <span>$ {item?.rawData?.amount}</span>
                        </li>
                        <li>
                          Date{" "}
                          <span>
                            {" "}
                            {moment(new Date(item?.date))
                              .tz("America/New_York")
                              .format("YYYY-DD-MM")}
                          </span>
                        </li>
                        <li>
                          Duration in hrs <span>{item?.hour} hrs</span>
                        </li>
                        <li>
                          Amount{" "}
                          <span>
                            ${" "}
                            {item?.rawData?.amount +
                              125 *
                                (item?.hour - item?.rawData?.amount_hour)}{" "}
                          </span>
                        </li>
                        <li>
                          Additional Hour Amount{" "}
                          <span>$ {item?.rawData?.additional_hour_amount}</span>
                        </li>
                        <li>
                          Cleaning charge{" "}
                          <span> $ {item?.rawData?.cleaning_amount}</span>
                        </li>
                        <li className="service-details-book">
                          Book online - attend in person
                        </li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
        </>

        <ul className="detail-list">
          <li className="service-details-total">
            Total Amount: <span>$ {totalAmount}</span>
          </li>
        </ul>
        <Button
          variant="warning text-white btn-slider slider-btn"
          onClick={handleCheckAvailability}
        >
          Check Availability
        </Button>
      </div>
    </div>
  );
}

export default RentalBasket;
