import * as React from "react";
import { Link } from "react-router-dom";
import "./prasadam.css";
import { Button } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Card, CardActions, CardContent, CardMedia } from "@mui/material";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { Add } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  useCafeteriaFunctions,
  useServicesCommonFunctions,
} from "./customHook";
import IconButton from "@mui/material/IconButton";
import { Remove } from "@mui/icons-material";

function Cafeteria() {

  function truncateText(text, charLimit) {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + "...";
    }
    return text;
  }

  const { data, handleAddItem, watch } = useCafeteriaFunctions();
  const {
    quantityCounts,
    handleOrderPuja,
    increaseQuantity,
    decreaseQuantity,
  } = useServicesCommonFunctions();
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Cafeteria</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Cafeteria</li>
        </ol>
      </div>

      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Services
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Cafeteria</span>
          </h1>
        </div>

        <div className="row">
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <div className="col">
                <nav className="navbar">
                  <form
                    className="container-fluid justify-content-start ng-untouched ng-pristine ng-valid"
                    noValidate
                  >
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Sweet
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Snack
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2 active"
                      type="button"
                    >
                      Tiffin
                    </button>
                    <button
                      className="tag-btn btn-outline-warning me-2"
                      type="button"
                    >
                      Rice
                    </button>
                    <button
                      className="tag-btn btn-outline-dark me-2"
                      type="button"
                    >
                      South Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-primary me-2"
                      type="button"
                    >
                      North Indian
                    </button>
                    <button
                      className="tag-btn btn-outline-success me-2"
                      type="button"
                    >
                      Pickles / Pachidi
                    </button>
                    <button
                      className="tag-btn btn-outline-secondary me-2"
                      type="button"
                    >
                      Beverages
                    </button>
                    <button
                      className="tag-btn btn-outline-danger me-2"
                      type="button"
                    >
                      Greens
                    </button>
                    <button
                      className="tag-btn btn-outline-info me-2"
                      type="button"
                    >
                      Others
                    </button>
                  </form>
                </nav>
              </div>
              <Grid container spacing={2} p={3}>
                {data?.map((row) => (
                  <Grid item xs={3} key={row.id}>
                    <Card sx={{ maxWidth: 500 }}>
                      <CardMedia
                        component="img"
                        height="160"
                        image={row?.image}
                        alt="Paella dish"
                      />
                      <CardContent>
                        <Typography
                          variant="h5"
                          component="h4"
                          className="catering-ttl2 cursor catering-ttl"
                        >
                          {row?.title}
                        </Typography>
                        <Typography height={120}
                          variant="body2"
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: truncateText(row?.description,120 )
                          }}
                        ></Typography>
                      </CardContent>
                      <CardActions disableSpacing>
                        <Grid item xs={12}>
                          <Grid
                            container
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <div className="d-flex wd justify-content-between">
                              <Grid item>
                                <div className="qty-align">
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                  >
                                    <Remove
                                      className="decrease-btn qty-dec"
                                      onClick={() => decreaseQuantity(row._id)}
                                    />
                                  </IconButton>
                                  {quantityCounts[row._id] || 1}
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                  >
                                    <Add
                                      className="increase-btn qty-add"
                                      onClick={() => increaseQuantity(row._id)}
                                    />
                                  </IconButton>
                                </div>
                                
                              </Grid>
                              <Grid item>
                                <h2
                                  variant="body1"
                                  className="item-price cafeteria-price"
                                >
                                  {`$${row?.price}`}
                                </h2>
                              </Grid>
                            </div>
                            <Grid>
                            <Button
                                  variant="contained"
                                  color="primary"
                                  className="cafeteria-btn"
                                  onClick={() => {
                                    const data = {
                                      _id: `${row._id}`,
                                      title: `${row.title}`,
                                      amount: row.price,
                                      quantity:
                                        quantityCounts[`${row._id}`] || 1,
                                    };
                                    handleOrderPuja(
                                      data,
                                      "Cafeteria",
                                      "cafeteria"
                                    );
                                  }}
                                >
                                  Add <Add />
                                </Button>
                            </Grid>

                            {/* <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                className="add-button w120"
                                onClick={() => {
                                  handleAddItem({
                                    itemName: `${row?.title}`,
                                    itemQuantitiy: watch(
                                      `${row?.title}quantity`
                                    ),
                                    _id: row?._id,
                                    amount: row?.price,
                                  });
                                }}
                              >
                                Add <Add />
                              </Button>
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Stack spacing={2} marginTop={2} className="pagination">
                <Pagination count={5} color="primary" />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <PoojaBasket />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Cafeteria;
