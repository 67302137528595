import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./education.css";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/system";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

// const Textarea = styled(BaseTextareaAutosize)(
//   ({ theme }) => `
//     box-sizing: border-box;
//     width: 320px;
//     font-family: 'IBM Plex Sans', sans-serif;
//     font-size: 0.875rem;
//     font-weight: 400;
//     line-height: 1.5;
//     padding: 8px 12px;
//     border:1px solid #ccc;
//            }
//   `
// );

const FormGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
}));

function Education() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [isVisible, setIsVisible] = useState(false);

  const changeHandler = (e) => {
    if (e.target.value === "1") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Education</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Education</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Education</span>
          </h1>
        </div>
        <div className="row mb-5">
          <Grid
            container
            spacing={3}
            sx={{
              p: 2,
              margin: "auto",
              maxWidth: 900,
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <FormGrid item xs={12} md={12}>
              <Typography component="h3" variant="h4" className="parents-h1">
                Parent Information
              </Typography>
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="first-name" required className="label-text">
                Mother's First Name
              </FormLabel>
              <OutlinedInput
                id="first-name"
                name="first-name"
                type="name"
                placeholder="Mother's First Name"
                autoComplete="first name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="last-name" required className="label-text">
                Mother's Last Name
              </FormLabel>
              <OutlinedInput
                id="last-name"
                name="last-name"
                type="last-name"
                placeholder="Mother's Last Name"
                autoComplete="last name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="last-name" required className="label-text">
                Father's First Name
              </FormLabel>
              <OutlinedInput
                id="last-name"
                name="last-name"
                type="last-name"
                placeholder="Father's First Name"
                autoComplete="first name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="last-name" required className="label-text">
                Father's Last Name
              </FormLabel>
              <OutlinedInput
                id="last-name"
                name="last-name"
                type="last-name"
                placeholder="Father's Last Name"
                autoComplete="last name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12}>
              <FormLabel htmlFor="address1" className="label-text">
                Address line 1
              </FormLabel>
              <OutlinedInput
                id="address1"
                name="address1"
                type="address1"
                placeholder="Address line 1"
                autoComplete="address-line1"
                required
              />
            </FormGrid>

            <FormGrid item xs={4}>
              <FormLabel htmlFor="city" className="label-text">
                City
              </FormLabel>
              <OutlinedInput
                id="city"
                name="city"
                type="city"
                placeholder="City"
                autoComplete="City"
                required
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="state" className="label-text">
                State
              </FormLabel>
              <OutlinedInput
                id="state"
                name="state"
                type="state"
                placeholder="State"
                autoComplete="State"
                required
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="zip" className="label-text">
                Zip
              </FormLabel>
              <OutlinedInput
                id="zip"
                name="zip"
                type="zip"
                placeholder="Zip"
                autoComplete="postal-code"
                required
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="phone" className="label-text">
                Mother Phone
              </FormLabel>
              <OutlinedInput
                id="phone"
                name="phone"
                type="phone"
                placeholder="Mother Phone"
                autoComplete="phone"
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="phone" className="label-text">
                Father Phone
              </FormLabel>
              <OutlinedInput
                id="phone"
                name="phone"
                type="phone"
                placeholder="Mother Phone"
                autoComplete="phone"
              />
            </FormGrid>
            <FormGrid item xs={4}>
              <FormLabel htmlFor="phone" className="label-text">
                Home Phone
              </FormLabel>
              <OutlinedInput
                id="phone"
                name="phone"
                type="phone"
                placeholder="Home Phone"
                autoComplete="phone"
              />
            </FormGrid>
            <FormGrid item xs={6}>
              <FormLabel htmlFor="email" className="label-text">
                Mother Email
              </FormLabel>
              <OutlinedInput
                id="email"
                name="email"
                type="email"
                placeholder="Mother Email"
                autoComplete="email"
              />
            </FormGrid>
            <FormGrid item xs={6}>
              <FormLabel htmlFor="email" className="label-text">
                Father Email
              </FormLabel>
              <OutlinedInput
                id="email"
                name="email"
                type="email"
                placeholder="Father Email"
                autoComplete="email"
              />
            </FormGrid>

            <FormGrid item xs={12} className="mt-1">
              <FormLabel htmlFor="email" className="label-text">
                Parents
              </FormLabel>

              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Yoga & Meditation"
                />
                <FormControlLabel control={<Checkbox />} label="Bhagavadgita" />
              </FormGroup>
            </FormGrid>

            {/* <FormGrid item xs={12}>
              <FormLabel htmlFor="Comments">Comments or Questions</FormLabel>
              <Textarea
                aria-label="Comments"
                minRows={4}
                placeholder="Comments"
              />
            </FormGrid> */}

            <FormGrid item xs={12} md={6}>
              {/* <FormLabel htmlFor="enrolling" className="label-text">
                Enrolling Children
              </FormLabel> */}
              <FormControl fullWidth>
                <InputLabel id="select-label">Enrolling Children</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  label="0"
                  onChange={changeHandler}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </FormGrid>

            <FormGrid item xs={12} md={12}>
              {isVisible ? (
                <div>
                  <FormGrid item xs={12} md={6}>
                    <OutlinedInput
                      id="last-name"
                      name="last-name"
                      type="last-name"
                      placeholder="Name"
                      autoComplete="name"
                      required
                    />
                  </FormGrid>

                  <FormGrid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Age</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>4</MenuItem>
                        <MenuItem value={2}>5</MenuItem>
                        <MenuItem value={3}>6</MenuItem>
                        <MenuItem value={4}>7</MenuItem>
                        <MenuItem value={5}>8</MenuItem>
                        <MenuItem value={6}>9</MenuItem>
                        <MenuItem value={7}>10</MenuItem>
                        <MenuItem value={8}>11</MenuItem>
                        <MenuItem value={9}>12</MenuItem>
                      </Select>
                    </FormControl>
                  </FormGrid>
                </div>
              ) : null}
            </FormGrid>
            <hr class="hr-gap" />
            <FormGrid item xs={12} md={12}>
              <Typography component="h3" variant="h4" className="parents-h1">
                Payment
              </Typography>
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="last-name" required className="label-text">
                First Name
              </FormLabel>
              <OutlinedInput
                id="last-name"
                name="last-name"
                type="last-name"
                placeholder="First Name"
                autoComplete="first name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="last-name" required className="label-text">
                Last Name
              </FormLabel>
              <OutlinedInput
                id="last-name"
                name="last-name"
                type="last-name"
                placeholder="Last Name"
                autoComplete="last name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12} md={6}>
              <FormLabel htmlFor="last-name" required className="label-text">
                Email
              </FormLabel>
              <OutlinedInput
                id="last-name"
                name="last-name"
                type="last-name"
                placeholder="Email"
                autoComplete="last name"
                required
              />
            </FormGrid>
            <FormGrid item xs={12} md={6}></FormGrid>
            <FormGrid item xs={3}>
              <Button variant="contained" className="btn">
                Submit
              </Button>
            </FormGrid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Education;
