import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./gallery.css";
import { Link } from "react-router-dom";
import { apiEndPoints, galleryType } from "../../ApiServices/config";
import Masonry from "@mui/lab/Masonry";
import { getApi, postApi } from "../../ApiServices/api";

function Gallery({ type }) {
  const [gallery, setGallery] = useState({
    list: [],
    categories: [],
    festival: [],
    tags: [],
  });
  const payload = {
    keyword: "",
    category: "",
    festival: "",
    tags: "",
    order: "asc",
    type: type,
  };
  const getEvent = async () => {
    const [galleryList, galleryCategories, galleryFestivals, galleryTags] =
      await Promise.all([
        postApi(apiEndPoints.allGallery, payload),
        getApi(apiEndPoints.galleryCategories),
        getApi(apiEndPoints.galleryFestivals),
        getApi(apiEndPoints.galleryTags),
      ]);

    setGallery({
      list: galleryList?.data?.status == 1 ? galleryList?.data?.data : [],
      categories:
        galleryCategories?.data?.status == 1
          ? galleryCategories?.data?.data
          : [],
      festival:
        galleryFestivals?.data?.status == 1 ? galleryFestivals?.data?.data : [],
      tags: galleryTags?.data?.status == 1 ? galleryTags?.data?.data : [],
    });
  };

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <>
      <div className="container-fluid gallery-bg py-5">
        <div className="container pt-4">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Temple
            </h6>
            <h1 className="mb-5">
              <span className="text-secundary">Gallery</span>
            </h1>
          </div>

          <Grid container justifyContent="center">
            {!!gallery ? (
              gallery.list?.length > 0 ? (
                <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                  {gallery.list?.map((event) => (
                    <Link
                      to={`/gallery/${
                        type === galleryType.photo ? "photos" : "videos"
                      }/${event.slug}`}
                      key={event.slug}
                    >
                      <div className="gallery-background-image">
                        <img src={event.image} alt="" />
                      </div>
                      <Typography variant="h5" textAlign="center">
                        {event.name}
                      </Typography>
                    </Link>
                  ))}
                </Masonry>
              ) : (
                "Currently there are no events gallery, please check in future for all updated event gallery."
              )
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Gallery;
