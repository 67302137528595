import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";

export default function ConsentDialog({ index, onAgree, cartItem }) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Box className="col-12" display="flex" justifyContent="end">
        <IconButton type="button" color="error" onClick={openDialog}>
          <RemoveCircle />
        </IconButton>
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        aria-labelledby="remove-children-dialog-title"
        aria-describedby="remove-children-dialog-description"
      >
        <DialogTitle id="remove-children-dialog-title">
          Please Confirm
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-children-dialog-description">
            Are you sure you wanted to remove.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Disagree</Button>
          <Button
            onClick={() => {
              onAgree(index || cartItem);
              closeDialog();
            }}
            color="error"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
