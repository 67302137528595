import React from "react";
import "./templetrustees.css";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function TempleTrustees() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Temple Board of Trustees
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">About Us</Link>
          </li>
          <li className="breadcrumb-item active">Temple Board of Trustees</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Board of Trustees</span>
          </h1>
        </div>

        <div className="row"></div>
        <p>
          To get in touch with the Trustees, Executive Board, or Committee
          Members, please send an email to{" "}
          <a href="mailto:contact@durgatemple.org">contact@durgatemple.org </a>{" "}
          or call <a href="tel:703-690-9355">703-690-9355</a>.
          <br />
        </p>
        <Table responsive striped className="trustee">
          <thead>
            <tr>
              <th>Designation</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Principal Trustee Emeritus</td>
              <td>Dr. Jai N. Gupta</td>
            </tr>
            <tr>
              <td>Chairman</td>
              <td>Suresh C. Gupta</td>
            </tr>
            <tr>
              <td>Vice Chairman</td>
              <td>Raj Kapoor</td>
            </tr>
            <tr>
              <td>Treasurer</td>
              <td>Madan Gupta</td>
            </tr>
            <tr>
              <td>Secretary</td>
              <td>Kusum Taneja</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Poonam Bansal</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Kamlesh Chainani</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Mahendrasinh Chudasama</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Jayesh Dayal</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Rajinder Garg</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Akhil Govil</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Ved Goyal</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Asit N. Gupta</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Chand Gupta</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Ram Gupta</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Ravi Gupta</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Girish Jindia</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Amit Kapoor</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Ramendra Kumar</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Bobby Makheja</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Charles Narang</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Rajiv Narang</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Brajnandan Sahay</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Sajan Saraf</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Rani Sharma</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Madhulika Singh</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>C. Kumar</td>
            </tr>
            <tr>
              <td>Trustee</td>
              <td>Sudhir Oberoi</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default TempleTrustees;
