import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import "./gallery.css";
import { getApi, postApi } from "../../../../ApiServices/api";
import { apiEndPoints } from "../../../../ApiServices/config";
import Masonry from "@mui/lab/Masonry";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const photos = [
  {
    src: "./images/gallery1.png",
    width: 884,
    height: 600,
  },
  {
    src: "./images/gallery2.png",
    width: 1600,
    height: 900,
  },
  {
    src: "./images/gallery3.png",
    width: 1600,
    height: 900,
  },
  {
    src: "./images/gallery4.png",
    width: 1600,
    height: 900,
  },
  {
    src: "./images/gallery5.png",
    width: 1600,
    height: 900,
  },
];

const payload = {
  keyword: "",
  category: "",
  festival: "",
  tags: "",
  order: "asc",
  type: "photo",
};

function Gallery(props) {
  const { gallery } = props;

  return (
    <>
      <div className="container-fluid gallery-bg py-5">
        <div className="container pt-4">
          <div className="text-center">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              Temple
            </h6>
            <h1 className="mb-5">
              <span className="text-secundary">Photo Gallery</span>
            </h1>
          </div>
          <Grid container justifyContent="center">
            {!!gallery ? (
              <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                {gallery.list?.length > 0
                  ? gallery.list?.map((event, index) => (
                      <Link to={`/gallery/photos/${event.slug}`} key={index}>
                        <div className="image-container">
                          <img src={event.image} alt="" />
                          <div className="image-overlay">
                            <Typography
                              variant="h5"
                              textAlign="center"
                              className="overlay-text"
                            >
                              {event.name}
                            </Typography>
                          </div>
                        </div>
                      </Link>
                    ))
                  : null}
              </Masonry>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <div className="text-center mt-4">
            <Link to="/gallery/videos">
              <div className="btn view-gallery-bar text-white">
                View Video Gallery &gt;&gt;&gt;
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
