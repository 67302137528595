/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import "./profile.css";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  FormGroup,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button } from "react-bootstrap";

import * as yup from "yup";
import { MuiTelInput } from "mui-tel-input";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { apiEndPoints } from "../../ApiServices/config";
import { getApi, postApi } from "../../ApiServices/api";
import toast from "react-hot-toast";
import { AddCircle, Remove, RemoveCircle } from "@mui/icons-material";
import dayjs from "dayjs";
import ConsentDialog from "../../components/common/ConsentDialog";

const defaultValues = {
  first_name: "",
  middle_name: "",
  last_name: "",
  dob: "",
  nakshatram: "",
  email: "",
};

function Profile() {
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const [userData, setUserData] = useState();
  const [spouserashi, setSpouseRashi] = useState();
  const [childrenrashi, setChildrenRashi] = useState([]);
  const [spousechildnakshatra, setspouseNakshatra] = useState();
  const [childnakshatra, setchildNakshatra] = useState([]);
  const [childIndex, setChildIndex] = useState(0);
  const [spouseAccordionExpand, setSpouseAccordionExpand] = useState(false);
  const [childrenAccordionExpand, setChildrenAccordionExpand] = useState(false);
  const [masterData, setMasterData] = useState({
    languageData: null,
    countryData: null,
    statesData: null,
    nakshatraData: null,
    rashiData: null,
    userData: null,
  });
  const [mobile, setMobile] = useState({
    main: "",
    home: "",
    office: "",
  });
  const navigate = useNavigate();

  const {
    watch,
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "childrens",
  });
  const getMasterData = async () => {
    const payload = {
      country_id: "611ce96de2cd656171314e2a",
      rashi_id: "611ce9cde2cd656171314e4f",
    };
    const result = await Promise.all([
      getApi(apiEndPoints.languages),
      getApi(apiEndPoints.countries),
      postApi(apiEndPoints.state, {
        country_id: payload?.country_id,
      }),
      getApi(apiEndPoints.nakshatram),
      getApi(apiEndPoints.rashi),
      postApi(apiEndPoints.getProfile, {
        user_id: devoteeData.user_id,
      }),
    ]);

    setchildNakshatra((prev) => {
      const updatedChildrenNakshatra = [...prev];
      updatedChildrenNakshatra[childIndex] = result[3]?.data?.data;
      return updatedChildrenNakshatra;
    });
    setChildrenRashi((prev) => {
      const updatedChildrenRashi = [...prev];
      updatedChildrenRashi[childIndex] = result[4]?.data?.data;
      return updatedChildrenRashi;
    });

    setspouseNakshatra((childnakshatra[0] = result[3]?.data?.data));
    setSpouseRashi(result[4]?.data?.data);
    setMasterData({
      languageData: result[0]?.data?.data,
      countryData: result[1]?.data?.data,
      statesData: result[2]?.data?.data,
      nakshatraData: result[3]?.data?.data,
      rashiData: result[4]?.data?.data,
      userData: result[5]?.data?.data,
    });
  };
  const prefillChilderanoptions = async (masterData) => {
    const result = await Promise.all([
      getApi(apiEndPoints.nakshatram),
      getApi(apiEndPoints.rashi),
    ]);
    masterData?.userData?.childrens.map((item, index) => {
      setchildNakshatra((prev) => {
        const updatedChildrenNakshatra = [...prev];
        updatedChildrenNakshatra[index] = result[0]?.data?.data;
        return updatedChildrenNakshatra;
      });
      setChildrenRashi((prev) => {
        const updatedChildrenRashi = [...prev];
        updatedChildrenRashi[index] = result[1]?.data?.data;
        return updatedChildrenRashi;
      });
    });
  };
  useEffect(() => {
    getMasterData();
  }, []);
  useEffect(() => {
    if (masterData?.userData?.childrens.length > 0) {
      setChildrenAccordionExpand(true);
      masterData?.userData?.childrens.map((item, index) => {
        append(item);
      });
      prefillChilderanoptions(masterData);
    }
    if (masterData?.userData?.spouse.length > 0) {
      setSpouseAccordionExpand(true);
    }
    if (masterData?.userData) {
      reset({
        first_name: masterData?.userData?.first_name,
        last_name: masterData?.userData?.last_name,
        middle_name: masterData?.userData?.middle_name,
        dob: dayjs(masterData?.userData?.dob),
        nakshatram: masterData?.userData?.nakshatram,
        rashi: masterData?.userData?.rashi,
        gotram: masterData?.userData?.gotram,
        username: masterData?.userData?.username,
        email: masterData?.userData?.email,
        devotee_id: masterData?.userData?.devotee_id,
        contact_number: `+${masterData?.userData?.contact_number}`,
        language: masterData?.userData?.language,
        address: masterData?.userData?.address?.address,
        country: masterData?.userData?.address?.country,
        state: masterData?.userData?.address?.state,
        city: masterData?.userData?.address?.city,
        zip_code: masterData?.userData?.address?.zipcode,
        secondary_email: masterData?.userData?.address?.secondary_email,
        childrens:
          masterData?.userData?.childrens &&
          masterData?.userData?.childrens?.map((item) => ({
            ...item,
            dob: dayjs(item.dob),
            nakshatram: item.nakshatram,
            rashi: item.rashi,
          })),
        spouse:
          masterData?.userData?.spouse &&
          masterData?.userData?.spouse.map((item) => ({
            ...item,
            dob: dayjs(item.dob),
          })),
        is_subscribed: masterData?.userData?.is_subscribed,
        home_phone: masterData?.userData?.home_contact_number,
        office_phone: masterData?.userData?.work_contact_number,
      });
      setMobile((prev) => ({
        ...prev,
        main: `+${masterData?.userData?.contact_number}`,
        home: `+${masterData?.userData?.home_contact_number}`,
        office: `+${masterData?.userData?.work_contact_number}`,
      }));
    }
  }, [masterData?.userData]);

  const onSubmit = async (data) => {
    if (!childrenAccordionExpand) {
      data.childrens = null;
    }

    if (!spouseAccordionExpand) {
      data.spouse = null;
    }
    data.user_id = JSON.parse(localStorage.getItem("devoteeData"))?.user_id;
    try {
      const response = await postApi(apiEndPoints.devoteeUpdate, data);
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        localStorage.setItem(
          "devoteeData",
          JSON.stringify(response?.data?.data)
        );
        navigate("/profile");
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(`Registration failed: , ${error}`);
    }
  };

  const handleMobileChange = (key, value) => {
    setMobile((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  console.log(fields, watch("childrens"), childIndex);
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Profile</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Profile</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Devotee
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Profile</span>
          </h1>
        </div>

        <Box
          sx={{
            "& .MuiTextField-root": {
              minWidth: "100% !important",
            },
            "& .dropdown-margin-top": {
              marginTop: "15px !important",
            },
            "& .accordion-background": {
              boxShadow: "none !important",
            },
            "& .accordion-background::before": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="registration-h4">Personal Information</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("first_name", {
                    required: "First Name is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="First Name *"
                  error={errors?.first_name?.message}
                  helperText={errors?.first_name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("middle_name")}
                  margin="normal"
                  fullWidth
                  label="Middle Name"
                  error={errors?.middle_name?.message}
                  helperText={errors?.middle_name?.message}
                  defaultValue={watch("middle_name")}
                  value={watch("middle_name")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("last_name", {
                    required: "Last Name is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="Last Name *"
                  error={errors?.last_name?.message}
                  helperText={errors?.last_name?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="date-top-mrgn">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <Controller
                        control={control}
                        rules={{ required: "Date of Birth is required" }}
                        name="dob"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              {...field}
                              label="Date of Birth"
                              error={errors?.dob?.message}
                            />
                          );
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FormHelperText error>{errors?.dob?.message}</FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  defaultValue={watch("nakshatram")}
                  name="nakshatram"
                  rules={{ required: "Nakshatram is required" }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Nakshatram
                        </InputLabel>
                        <Select
                          {...field}
                          defaultValue={field.value}
                          labelId="demo-simple-select-label"
                          label="Nakshatram"
                          onChange={async (e) => {
                            field.onChange(e.target.value);
                            try {
                              const responseData =
                                e.target.value == 0
                                  ? await getApi(apiEndPoints.rashi)
                                  : await postApi(
                                      apiEndPoints.rashibynakshatramid,
                                      {
                                        _id: e.target.value,
                                      }
                                    );
                              setMasterData((prevState) => ({
                                ...prevState,
                                rashiData: responseData?.data.data,
                              }));
                            } catch (error) {
                              console.error(
                                "Error fetching state data:",
                                error
                              );
                              // Optionally handle error state here
                            }
                          }}
                        >
                          {masterData?.nakshatraData?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}

                          {masterData?.nakshatraData?.length > 8 ? null : (
                            <MenuItem value="0">unknown</MenuItem>
                          )}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              {/* Rashi select box */}
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="rashi"
                  rules={{ required: "Rashi is required" }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Rashi
                        </InputLabel>
                        <Select
                          {...field}
                          defaultValue={field.value}
                          labelId="demo-simple-select-label"
                          label="Rashi"
                          onChange={async (e) => {
                            field.onChange(e.target.value);
                            try {
                              const responseData =
                                e.target.value == 0
                                  ? await getApi(apiEndPoints.nakshatram)
                                  : await postApi(
                                      apiEndPoints.nakshatrambyrashi,
                                      {
                                        _id: e.target.value,
                                      }
                                    );

                              setMasterData((prevState) => ({
                                ...prevState,
                                nakshatraData: responseData?.data.data,
                              }));
                            } catch (error) {
                              console.error(
                                "Error fetching state data:",
                                error
                              );
                            }
                          }}
                        >
                          {masterData?.rashiData?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.rashi_name}
                            </MenuItem>
                          ))}

                          <MenuItem value="0">unknown</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("gotram", { required: "Gotram is required" })}
                  margin="normal"
                  fullWidth
                  label="Gotram"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.gotram?.message}
                  helperText={errors?.gotram?.message}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("email", { required: "Email is required" })}
                  margin="normal"
                  fullWidth
                  label="Email *"
                  error={errors?.email?.message}
                  helperText={errors?.email?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("devotee_id")}
                  margin="normal"
                  fullWidth
                  label="Devotee id"
                  error={errors?.devotee_id?.message}
                  helperText={errors?.devotee_id?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="contact_number"
                  render={({ field }) => {
                    return (
                      <MuiTelInput
                        {...field}
                        value={mobile.main}
                        onChange={(event, event2) => {
                          handleMobileChange("main", event);
                          field.onChange(+event2?.numberValue);
                        }}
                        defaultCountry="US"
                        sx={{ mt: "16px", mb: "8px" }}
                        fullWidth
                        label="Mobile *"
                        error={errors?.contact_number?.message}
                        helperText={errors?.contact_number?.message}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  rules={{ required: "Preferred Language is required" }}
                  name="language"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Preferred Language
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Preferred Language"
                          defaultValue={field.value}
                        >
                          {masterData?.languageData?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>

              <Accordion
                expanded={spouseAccordionExpand}
                onChange={() => {
                  setSpouseAccordionExpand(!spouseAccordionExpand);
                }}
                className="accordion-background"
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    {spouseAccordionExpand ? <Remove /> : <AddIcon />}
                    Spouse
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <h4 className="registration-h4">Spouse Information</h4>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.first_name`)}
                        margin="normal"
                        fullWidth
                        label="First Name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.middle_name`)}
                        margin="normal"
                        fullWidth
                        label="Middle Name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.last_name`)}
                        margin="normal"
                        fullWidth
                        label="Last Name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="date-top-mrgn">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <Controller
                              control={control}
                              name={`spouse.0.dob`}
                              render={({ field }) => {
                                return (
                                  <DatePicker
                                    {...field}
                                    label="Date of Birth"
                                  />
                                );
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <p>{errors?.dob?.message}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <Controller
                        name="spouse.0.nakshatram"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl
                            fullWidth
                            className="dropdown-margin-top"
                            error={fieldState?.error?.message}
                            key={field.value}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Nakshatram
                            </InputLabel>
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Nakshatram"
                              defaultValue={field.value}
                              onChange={async (e) => {
                                field.onChange(e);
                                try {
                                  const responseData =
                                    e.target.value === "0"
                                      ? await getApi(apiEndPoints.rashi)
                                      : await postApi(
                                          apiEndPoints.rashibynakshatramid,
                                          {
                                            _id: e.target.value,
                                          }
                                        );

                                  setSpouseRashi(responseData?.data?.data);
                                } catch (error) {
                                  console.error(
                                    "Error fetching state data:",
                                    error
                                  );
                                }
                              }}
                            >
                              {spousechildnakshatra?.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                              <MenuItem value="0">unknown</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </div>
                    {/* Rashi select box */}
                    <div className="col-lg-4 col-md-6">
                      <Controller
                        name="spouse.0.rashi"
                        control={control}
                        render={({ field, fieldState }) => (
                          <FormControl
                            fullWidth
                            className="dropdown-margin-top"
                            error={fieldState?.error?.message}
                            key={field.value}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Rashi
                            </InputLabel>
                            <Select
                              {...field}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Rashi"
                              defaultValue={field.value}
                              onChange={async (e) => {
                                field.onChange(e.target.value);
                                try {
                                  const responseData =
                                    e.target.value == 0
                                      ? await getApi(apiEndPoints.nakshatram)
                                      : await postApi(
                                          apiEndPoints.nakshatrambyrashi,
                                          {
                                            _id: e.target.value,
                                          }
                                        );

                                  setspouseNakshatra(responseData?.data?.data);
                                } catch (error) {
                                  console.error(
                                    "Error fetching state data:",
                                    error
                                  );
                                }
                              }}
                            >
                              {spouserashi?.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.rashi_name}
                                </MenuItem>
                              ))}
                              <MenuItem value="0">unknown</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <TextField
                        {...register(`spouse.0.email`)}
                        margin="normal"
                        fullWidth
                        label="Email"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={childrenAccordionExpand}
                onChange={() => {
                  setChildrenAccordionExpand(!childrenAccordionExpand);
                }}
                className="accordion-background"
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>
                    {childrenAccordionExpand ? <Remove /> : <AddIcon />}
                    Children
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" justifyContent="space-between">
                    <Typography>
                      <h4 className="registration-h4">Children Information</h4>
                    </Typography>
                  </Box>
                  {fields.map((item, index) => (
                    <div key={item.id} className="row">
                      <ConsentDialog
                        index={index}
                        onAgree={() => {
                          remove(index);
                          setChildIndex(fields.length - 1);
                        }}
                      />
                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].first_name`)}
                          margin="normal"
                          fullWidth
                          label="First Name *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].middle_name`)}
                          margin="normal"
                          fullWidth
                          label="Middle Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].last_name`)}
                          margin="normal"
                          fullWidth
                          label="Last Name *"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="date-top-mrgn">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <Controller
                                name={`childrens[${index}].dob`}
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    {...field}
                                    label="Date of Birth"
                                    value={field.value ? field.value : null}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onChange={(date) => {
                                      // Keep the date as a Moment object
                                      field.onChange(date);
                                    }}
                                  />
                                )}
                              />{" "}
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <Controller
                          name={`childrens.${index}.nakshatram`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <FormControl
                              fullWidth
                              className="dropdown-margin-top"
                              error={fieldState?.error?.message}
                              key={field.value}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Nakshatram
                              </InputLabel>
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Nakshatram"
                                defaultValue={field.value}
                                onChange={async (e) => {
                                  field.onChange(e.target.value);
                                  try {
                                    const responseData =
                                      e.target.value == 0
                                        ? await getApi(apiEndPoints.rashi)
                                        : await postApi(
                                            apiEndPoints.rashibynakshatramid,
                                            {
                                              _id: e.target.value,
                                            }
                                          );

                                    setChildrenRashi((prev) => {
                                      const updatedChildrenRashi = [...prev];
                                      updatedChildrenRashi[index] =
                                        responseData?.data.data;
                                      return updatedChildrenRashi;
                                    });
                                  } catch (error) {
                                    console.error(
                                      "Error fetching state data:",
                                      error
                                    );
                                  }
                                }}
                              >
                                {childnakshatra[index]?.map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                                <MenuItem value="0">unknown</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </div>

                      {/* children rashi */}

                      <div className="col-lg-4 col-md-6">
                        <Controller
                          name={`childrens.${index}.rashi`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <FormControl
                              fullWidth
                              className="dropdown-margin-top"
                              error={fieldState?.error?.message}
                              key={field.value}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Rashi
                              </InputLabel>
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Rashi"
                                defaultValue={field.value}
                                onChange={async (e) => {
                                  field.onChange(e.target.value);
                                  try {
                                    const responseData =
                                      e.target.value == 0
                                        ? await getApi(apiEndPoints.nakshatram)
                                        : await postApi(
                                            apiEndPoints.nakshatrambyrashi,
                                            {
                                              _id: e.target.value,
                                            }
                                          );

                                    // After receiving the response, update the state
                                    setchildNakshatra((prev) => {
                                      const updatedChildrenNakshatra = [
                                        ...prev,
                                      ];
                                      updatedChildrenNakshatra[index] =
                                        responseData?.data?.data;
                                      return updatedChildrenNakshatra;
                                    });
                                  } catch (error) {
                                    console.error(
                                      "Error fetching state data:",
                                      error
                                    );
                                    // Optionally handle error state here
                                  }
                                }}
                              >
                                {childrenrashi[index]?.map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.rashi_name}
                                  </MenuItem>
                                ))}
                                <MenuItem value="0">unknown</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </div>

                      <div className="col-lg-4 col-md-6">
                        <TextField
                          {...register(`childrens[${index}].email`)}
                          margin="normal"
                          fullWidth
                          label="Email"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <Box display="flex" justifyContent="end">
                    <IconButton
                      type="button"
                      color="success"
                      onClick={async () => {
                        append({});
                        if (childIndex != 0) {
                          setValue(`childrens[${childIndex}].first_name`, "");
                          setValue(`childrens[${childIndex}].middle_name`, "");
                          setValue(`childrens[${childIndex}].last_name`, "");
                          setValue(`childrens[${childIndex}].dob`, null);
                          setValue(`childrens[${childIndex}].nakshatram`, null);
                          setValue(`childrens[${childIndex}].rashi`, null);
                          setValue(`childrens[${childIndex}].email`, "");
                        }
                        const result = await Promise.all([
                          getApi(apiEndPoints.nakshatram),
                          getApi(apiEndPoints.rashi),
                        ]);

                        setchildNakshatra((prev) => {
                          const updatedChildrenNakshatra = [...prev];
                          updatedChildrenNakshatra[childnakshatra.length] =
                            result[0]?.data?.data;
                          return updatedChildrenNakshatra;
                        });
                        setChildrenRashi((prev) => {
                          const updatedChildrenRashi = [...prev];
                          updatedChildrenRashi[childrenrashi.length] =
                            result[1]?.data?.data;
                          return updatedChildrenRashi;
                        });
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
            <hr />
            <h4 className="registration-h4">Contact Information</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("address", { required: "Address is required" })}
                  margin="normal"
                  fullWidth
                  label="Street Address *"
                  error={errors?.address?.message}
                  helperText={errors?.address?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  rules={{ required: "Country is required" }}
                  name="country"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select Country *
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Select Country *"
                          onChange={async (e) => {
                            field.onChange(e.target.value);
                            try {
                              const responseData = await postApi(
                                apiEndPoints.state,
                                {
                                  country_id: e.target.value,
                                }
                              );

                              // After receiving the response, update the state
                              setMasterData((prevState) => ({
                                ...prevState,
                                statesData: responseData?.data.data,
                              }));
                            } catch (error) {
                              console.error(
                                "Error fetching state data:",
                                error
                              );
                              // Optionally handle error state here
                            }
                          }}
                        >
                          {masterData?.countryData?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="state"
                  rules={{ required: "State is required" }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Select State *
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Select State *"
                          defaultValue={field.value}
                        >
                          {masterData?.statesData?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("city", { required: "City is required" })}
                  margin="normal"
                  fullWidth
                  label="City *"
                  error={errors?.city?.message}
                  helperText={errors?.city?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("zip_code", {
                    required: "Zip Code is required",
                  })}
                  margin="normal"
                  fullWidth
                  label="Zip Code *"
                  error={errors?.zip_code?.message}
                  helperText={errors?.zip_code?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="home_phone"
                  render={({ field }) => {
                    return (
                      <MuiTelInput
                        {...field}
                        value={mobile.home}
                        onChange={(event, event2) => {
                          handleMobileChange("home", event);
                          field.onChange(+event2?.numberValue);
                        }}
                        defaultCountry="US"
                        sx={{ mt: "16px", mb: "8px" }}
                        fullWidth
                        label="Home Phone"
                        error={errors?.home_phone?.message}
                        helperText={errors?.home_phone?.message}
                      />
                    );
                  }}
                />
              </div>

              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  name="office_phone"
                  render={({ field }) => {
                    return (
                      <MuiTelInput
                        {...field}
                        value={mobile.office}
                        onChange={(event, event2) => {
                          handleMobileChange("office", event);
                          field.onChange(+event2?.numberValue);
                        }}
                        defaultCountry="US"
                        sx={{ mt: "16px", mb: "8px" }}
                        fullWidth
                        label="Office Phone"
                        error={errors?.office_phone?.message}
                        helperText={errors?.office_phone?.message}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6">
                <TextField
                  {...register("secondary_email")}
                  margin="normal"
                  fullWidth
                  label="Alternate Email"
                  error={errors?.secondary_email?.message}
                  helperText={errors?.secondary_email?.message}
                />
              </div>
            </div>
            <hr />
            <div>
              <h4 className="registration-h4">Subscription</h4>
              <FormGroup>
                <FormControlLabel
                  {...register("is_subscribed")}
                  control={<Checkbox />}
                  label="Subscribe Newsletter"
                />
                <FormControlLabel
                  {...register("isGetEmailNotifications")}
                  control={<Checkbox />}
                  label="Get notifications from temple via Email"
                />
                <FormControlLabel
                  {...register("isGetSmsNotifications")}
                  control={<Checkbox />}
                  label="Get notifications from temple via SMS"
                />
              </FormGroup>
            </div>
            <Button
              variant="warning text-white btn-slider slider-btn col-lg-3 col-md-6 mt-3 py-3"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
      </div>
    </>
  );
}

export default Profile;
