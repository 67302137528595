import React from "react";
import "../../pages/templeHistory/templehistory.css";
import { Link } from "react-router-dom";

function TempleMission() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Durga Temple's Mission
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">About Us</Link>
          </li>
          <li className="breadcrumb-item active">Durga Temple's Mission</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple's
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Mission</span>
          </h1>
        </div>

        <div className="row">
          <p>
            The Durga Temple in Lorton, Virginia, aims to provide a traditional
            Hindu worship space while offering religious, humanitarian,
            cultural, and educational resources to those interested in Hinduism.
            Established on April 24, 1999, as the first Bhagwati Durgaji temple
            in the United States, the temple promotes ideals of world peace,
            harmony, spiritual and personal well-being through Hindu faith. It
            emphasizes youth and local community outreach programs,
            collaborating with institutions of other faiths to foster unity.
          </p>
        </div>
      </div>
    </>
  );
}

export default TempleMission;
