import "./rentals.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import RentalBasket from "../../components/common/Basket/RentalBasket";
import RentalItem from "../../components/rentalService/RentalItem";
import { useSelector } from "react-redux";

function Rentals() {
  const [rentalData, setRentalData] = useState();
  const rentCartArr = useSelector((state) => state.rentArr);

  const [purposes, setPurposes] = useState();

  const getEventPurposes = async () => {
    try {
      const response = await getApi(apiEndPoints.getRentalPurpose);
      setPurposes(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getRentalsData = async () => {
    try {
      const response = await getApi(apiEndPoints.getRental);
      setRentalData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRentalsData();
    getEventPurposes();
  }, []);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Rentals</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Rentals</Link>
          </li>
          <li className="breadcrumb-item active">Rentals</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Rentals</span>
          </h1>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-8">
            {rentCartArr?.length > 0
              ? rentCartArr.map((item) => {
                  return (
                    <RentalItem
                      item={item.rawData}
                      key={item._id}
                      cartData={item}
                      purposes={purposes}
                    />
                  );
                })
              : rentalData
              ? rentalData?.map((item) => {
                  return (
                    <RentalItem
                      item={item}
                      key={item._id}
                      purposes={purposes}
                    />
                  );
                })
              : null}
          </div>

          <div className="col-lg-4 col-md-4 rental-basket">
            {/* rental basket  */}
            <RentalBasket />
          </div>
        </div>
      </div>
    </>
  );
}

export default Rentals;
