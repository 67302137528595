import React from "react";
import "./religiousservices.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "react-bootstrap";
import PoojaBasket from "../../../components/common/Basket/PoojaBasket";
import { Link } from "react-router-dom";
import {
  useServicesCommonFunctions,
  useVahanSevasServicesFunctions,
} from "../customHooks";

function VahanaSevas() {
  const { serviceData } = useVahanSevasServicesFunctions();
  const {
    selectedDate,
    quantityCounts,
    setSelectedDate,
    handleOrderPuja,
    decreaseQuantity,
    increaseQuantity,
  } = useServicesCommonFunctions();
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Vahana Sevas</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/servicesattemple">Services</Link>
          </li>
          <li className="breadcrumb-item active">Vahana Sevas</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Vahana Sevas</span>
          </h1>
        </div>
        <div className="row">
          <p>
            <strong>Please Note:</strong> The schedule may be changed due to
            special events held in temple on that day.
          </p>
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="row">
                {serviceData?.map((i) => (
                  <div key={i._id} className="col-lg-4 col-md-6">
                    <div className="religious-services-box">
                      <div className="religious-service-item d-block rounded text-center h-100 p-3">
                        <Link to={`/services/${i.category_slug}/${i.slug}`}>
                          <img
                            className="img-fluid rounded mb-2"
                            src={i.image}
                            alt={i.title}
                          />
                        </Link>
                        <h4 className="mb-0 religious-services-title">
                          {i.title}
                        </h4>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="religious-subtitle">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Select a date"
                                value={selectedDate[i._id]}
                                onChange={(date) => {
                                  setSelectedDate((prevDates) => ({
                                    ...prevDates,
                                    [i._id]: date,
                                  }));
                                }}
                                shouldDisableDate={(date) => {
                                  const currentDate = new Date();
                                  const startDate = new Date(i?.start);
                                  const endDate = new Date(i?.end);

                                  currentDate.setHours(0, 0, 0, 0);

                                  return (
                                    date < currentDate ||
                                    date < startDate ||
                                    date > endDate
                                  );
                                }}
                              />
                            </LocalizationProvider>
                          </div>

                          <div className="religious-subtitle">
                            <div className="input-group quantity mx-auto">
                              <div className="input-group-btn">
                                <button
                                  className="btn btn-sm btn-primary btn-minus"
                                  onClick={() => decreaseQuantity(i._id)}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-sm bg-secondary text-center"
                                value={quantityCounts[i._id] || 1}
                              />
                              <div className="input-group-btn">
                                <button
                                  className="btn btn-sm btn-primary btn-plus"
                                  onClick={() => increaseQuantity(i._id)}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h2 className="religious-subtitle">
                            {/* <span>4th Saturday</span> */}
                            <Button
                              variant="warning text-white btn-slider slider-btn religious-btn"
                              onClick={() =>
                                handleOrderPuja(
                                  i,
                                  "Vahana Sevas",
                                  "services/vahan_sevas"
                                )
                              }
                            >
                              Request Puja
                            </Button>
                          </h2>
                          <h2 className="religious-subtitle">
                            <span>
                              {/* <QueryBuilderIcon className="time-icon" />
                              09:00 */}
                            </span>
                            $ {i.amount}
                          </h2>
                        </div>
                        <div
                          className="service-content-bdr truncate"
                          dangerouslySetInnerHTML={{
                            __html: i?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* basket ------------------------------------------- */}
            <div className="col-lg-3 col-md-4">
              <PoojaBasket />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VahanaSevas;
