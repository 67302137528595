import React, { useEffect, useState } from "react";
import "../panchangam/panchangam.css";
import "./dayschedule.css";
import "./monthlyevents.css";

// import News from "../newsevents/News";
// import Events from "../newsevents/Events";
import moment from "moment-timezone";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Box, Link, Tab, Typography } from "@mui/material";
import Today from "../monthlyevents/Today";
import Monthly from "../monthlyevents/Monthly";
import Weekly from "../monthlyevents/Weekly";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TodayIcon from "@mui/icons-material/Today";

function ScheduleAndUpcommingSection(props) {
  const {
    upcomingEvents,
    todaySchedule,
    handleChange,
    updateDate,
    formatDateForDisplay,
    tab,
    date,
  } = props;

  return (
    <>
      <div className="container-fluid about-temple-bg panchang-btm">
        <div className="container panchang-top-pad">
          <div className="row g-4 mb-4">
            <div className={"col-lg-4 col-md-4"}>
              <div className="text-center h-100 pt-0 panchang">
                <React.Fragment>
                  <div className="title-bg ">
                    <h2>Day Schedule</h2>
                  </div>
                  <div className="sub-title-bg d-flex justify-content-around">
                    <div>
                      <KeyboardDoubleArrowLeftIcon
                        className="arw-btn"
                        onClick={() => updateDate(-1)}
                      />
                    </div>
                    <div>
                      <h2>{formatDateForDisplay(date)}</h2>
                    </div>
                    <div>
                      <KeyboardDoubleArrowRightIcon
                        className="arw-btn"
                        onClick={() => updateDate(1)}
                      />
                    </div>
                  </div>
                  <div className="panchang-content">
                    <div className="text-center h-100 pt-0 panchang">
                      <div className="panchang-content2">
                        <Box
                          sx={{
                            p: 3,
                            maxHeight: "400px",
                            overflowY: "auto",
                            width: "100%",
                          }}
                        >
                          <ul className="dayschedulel-list">
                            {todaySchedule?.map((Schedule, i) => (
                              <li key={i}>
                                <span>{Schedule.time}</span>
                                {Schedule.title}
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>
            <div className={"col-lg-8 col-md-8"}>
              <div className="text-center h-100 pt-0 panchang">
                <React.Fragment>
                  <Box title={tab}>
                    <Box>
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                            }}
                          >
                            <div className="title-bg d-flex justify-content-around align-items-center">
                              <div>
                                <h2>Upcoming Events</h2>
                              </div>
                              <div>
                                <TabList
                                  centered
                                  onChange={handleChange}
                                  aria-label="lab API tabs example"
                                  textColor="blue"
                                  indicatorColor="blue"
                                  sx={{
                                    "& .MuiTabs-indicator": {
                                      backgroundColor: "white",
                                    },
                                    "& .css-1wf8b0h-MuiTabs-flexContainer": {
                                      height: "30px",
                                    },
                                  }}
                                >
                                  <Tab
                                    icon={
                                      <CalendarMonthIcon
                                        sx={{
                                          "&.MuiTab-iconWrapper": {
                                            marginRight: "1px !important",
                                          },
                                        }}
                                      />
                                    }
                                    iconPosition="start"
                                    label="Monthly"
                                    value="Monthly"
                                    sx={{
                                      textTransform: "inherit",
                                      height: "100%",
                                      color: "white",
                                      padding: "10px",
                                      minHeight: "50px",
                                      "&.Mui-selected": { color: "white" },
                                    }}
                                  />

                                  <Tab
                                    icon={
                                      <DateRangeIcon
                                        sx={{
                                          "&.MuiTab-iconWrapper": {
                                            marginRight: "1px !important",
                                          },
                                        }}
                                      />
                                    }
                                    iconPosition="start"
                                    label="Weekly"
                                    value="Weekly"
                                    sx={{
                                      textTransform: "inherit",
                                      height: "100%",
                                      color: "white",
                                      padding: "10px",
                                      minHeight: "50px",
                                      "&.Mui-selected": { color: "white" },
                                    }}
                                  />
                                  <Tab
                                    icon={
                                      <TodayIcon
                                        sx={{
                                          "&.MuiTab-iconWrapper": {
                                            marginRight: "1px !important",
                                          },
                                        }}
                                      />
                                    }
                                    iconPosition="start"
                                    label="Today"
                                    value="Today"
                                    sx={{
                                      textTransform: "inherit",
                                      color: "white",
                                      height: "100%",
                                      padding: "10px",
                                      minHeight: "50px",
                                      "&.Mui-selected": { color: "white" },
                                    }}
                                  />
                                </TabList>
                              </div>
                            </div>
                            <div className="sub-title-bg">
                              <a href="/calenderview">
                                <CalendarMonthIcon /> Calendar View
                              </a>
                            </div>
                          </Box>
                          <Box
                            sx={{
                              maxHeight: "428px",
                              width: "100%",
                              overflowY: "auto",
                              backgroundColor: "#ffffff",
                              height: "428px",
                              width: "100%",
                              "& .actions": {
                                color: "text.secondary",
                              },
                              "& .textPrimary": {
                                color: "text.primary",
                              },
                            }}
                          >
                            <TabPanel value="Today">
                              <Today events={upcomingEvents?.today} />
                            </TabPanel>
                            <TabPanel value="Weekly">
                              <Weekly events={upcomingEvents?.weekly} />
                            </TabPanel>
                            <TabPanel value="Monthly">
                              <Monthly events={upcomingEvents?.monthly} />
                            </TabPanel>
                          </Box>
                        </TabContext>
                      </Box>
                    </Box>
                    <div className="bottom-more">
                      <Typography variant="p">
                        <Link
                          to="/calenderview?view=agenda"
                          className="btn text-white py-2 px-4"
                        >
                          View all
                        </Link>
                      </Typography>
                    </div>
                  </Box>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ScheduleAndUpcommingSection;
