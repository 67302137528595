import * as React from "react";
import { Link } from "react-router-dom";
import "./donation.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import layer1 from "../../../images/layer1.jpg";
import layer2 from "../../../images/layer2.jpg";
import layer3 from "../../../images/layer3.jpg";
import layer4 from "../../../images/layer4.jpg";
import { Button, Typography } from "@mui/material";

const layers = [
  { url: layer1, label: "Level 1" },
  { url: layer2, label: "Level 2" },
  { url: layer3, label: "Level 3" },
  { url: layer4, label: "Level 4" },
];

const names = [
  "Stone E",
  "Stone F",
  "Stone G",
  "Stone H",
  "Stone I",
  "Stone J",
  "Stone K",
  "Stone M",
];

function StoneDonation() {
  const [value, setValue] = React.useState(layer1);

  const [personName, setPersonName] = React.useState([]);
  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Garbhalaya Stone Donation
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Donation</Link>
          </li>
          <li className="breadcrumb-item active">Garbhalaya Stone Donation</li>
        </ul>
      </div>

      <div className="container py-4">
        <div className="stonebg">
          <div className="row">
            <div className="col-lg-6 col-md-6">&nbsp;</div>
            <div className="col-lg-6 col-md-6">
              <div>
                <div>
                  <img src={value} />
                </div>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel shrink htmlFor="select-multiple-native">
                    Choose Layer and Stone
                  </InputLabel>
                  <fieldset className="fieldset">
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={6}>
                        <Select
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                        >
                          {layers.map((value) => {
                            return (
                              <MenuItem value={value.url}>
                                {value.label}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <Select value={names[0]} name="stones">
                            {names.map((option, index) => {
                              return (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        <Typography
                          component="h1"
                          className="display-ttl stone_amount"
                          fullWidth
                          sx={{ mt: 1 }}
                        >
                          Amount : $ 1116
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor="select-multiple-native">
                            Name for Display
                          </InputLabel>
                          <fieldset className="fieldset2">
                            <Box component="form">
                              <Grid item xs={12}>
                                <TextField
                                  autoComplete="given-name"
                                  name="firstName"
                                  fullWidth
                                  id="firstName"
                                  label="First Name"
                                  autoFocus
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  id="lastName"
                                  label="Last Name"
                                  name="lastName"
                                  autoComplete="family-name"
                                />
                              </Grid>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                              >
                                Submit
                              </Button>
                            </Box>
                          </fieldset>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </fieldset>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StoneDonation;

// function Select(props) {
//   const slots = {
//     root: StyledButton,
//     listbox: Listbox,
//     popup: Popup,
//     ...props.slots,
//   };

//   return <BaseSelect {...props} slots={slots} />;
// }

// Select.propTypes = {
//   /**
//    * The components used for each slot inside the Select.
//    * Either a string to use a HTML element or a component.
//    * @default {}
//    */
//   slots: PropTypes.shape({
//     listbox: PropTypes.elementType,
//     popup: PropTypes.elementType,
//     root: PropTypes.elementType,
//   }),
// };

// const blue = {
//   100: "#DAECFF",
// };

// const grey = {
//   100: "#E5EAF2",
// };

// const CustomButton = React.forwardRef(function CustomButton(props, ref) {
//   const { ownerState, ...other } = props;
//   return (
//     <button
//       type="button"
//       {...other}
//       ref={ref}
//       style={{
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "center",
//       }}
//     >
//       <span>{other.children}</span>
//       <UnfoldMoreRoundedIcon />
//     </button>
//   );
// });

// CustomButton.propTypes = {
//   children: PropTypes.node,
//   ownerState: PropTypes.object.isRequired,
// };

// const StyledButton = styled(CustomButton, { shouldForwardProp: () => true })(
//   ({ theme }) => `
//   box-sizing: border-box;
//   min-width:100%;
//   padding: 8px 12px;
//   border-radius: 4px;
//   text-align: left;
//   line-height: 1.5;
//   background: #fff;
//   border: 1px solid #ccc;
//   color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
//   box-shadow: 0px 2px 2px ${
//     theme.palette.mode === "dark" ? grey[900] : grey[50]
//   };

//   transition-property: all;
//   transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//   transition-duration: 120ms;

//   &:hover {
//     background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
//     border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
//   }

//   &.${selectClasses.focusVisible} {
//     outline: 0;
//     border-color: ${blue[400]};
//     box-shadow: 0 0 0 3px ${
//       theme.palette.mode === "dark" ? blue[600] : blue[200]
//     };
//   }

//   & > svg {
//     font-size: 1rem;
//     vertical-align: middle;
//   }
//   `
// );

// const Listbox = styled("ul")(
//   ({ theme }) => `
//   box-sizing: border-box;
//   padding: 6px;
//   margin: 12px 0;
//    min-width:100%;
//   border-radius: 4px;
//   overflow: auto;
//   outline: 0px;
//   background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
//   border: 1px solid #e4e4e4;
//   color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
//   box-shadow: 0px 2px 6px ${
//     theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
//   };
//   `
// );

// const Option = styled(BaseOption)(
//   ({ theme }) => `
//   list-style: none;
//   padding: 8px;
//   border-radius: 8px;
//   cursor: default;

//   &:last-of-type {
//     border-bottom: none;
//   }

//   &.${optionClasses.selected} {
//     background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
//     color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
//   }

//   &.${optionClasses.highlighted} {
//     background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
//     color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
//   }

//   &.${optionClasses.highlighted}.${optionClasses.selected} {
//     background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
//     color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
//   }

//   &:focus-visible {
//     outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
//   }

//   &.${optionClasses.disabled} {
//     color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
//   }

//   &:hover:not(.${optionClasses.disabled}) {
//     background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
//     color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
//   }
//   `
// );

// const Popup = styled("div")`
//   z-index: 1;
// `;
