import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
function NewsLetterSuccess() {
  const { state } = useLocation();
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Newsletter Subscription
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">News Letter</Link>
          </li>
          <li className="breadcrumb-item active">News Letter Successfull</li>
        </ol>
      </div>
      <div className="container py-5">
        <h4 className="mb-5 success-heading">
          Durga Temple Newsletter Subscription
        </h4>
        <p className="mb-4">
          Dear{" "}
          <b>
            {state?.data?.first_name} {state?.data?.last_name}
          </b>
          , Thank you for subscribing to the Durga Temple Newsletter. We are
          delighted to welcome you to our community! If you have any questions
          or would like to learn more about upcoming events and activities at
          Durga Temple, feel free to reach out to us at{" "}
          <a href="mailto:contact@durgatemple.org">contact@durgatemple.org</a>
          Thank you again for your interest and support. We look forward to
          sharing the latest news and updates with you.
        </p>
        <p>
          Regards,
          <br />
          Durga Temple
        </p>
      </div>
    </>
  );
}

export default NewsLetterSuccess;
