import React from "react";
import "./faq.css";
import { Link } from "react-router-dom";

function Faq() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">FAQ</h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/location">Visit Info</Link>
          </li>
          <li className="breadcrumb-item active">Frequently Asked Questions</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Frequently Asked Questions</span>
          </h1>
        </div>

        <div className="row">
          <p className="faq-sub">
            The Durga Temple of Virginia has compiled a list of frequently asked
            questions to provide you with readily available information. We hope
            this resource is helpful to you. If you have further questions,
            please send an email to{" "}
            <Link href="mailto:contact@durgatemple.org">
              contact@durgatemple.org
            </Link>{" "}
            and/or call
            <Link href="tel:703-690-9355"> 703-690-9355</Link>.
          </p>
          <h2 className="temple-title mt-4">1. What are the Temple hours?</h2>
          <p>
            The Durga Temple's opening hours vary seasonally and by day of the
            week. The Temple is generally open from 9:00 AM to 9:00 PM during
            spring and summer, and 9:00 AM to 8:00 PM during winter. For the
            most up-to-date information on Darshan hours and any possible
            changes, please visit the Temple's official website at{" "}
            <Link to="https://durgatemple.org/">www.durgatemple.org</Link>.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            2. What pujas/services are performed at the Temple?
          </h2>
          <p>
            Please refer to{" "}
            <Link to="/servicesattemple">"Services at Temple"</Link> and{" "}
            <Link to="/donation">Donation</Link> where all the pujas and the
            sponsorship amounts are listed.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            3. When can I do an Archana for a deity?
          </h2>
          <p>You can sponsor Archanas online and at the Temple.</p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            4. Which food items can I offer to the deities?
          </h2>
          <p>
            Offerings of food are appreciated, but they are not mandatory.
            Devotees should seek guidance from the Priests or Office Manager on
            appropriate vegetarian offerings, as food items containing egg or
            meat are prohibited. Suitable offerings typically encompass flowers,
            fruits, nuts, or cash. Commercially packaged nuts are also accepted
            and will be used appropriately by the Temple.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            6. Can I have a Hair Offering service for myself or my child?
          </h2>
          <p>
            To schedule a Hair Offering Puja, reach out to the Priests, Office
            Manager, or for more details, go to the Services tab.
            <Link to="/services/personal">Click here</Link>
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            7. What do I need to bring for a Car Puja?
          </h2>
          <p>
            When participating in a Car Puja, please bring four lemons to drive
            your car over as part of the ritual. Additionally, you may bring
            fruits, flowers, nuts, and cash to offer during the puja ceremony.
            These offerings symbolize gratitude and serve as a means of seeking
            blessings for your vehicle and journeys.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            8. Can I request the Temple to stay open just a few minutes longer
            if I am running late?
          </h2>
          <p>
            Temple services will be done on time to the best of our ability.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            9. When is Prasadam available at the Temple?
          </h2>
          <p>
            Bandra Prasad is distributed on Sundays or during festival
            occasions.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            10. Are there any accommodations available at the Temple for
            devotees?
          </h2>
          <p>
            Unfortunately, there are no accommodations available at the Temple
            for devotees.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            11. Does the Temple offer Priest services for personal services such
            as weddings, seemanthams, homams, shraddam, etc.?
          </h2>
          <p>
            Durga Temple provides Priests for individual services. To learn more
            about the specific services available, please visit the “Services
            tab” on the Temple website. If you have any questions or need
            further assistance, feel free to reach out via email at
            <Link href="mailto:contact@durgatemple.org">
              contact@durgatemple.org
            </Link>
            or by phone at <Link href="tel:703-690-9355">703-690-9355</Link>.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            12. Are there any facilities available at the Temple for private
            events?
          </h2>
          <p>
            Yes, we have a 250-capacity Cultural Hall located in the basement.
            Visit the "Rentals" section under "Services" on our website for
            details and costs. To rent a facility, email contact@durgatemple.org
            with your event details and contact information. Our office will
            respond within 48 hours. Please provide multiple date options in
            case your first choice is unavailable. Note that only vegetarian
            food is allowed, and alcoholic beverages are prohibited on Temple
            premises.If no one answers your call, please leave a message, and a
            staff member will return your call as soon as possible.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            13. What are the online links to book personal and other services at
            the Temple?
          </h2>
          <p>
            Go to <Link to="https://durgatemple.org/">www.durgatemple.org</Link>{" "}
            and select “Services”
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            14. What is the process to sponsor Abhishekam?
          </h2>
          <p>
            Payments can be made to sponsor Abhishekam on the Durga Temple
            website. In addition, call the Temple if you are planning to
            participate.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            15. What are the Temple contact details?
          </h2>
          <p>
            <Link to="https://durgatemple.org/contactus">
              www.durgatemple.org
            </Link>
            <br />
            E-mail:{" "}
            <Link href="mailto:contact@durgatemple.org">
              contact@durgatemple.org
            </Link>
            <br />
            Phone number: <Link href="tel:703-690-9355">703-690-9355</Link>
          </p>
          <p>
            Please leave your name, number, and a brief message. If your call is
            not answered immediately, we may be assisting other devotees, and
            will return your call as soon as possible.
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            16. Are we allowed to do the 108 Pradikshana?
          </h2>
          <p>Yes.</p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            17. How do I get a Durga Temple calendar?
          </h2>
          <p>
            The Durga Temple calendars are available to purchase usually at the
            beginning or year until sold out. Purchase at the Manager's Office
            or download it from our website at www.durgatemple.org by looking
            for the Annual Calendar on the main page.{" "}
          </p>
          <hr className="hr-gap2" />
          <h2 className="temple-title">
            18. Privacy and Optional Fields in the New Durga Temple Website
            Registration
          </h2>
          <p>
            We understand the importance of privacy and personal preferences
            when it comes to sharing information. Users are not required to
            provide their Date of Birth (DOB), Gotra, Nakshatra, and Rashi
            during registration on the new Durga Temple website. These fields
            are optional, and users have the freedom to choose whether or not to
            disclose this information. Here's a brief explanation of how each
            field is used when provided:
          </p>
          <p>
            <ul>
              <li>
                &#8226; DOB: This information is used to send birthday blessings
                from the temple and reminders a week before so devotees can plan
                and perform any prayers on their special day. Additionally, it
                helps in planning events for specific age groups and sending
                personal invitations, such as events for seniors or students
                before exam season/Saraswati Puja.
              </li>
              <li>
                &#8226; Gotra/Nakshatra/Rashi: These details are used for
                Sankalp during Pujas or blessings performed by priests.
              </li>
              <li>
                &#8226; Nakshatra/Rashi information is also helpful in sending
                notifications during solar and lunar eclipses when certain
                groups of Rashis may need to perform parihara Puja.
              </li>
            </ul>
          </p>
          <p>
            Rest assured that your privacy is our priority, and we respect your
            decision to share or withhold any of the optional information during
            registration. We hope you enjoy the new Durga Temple website and its
            features.
          </p>
        </div>
      </div>
    </>
  );
}

export default Faq;
