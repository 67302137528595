import React from "react";
import "../../pages/templeTrustees/templetrustees.css";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function Hotels() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Hotels</h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Visit Info</Link>
          </li>
          <li className="breadcrumb-item active">Hotels</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Hotels</span>
          </h1>
        </div>

        <div className="row">
          <Table responsive striped className="trustee">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Holiday Inn Express Lorton, an IHG Hotel 8180 Silverbrook Rd,
                  Lorton, VA 22079
                </td>
                <td>703-643-3100</td>
              </tr>
              <tr>
                <td>
                  Embassy Suites by Hilton Springfield 8100 Loisdale Road
                  Springfield, Virginia 22150 USA
                </td>
                <td> 571-339-2000</td>
              </tr>
              <tr>
                <td>
                  Hilton Springfield 6550 Loisdale Rd, Springfield, VA 22150
                </td>
                <td>703-971-8900</td>
              </tr>
              <tr>
                <td>
                  Courtyard by Marriott Potomac Mills Woodbridge 14300 Crossing
                  Pl, Woodbridge, VA 22192
                </td>
                <td>703-491-4525</td>
              </tr>
              <tr>
                <td>
                  Hilton Garden Inn Woodbridge 2500 Neabsco Common Place
                  Woodbridge, Virginia 22191
                </td>
                <td>703-590-2500</td>
              </tr>
              <tr>
                <td>
                  Wingate by Wyndham Springfield 6550 Loisdale Ct, Springfield,
                  VA 22150
                </td>
                <td>703-664-1576</td>
              </tr>
              <tr>
                <td>
                  Courtyard by Marriott Springfield 6710 Commerce St,
                  Springfield, VA 22150
                </td>
                <td>703-924-7200</td>
              </tr>
              <tr>
                <td>
                  Hilton Alexandria Mark Center 5000 Seminary Rd, Alexandria, VA
                  22311
                </td>
                <td>703-845-1010</td>
              </tr>
              <tr>
                <td>
                  Courtyard by Marriott Potomac Mills Woodbridge 14300 Crossing
                  Pl, Woodbridge, VA 22192
                </td>
                <td>703-491-4525</td>
              </tr>
              <tr>
                <td>
                  SpringHill Suites by Marriott Potomac Mills Woodbridge 14325
                  Crossing Pl, Woodbridge, VA 22192
                </td>
                <td>703-576-9000</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default Hotels;
