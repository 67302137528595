import React from "react";
import { Link } from "react-router-dom";

function Almanac() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Panchang ({" "}
          {new Date().toLocaleDateString("en-US", {
            timeZone: "America/New_York",
            month: "long",
            year: "numeric",
          })}{" "}
          )
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/fullpanchang">Full Panchang</Link>
          </li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="row">
          <iframe
            src="https://www.mypanchang.com/newsite/calembed.php?locid=4758023&th=1"
            height="100%"
            // width="100%"
            style={{ aspectRatio: 16 / 9 }}
            title="my panchang"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Almanac;
