import React from "react";
import "./Checkavailability.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

function CheckAvailabilitySent() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Check Availability
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Check Availability</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Check
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Availability</span>
          </h1>
        </div>
        <div className="row">
          <Container component="main" maxWidth="sm">
            <Box
              component="form"
              noValidate
              sx={{ mt: 3 }}
              align="center"
              className="message-bg p-5"
            >
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  className="mt-3 sent-color"
                >
                  Mail Sent
                </FormLabel>
                <Typography
                  variant="body1"
                  className="mt-1"
                  align="center"
                ></Typography>
                <Typography className="mt-1 mb-4 p-content-color">
                  Your request is submitted to the temple, please wait for
                  response from temple
                </Typography>
                <Grid container className="btn-align">
                  <Button
                    className="cont-btn"
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, p: 2 }}
                    onClick={() => navigate("/")}
                  >
                    Back
                  </Button>
                </Grid>
              </FormControl>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 2,
                }}
              ></Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}

export default CheckAvailabilitySent;
