import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function MeraApne() {
  const [meraApne, setMeraApne] = useState();
  const getMeraApne = async () => {
    const response = await getApi(apiEndPoints.allMeraApne);
    if (response && response.status === 200) {
      setMeraApne(response?.data[0]);
    }
  };

  useEffect(() => {
    getMeraApne();
  }, []);
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Mera-Apne</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Events & Activities</Link>
          </li>
          <li className="breadcrumb-item active">Mera-Apne</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Mera-Apne</span>
          </h1>
        </div>
        <div className="row text-center">
          <div dangerouslySetInnerHTML={{ __html: meraApne?.description }} />
        </div>
      </div>
    </>
  );
}

export default MeraApne;
