import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ReligiousBooks.css";

import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";

import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

function ReligiousBooks() {
  const [religiousBooks, setReligiousBooks] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);

  const getReligiousBooks = async () => {
    const body = {
      category: selectedCategories,
      languages: selectedLanguages,
    };
    const response = await postApi(apiEndPoints.allReligiousBooks, body);
    if (response && response.status === 200) {
      setReligiousBooks(response?.data);
    }
  };
  const getBooksLanguages = async () => {
    const res = await getApi(apiEndPoints.getAllbooksLanguages);
    const data = res?.data?.data.map((item) => item.name);
    setLanguages(data);
  };
  const getBooksCategory = async () => {
    const res = await getApi(apiEndPoints.getAllbooksCategory);
    const data = res?.data?.data.map((item) => item.name);

    setCategories(data);
  };

  useEffect(() => {
    getReligiousBooks();
    getBooksLanguages();
    getBooksCategory();
  }, []);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Religious Texts</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Religious Texts</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">
              Aarti, Bhajan, Chalisa, Mantra, Path, Prayer, Puja, Sloka, Song
            </span>
          </h1>
        </div>
        <div className="row">
          <Box
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            className="mb-4"
          >
            <div>
              <Typography level="title-lg" id="category" mb={2}>
                Category
              </Typography>
              <Box
                role="group"
                aria-labelledby="category"
                sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
              >
                {categories.map((name) => {
                  const checked = selectedCategories.includes(name);
                  return (
                    <Chip
                      key={name}
                      variant="plain"
                      color={checked ? "primary" : "neutral"}
                      startDecorator={
                        checked && (
                          <CheckIcon
                            sx={{ zIndex: 1, pointerEvents: "none" }}
                          />
                        )
                      }
                    >
                      <Checkbox
                        variant="outlined"
                        color={checked ? "primary" : "neutral"}
                        disableIcon
                        overlay
                        label={name}
                        checked={checked}
                        onChange={(event) => {
                          setSelectedCategories((names) =>
                            !event.target.checked
                              ? names.filter((n) => n !== name)
                              : [...names, name]
                          );
                        }}
                      />
                    </Chip>
                  );
                })}
              </Box>
            </div>
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <div>
              <Typography level="title-lg" id="category" mb={2}>
                Language
              </Typography>
              <Box
                role="group"
                aria-labelledby="category"
                sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
              >
                {languages.map((name) => {
                  const checked = selectedLanguages.includes(name);
                  return (
                    <Chip
                      key={name}
                      variant="plain"
                      color={checked ? "primary" : "neutral"}
                      startDecorator={
                        checked && (
                          <CheckIcon
                            sx={{ zIndex: 1, pointerEvents: "none" }}
                          />
                        )
                      }
                    >
                      <Checkbox
                        variant="outlined"
                        color={checked ? "primary" : "neutral"}
                        disableIcon
                        overlay
                        label={name}
                        checked={checked}
                        onChange={(event) => {
                          setSelectedLanguages((names) =>
                            !event.target.checked
                              ? names.filter((n) => n !== name)
                              : [...names, name]
                          );
                        }}
                      />
                    </Chip>
                  );
                })}
              </Box>
            </div>
          </Box>
        </div>
        {/* Button to trigger the API call */}
        <button className="btn m-4 ms-0" onClick={getReligiousBooks}>
          Apply Filter
        </button>

        <div className="row">
          {religiousBooks?.map((row) => (
            <div className="col-lg-3 col-md-6">
              <Link
                key={row?.id}
                className="service-item d-block rounded text-center h-100 p-3"
                to={row.file}
              >
                <img
                  className="img-fluid rounded mb-3"
                  alt={row?.title}
                  src={row?.image}
                />
                <h4 className="mb-0">{row?.title}</h4>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ReligiousBooks;
