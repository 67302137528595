import React from "react";
import "./panchangam.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { capitalize, initial, lowerCase } from "lodash";
import { LineWeight } from "@mui/icons-material";

function Panchangam(props) {
  const { dailyPanchangam } = props;

  return (
    <>
      <div className="container-fluid panchang-btm">
        <div className="container panchang-top-pad">
          <div className="row g-4 mb-5">
            <div>
              <div className="text-center bg-white h-100 pt-0 panchang">
                <div className="title-bg">
                  <h2>Panchangam </h2>
                </div>
                <div className="sub-title-bg">
                  <h2>
                    {new Date().toLocaleDateString("en-US", {
                      timeZone: "America/New_York",
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                    <span>(FAIRFAX, VIRGINIA, AMERICA (UNITED STATES))</span>
                  </h2>
                </div>
                <div className="panchang-content1">
                  <>
                    <React.Fragment>
                      <div className="text-center h-100 pt-0 panchang">
                        {/* <div className="title-bg">
            <h2>Panchangam</h2>
          </div> */}
                        <div className="panchang-content2">
                          <Box
                            sx={{ p: 3, maxHeight: "400px", overflowY: "auto" }}
                          >
                            <div className="pancgan-table">
                              <iframe
                                id="IDpanchang"
                                src="https://www.mypanchang.com/newsite/compactfeed.php?locid=4758023&mode=full&pt=12pt"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                scrolling="auto"
                              ></iframe>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </React.Fragment>
                    <div className="d-flex justify-content-end">
                      <Link
                        className="btn text-white py-2 m-2 px-4 btn-right"
                        to="/fullpanchang"
                      >
                        Full Panchang
                      </Link>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Panchangam;
