import * as React from "react";
import "../../pages/forgetPassword/forgotpassword.css";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiEndPoints } from "../../ApiServices/config";
import toast from "react-hot-toast";
import { postApi } from "../../ApiServices/api";
import { useParams, useNavigate, Link } from "react-router-dom";

function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();

  const resetPasswordValidation = yup.object().shape({
    password: yup
      .string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirm_password: yup
      .string()
      .required("Please Confirm your password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordValidation),
  });

  const onSubmit = async (data) => {
    try {
      // token validation api
      const response = await postApi(apiEndPoints.isValidResetToken, {
        token: params.email_reset_token,
      });
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else {
        data.user = response?.data?.user;
        data.token = params.email_reset_token;
        // reset password api
        const result = await postApi(apiEndPoints.resetPassword, data);
        if (result?.data?.status === 1) {
          toast.success(result?.data?.message);
          navigate("/login");
        }
      }
    } catch (error) {
      toast.error(`Password Reset failed: ${error}`);
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Reset Password</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/login">Login</Link>
          </li>
          <li className="breadcrumb-item active">Devotee Reset Password</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Devotee
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Reset Password</span>
          </h1>
        </div>
        <div className="row">
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Box
                component="form"
                noValidate
                sx={{ mt: 3 }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      {...register("password")}
                      fullWidth
                      required
                      id="password"
                      label="New Password"
                      name="password"
                      className="mb-3"
                      error={!!errors?.password?.message}
                      helperText={errors?.password?.message}
                    />
                    <TextField
                      {...register("confirm_password")}
                      fullWidth
                      required
                      id="confirm_password"
                      label="Confirm Password"
                      name="confirm_password"
                      error={!!errors?.confirm_password?.message}
                      helperText={errors?.confirm_password?.message}
                    />
                  </Grid>
                  <Button
                    className="cont-btn"
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, p: 2 }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
