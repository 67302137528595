import React from "react";
import { Link } from "react-router-dom";

function RegistrationSuccess() {
  const localData = JSON.parse(localStorage.getItem("devoteeData"));

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Registration</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Registration</Link>
          </li>
          <li className="breadcrumb-item active">Registration successfull</li>
        </ol>
      </div>
      <div className="container py-5">
        <p className="mb-4 mt-5">Dear {localData?.first_name},</p>
        <h4 className="mb-5 success-heading">
          Thank you for registering in Durga Temple new website.
        </h4>
        <p>
          Your Devotee Id :{" "}
          <span className="spanp">{localData?.devotee_id}</span>
        </p>
        <p>
          Your User Name : <span className="spanp">{localData?.username}</span>
        </p>
        <p className="mb-5">
          Your Registered Email :{" "}
          <span className="spanp">{localData?.email}</span>
        </p>
        <p className="mb-4">
          Thank you for being part of Durga Temple Community, You will get a
          notification once new website is fully operational
        </p>
        <p>
          Regards,
          <br />
          Durga Temple Management
        </p>
      </div>
    </>
  );
}

export default RegistrationSuccess;
