import React from "react";
import "./TempleExecutiveBoard.css";
import { Table } from "react-bootstrap";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function TempleExecutiveBoard() {
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Current Temple Executive Board
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">About Us</Link>
          </li>
          <li className="breadcrumb-item active">
            Current Temple Executive Board
          </li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Current Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Executive Board</span>
          </h1>
        </div>
        <div className="row">
          <div className="mb-4 text-end">
            <Button variant="contained" color="success" className="me-3">
              Previous Year
            </Button>
            <Button variant="contained">Next Year</Button>
          </div>
          <p>
            You can contact trustees or Exec committee or Committee members by
            email as{" "}
            <a href="mailto:info@durgatemple.org">info@durgatemple.org</a>
            or at the temple phone <a href="tel:703-690-9355">
              703-690-9355
            </a>{" "}
            <br />
          </p>
          <Table responsive striped className="trustee">
            <thead>
              <tr>
                <th>Name</th>
                <th>Designation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mahendra Chudasama</td>
                <td>President</td>
              </tr>
              <tr>
                <td>Monika Chopra</td>
                <td>Vice-President</td>
              </tr>
              <tr>
                <td>Ranjna Sachdeva</td>
                <td>Secretary</td>
              </tr>
              <tr>
                <td>Mini Pillai</td>
                <td>Deputy Treasurer</td>
              </tr>
              <tr>
                <td>Madhulika Singh</td>
                <td>Executive Member</td>
              </tr>
              <tr>
                <td>Sudhir Oberoi</td>
                <td>Executive Member</td>
              </tr>
              <tr>
                <td>Valadi Kannan</td>
                <td>Executive Member</td>
              </tr>
              <tr>
                <td>Manju Sangraula</td>
                <td>Executive Member</td>
              </tr>
              <tr>
                <td>Dr.Archana Vardhan</td>
                <td>Executive Member</td>
              </tr>
              <tr>
                <td>Sapna Rohatgi</td>
                <td>Executive Member</td>
              </tr>
              <tr>
                <td>Chander Sharma</td>
                <td>Executive Member</td>
              </tr>
              <tr>
                <td>Acharya Omkar Sharma</td>
                <td>Temple Priest</td>
              </tr>
              <tr>
                <td>Acharya Shanti Swaroop Sharma</td>
                <td>Temple Priest</td>
              </tr>
              <tr>
                <td>Pandit Murlidhara Bhatta</td>
                <td>Temple Priest</td>
              </tr>
              <tr>
                <td>Bharat Patel</td>
                <td>Temple Manager</td>
              </tr>
            </tbody>
          </Table>
        </div>{" "}
      </div>
    </>
  );
}

export default TempleExecutiveBoard;
