import "../../pages/services/components/religiousservices.css";
import { Button } from "react-bootstrap";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import useLoadMasterDataAllDonation from "./customHooks";

function AllDonations() {
  const {
    masterData,
    donationAmount,
    handleDonationAmount,
    errorMessage,
    handleOrderPuja,
  } = useLoadMasterDataAllDonation();

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Donation</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active">Donation</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Donation</span>
          </h1>
        </div>
        <p>
          <strong>Please Note:</strong> The schedule may be changed due to
          special events held in temple on that day.
        </p>
        <div className="row">
          <div className="col-lg-9 col-md-8">
            <div className="row">
              {masterData?.map((i) => (
                <div key={i._id} className="col-lg-4 col-md-6">
                  <div className="religious-services-box">
                    <div className="religious-service-item d-block rounded text-center h-100 p-3">
                      <Link to={`/donation/${i.slug}`}>
                        <img
                          className="img-fluid rounded mb-2"
                          src={i.image}
                          alt=""
                        />
                      </Link>

                      <h4 className="mb-0 religious-services-title">
                        {i.title}
                      </h4>

                      {/* //----------------------------- */}
                      <div className="d-flex justify-content-between border-bottom mt-auto p-3">
                        <TextField
                          value={donationAmount[i?._id] || ""}
                          onChange={(e) => handleDonationAmount(i, e)}
                          label="Donations in $"
                          id="outlined-size-normal"
                          error={!!errorMessage[i._id]} // Show error if it exists
                          helperText={errorMessage[i._id]} // Show error message
                        />
                      </div>

                      {/* //----------------------------- */}
                      <div className="d-flex justify-content-end my-3">
                        <Button
                          variant="warning text-white btn-slider slider-btn religious-btn"
                          onClick={() => handleOrderPuja(i)}
                          disabled={!!errorMessage[i._id]}
                        >
                          Add to Basket
                        </Button>
                      </div>
                      <div
                        className="service-content-bdr truncate"
                        dangerouslySetInnerHTML={{
                          __html: i?.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* basket ------------------------------------------- */}
          <div className="col-lg-3 col-md-4">
            <PoojaBasket />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDonations;
