import React from "react";
import "./abouttemple.css";
import { Link } from "react-router-dom";

function AboutTemple() {
  return (
    <>
      <div className="container-fluid about-temple-bg pt-4">
        <div className="container pt-4">
          <div className="text-center about-temple-bg">
            <h6 className="section-title text-center text-primary text-uppercase title-tl">
              About
            </h6>
            <h1 className="mb-4">
              <span className="text-secundary">Temple</span>
            </h1>

            <div className="about">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="about-img">
                    <img
                      src="./images/about_bg.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="row about-content-pdg">
                    <div className="col-container">
                      <div className="col2">
                        <div className="about-content">
                          <h2>The Temple</h2>
                          <p>
                            The Durga Temple of Virginia is the first temple in
                            the United States dedicated to Bhagwati Durgaji. It
                            welcomes people of all Hindu faiths to worship
                            together. The temple places great importance on
                            youth involvement, local communities, educational
                            programs, and outreach. It collaborates with other
                            religious institutions to promote peace and unity.
                          </p>
                          <Link to="/thetemple">
                            <button className="btn rounded-pill text-white py-2 px-4">
                              Read more
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col2">
                        <div className="about-content">
                          <h2>History</h2>
                          <p>
                            Since its founding, the Hindu community in the
                            Washington Metropolitan area has reflected the
                            nature and philosophy of attracting immigrants to
                            the United States from all over the world. Buoyed by
                            this country's freedom, liberty, and opportunity,
                            countless people have come here.
                          </p>
                          <Link to="/templehistory">
                            <button className="btn rounded-pill text-white py-2 px-4">
                              Read more
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col2">
                        <div className="about-content">
                          <h2>Durga Temple's Mission</h2>
                          <p>
                            The Durga Temple in Lorton, Virginia, aims to
                            provide a traditional Hindu worship space while
                            offering religious, humanitarian, cultural, and
                            educational resources to those interested in
                            Hinduism. Established on April 24, 1999, as the
                            first Bhagwati Durgaji temple in the United States,
                            the temple promotes ideals of world peace,
                            harmony...
                          </p>
                          <Link to="/mission">
                            <button className="btn rounded-pill text-white py-2 px-4">
                              Read more
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default AboutTemple;
