import React from "react";
import { Link } from "react-router-dom";
import "./prayerbooking.css";

import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/joy/Box";
import Checkbox from "@mui/joy/Checkbox";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";

function PrayerBooking() {
  const [selected, setSelected] = React.useState([]);
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Religious Texts</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Religious Texts</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">
              Prayers/ Bhajans/ Slokas/ Pujas and Aartis
            </span>
          </h1>
        </div>
        <div className="row">
          <Box
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            className="mb-4"
          >
            <div>
              <Typography level="title-lg" id="category" mb={2}>
                Category
              </Typography>
              <Box
                role="group"
                aria-labelledby="category"
                sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
              >
                {["Aartis", "Bhajans", "Prayers", "Pujas", "Slokas"].map(
                  (name) => {
                    const checked = selected.includes(name);
                    return (
                      <Chip
                        key={name}
                        variant="plain"
                        color={checked ? "primary" : "neutral"}
                        startDecorator={
                          checked && (
                            <CheckIcon
                              sx={{ zIndex: 1, pointerEvents: "none" }}
                            />
                          )
                        }
                      >
                        <Checkbox
                          variant="outlined"
                          color={checked ? "primary" : "neutral"}
                          disableIcon
                          overlay
                          label={name}
                          checked={checked}
                          onChange={(event) => {
                            setSelected((names) =>
                              !event.target.checked
                                ? names.filter((n) => n !== name)
                                : [...names, name]
                            );
                          }}
                        />
                      </Chip>
                    );
                  }
                )}
              </Box>
            </div>
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <div>
              <Typography level="title-lg" id="category" mb={2}>
                Language
              </Typography>
              <Box
                role="group"
                aria-labelledby="category"
                sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
              >
                {[
                  "English",
                  "Gujarati",
                  "Hindi",
                  "Kannada",
                  "Malayalam",
                  "Marathi",
                  "Punjabi",
                  "Tamil",
                  "Telugu",
                ].map((name) => {
                  const checked = selected.includes(name);
                  return (
                    <Chip
                      key={name}
                      variant="plain"
                      color={checked ? "primary" : "neutral"}
                      startDecorator={
                        checked && (
                          <CheckIcon
                            sx={{ zIndex: 1, pointerEvents: "none" }}
                          />
                        )
                      }
                    >
                      <Checkbox
                        variant="outlined"
                        color={checked ? "primary" : "neutral"}
                        disableIcon
                        overlay
                        label={name}
                        checked={checked}
                        onChange={(event) => {
                          setSelected((names) =>
                            !event.target.checked
                              ? names.filter((n) => n !== name)
                              : [...names, name]
                          );
                        }}
                      />
                    </Chip>
                  );
                })}
              </Box>
            </div>
          </Box>
        </div>
        <div className="row mt-5 g-4">
          <div className="col-lg-3 col-md-6">
            <Link
              className="service-item d-block rounded text-center h-100 p-3"
              to="/"
            >
              <img
                className="img-fluid rounded mb-3"
                src="./images/prayers/prayer-img1.jpg"
                alt=""
              />
              <h4 className="mb-0">Sundarakandam</h4>
            </Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <Link
              className="service-item d-block rounded text-center h-100 p-3"
              to="/"
            >
              <img
                className="img-fluid rounded mb-3"
                src="./images/prayers/prayer-img2.jpg"
                alt=""
              />
              <h4 className="mb-0">Mahabharata</h4>
            </Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <Link
              className="service-item d-block rounded text-center h-100 p-3"
              to="/"
            >
              <img
                className="img-fluid rounded mb-3"
                src="./images/prayers/prayer-img3.jpg"
                alt=""
              />
              <h4 className="mb-0">Maha Shiva Puranam</h4>
            </Link>
          </div>
          <div className="col-lg-3 col-md-6">
            <Link
              className="service-item d-block rounded text-center h-100 p-3"
              to="/"
            >
              <img
                className="img-fluid rounded mb-3"
                src="./images/prayers/prayer-img4.jpg"
                alt=""
              />
              <h4 className="mb-0">Rukmini kalyanam</h4>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrayerBooking;
