import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Share from "yet-another-react-lightbox/plugins/share";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

export default function PhotoGallerySlider({ open, setOpen, photos, index }) {
  const slides = photos.map((photo) => ({
    src: photo.image,
    share: photo.image,
  }));

  return (
    <Lightbox
      index={index}
      plugins={[
        Captions,
        Counter,
        Fullscreen,
        Share,
        Slideshow,
        Thumbnails,
        Zoom,
      ]}
      open={open}
      carousel={{ preload: 10 }}
      close={() => setOpen(false)}
      counter={{ container: { style: { top: "unset", bottom: 20 } } }}
      slides={slides}
      animation={{
        swipe: 0,
        zoom: 500,
      }}
    />
  );
}
