import { Textarea } from "@mui/joy";
import { Box, Button, Container, Grid } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiEndPoints } from "../../ApiServices/config";
import toast from "react-hot-toast";
import { postApi } from "../../ApiServices/api";

function Cancellation() {
  const params = useParams();

  const schema = yup.object().shape({
    reason: yup.string().required("Cancellation reason is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await postApi(
        `${apiEndPoints.sendRejectionMail}/${params.bookingId}`,
        { reason: data.reason }
      );
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
        reset();
      }
    } catch (error) {
      toast.error(`Password Reset failed: ${error}`);
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Cancellation Reasons
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Cancellation Reasons</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Cancellation
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Reasons</span>
          </h1>
        </div>
        <div className="row">
          <Container component="main" maxWidth="sm">
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Box
                component="form"
                noValidate
                sx={{ mt: 3 }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Textarea
                      {...register("reason")}
                      aria-label="minimum height"
                      minRows={6}
                      placeholder="Cancellation Resons"
                      error={!!errors?.reason?.message}
                      helperText={errors?.reason?.message}
                    />
                    {errors?.reason?.message && (
                      <span className="text-danger">
                        {errors?.reason?.message}
                      </span>
                    )}
                  </Grid>
                  <Button
                    className="cont-btn"
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, p: 2 }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Cancellation;
