import { useNavigate } from "react-router-dom";
import { postApi } from "../../../ApiServices/api";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { apiEndPoints } from "../../../ApiServices/config";
export const useFooterFunctions = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const checkLoggedIn = async () => {
    const isLoggedIn = localStorage.getItem("token");
    if (isLoggedIn) {
      navigate("/volunteer");
    } else {
      toast.error("Please Login First");
    }
  };

  const commingSoon = async () => {
    toast.success("This feature will available soon");
  };

  const onSubmit = async (data) => {
    try {
      data.subscribeBy = "footer";
      const response = await postApi(apiEndPoints.subscribe, data);
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        navigate("/otp", { state: { data } });
        reset();
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(`Registration failed: ${error}`);
    }
  };
  return {
    navigate,
    checkLoggedIn,
    commingSoon,
    onSubmit,
    register,
    handleSubmit,
    errors,
    reset,
  };
};
