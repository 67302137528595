import * as React from "react";
import { Link } from "react-router-dom";
import "./forgotpassword.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiEndPoints } from "../../ApiServices/config";
import toast from "react-hot-toast";
import { postApi } from "../../ApiServices/api";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const navigate = useNavigate();
  const forgotPasswordValidationSchema = yup.object().shape({
    username: yup.string().required("Please enter your username"),
  });
  const {
    watch,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await postApi(apiEndPoints.forgotPassword, data);
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else if (response?.data?.status === 1) {
        toast.success(response?.data?.message);
        navigate("/forgotpasswordsent", {
          state: response.data.message,
        });
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(`Registration failed: , ${error}`);
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Forgot Password</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/login">Login</Link>
          </li>
          <li className="breadcrumb-item active">Forgot Password</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-4">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Devotee
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Forgot Password</span>
          </h1>
        </div>
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container component="main" maxWidth="sm">
              <Typography variant="body1" className="mt-4">
                Enter your Username or Devotee id or your Email below and we'll
                send you a link to reset your password.
              </Typography>
              <Controller
                control={control}
                defaultValue={"username"}
                name="reset_by"
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="login-by-label" className="mt-3">
                      Password Reset by
                    </FormLabel>
                    <RadioGroup {...field} row aria-labelledby="login-by-label">
                      <FormControlLabel
                        value="username"
                        control={<Radio />}
                        label="Username"
                      />
                      <FormControlLabel
                        value="devotee_id"
                        control={<Radio />}
                        label="Devotee Id"
                      />
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 2,
                }}
              >
                <Box sx={{ mt: 3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        {...register("username")}
                        margin="normal"
                        fullWidth
                        label={
                          watch("reset_by") === "email"
                            ? "Please enter your Email"
                            : watch("reset_by") === "devotee_id"
                            ? "Please enter your Devotee Id"
                            : "Please enter your Username"
                        }
                        error={errors?.username?.message}
                        helperText={errors?.username?.message}
                      />
                    </Grid>
                    <Button
                      className="cont-btn"
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, p: 2 }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </form>
        </div>
      </div>
    </>
  );
}
export default ForgotPassword;
