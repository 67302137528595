/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useLayoutEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import "./RecurringDonations.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { apiEndPoints } from "../../ApiServices/config";
import { getApi, postApi } from "../../ApiServices/api";

const localizer = momentLocalizer(moment);
function RecurringDonationsForm() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [amountType, setamountType] = useState("fixedAmount");
  const [duration, setduration] = useState("fixedDuration");
  const [frequency, setFrequency] = useState("day");
  const [quantity, setQuantity] = useState(1);
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [donationsData, setDonationData] = useState();
  const [countries, setCountries] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const {
    watch,
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Functions View Schedule
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Functions to Increment and Decremeant Quantity
  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const getData = async () => {
    const res1 = await getApi(apiEndPoints?.getAllDonations);
    setDonationData(res1?.data?.data);
    const res2 = await getApi(apiEndPoints.countries);
    setCountries(res2?.data?.data);
  };

  function calculateTotalAmount(
    startDate,
    endDate,
    frequency,
    quantity,
    amount
  ) {
    let start, end;
    const durationType = watch("duartionType");
    const durationValue = Number(watch("duration"));

    if (durationType === "fixedDuration") {
      start = new Date(startDate);
      end = new Date(startDate);
      end.setFullYear(start.getFullYear() + durationValue);
    } else if (durationType === "fixedQuantity") {
      start = new Date(startDate);
      end = new Date(startDate);
      switch (frequency) {
        case "day":
          end.setDate(start.getDate() + quantity);
          break;
        case "week":
          end.setDate(start.getDate() + quantity * 7);
          break;
        case "month":
          end.setMonth(start.getMonth() + quantity);
          break;
        case "year":
          end.setFullYear(start.getFullYear() + quantity);
          break;
        default:
          console.error("Invalid frequency provided");
          return;
      }
    } else if (durationType === "customDates") {
      start = new Date(startDate);
      end = new Date(endDate);
    } else {
      console.error("Invalid duration type provided");
      return;
    }

    const diffInMilliseconds = end - start;

    let intervals = 0;

    switch (frequency) {
      case "day":
        intervals = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
        break;
      case "week":
        intervals = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24 * 7));
        break;
      case "month":
        intervals =
          (end.getFullYear() - start.getFullYear()) * 12 +
          (end.getMonth() - start.getMonth());
        if (intervals === 0 && end.getDate() === start.getDate()) {
          intervals = 1;
        } else if (end.getDate() > start.getDate()) {
          intervals += 1;
        }
        break;
      case "year":
        intervals = end.getFullYear() - start.getFullYear();
        if (
          intervals === 0 &&
          end.getDate() === start.getDate() &&
          end.getMonth() === start.getMonth()
        ) {
          intervals = 1;
        } else if (
          end.getMonth() > start.getMonth() ||
          (end.getMonth() === start.getMonth() &&
            end.getDate() > start.getDate())
        ) {
          intervals += 1;
        }
        break;
      default:
        console.error("Invalid frequency provided");
        return;
    }

    setTotalAmount(intervals * amount);
  }

  const onSubmit = async (data) => {
    if (!stripe || !elements) {
      toast.error("Stripe has not been initialized.");
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) {
      toast.error("Please enter valid card details.");
      return;
    }
    let startDate, endDate;
    if (data.duartionType === "fixedDuration") {
      startDate = new Date(data?.startDate);
      endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + Number(data.duration));
    } else if (data.duartionType === "fixedQuantity") {
      startDate = new Date(data.startDate);
      endDate = new Date(startDate);
      switch (data?.frequency) {
        case "day":
          endDate.setDate(startDate.getDate() + quantity);
          break;
        case "week":
          endDate.setDate(startDate.getDate() + quantity * 7);
          break;
        case "month":
          endDate.setMonth(startDate.getMonth() + quantity);
          break;
        case "year":
          endDate.setFullYear(startDate.getFullYear() + quantity);
          break;
        default:
          break;
      }
    } else if (data.duartionType === "customDates") {
      startDate = new Date(data?.startDate);
      endDate = new Date(data?.endDate);
    }

    const payload = {
      paymentMethodId: paymentMethod?.id,
      donationType: data?.donationType,
      paymentType: data?.paymentType,
      frequency: data?.frequency,
      donationAmount:
        data?.paymentType === "single" ? totalAmount : data?.amount,
      startDate: startDate,
      endDate: endDate,
      email: data?.email,
      first_name: data?.first_name,
      last_name: data?.last_name,
      address: data?.address,
      country: data?.country,
      city: data?.city,
      company_name: data?.company_name,
      occasion: data?.occasion,
    };

    const response = await postApi(
      apiEndPoints?.recurringDonationPayment,
      payload
    );
    if (response.status === 200) {
      if (response.data.status === 400) {
        toast.warning(response.data.message);
      } else {
        toast.success("Schedule created sussessfully ");
      }
    }
  };

  const viewShedule = async (data) => {
    let startDate, endDate;

    if (duration === "fixedDuration") {
      startDate = new Date(data?.startDate);
      endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + Number(data?.duration));
    } else if (duration === "fixedQuantity") {
      startDate = new Date(data?.startDate);
      endDate = new Date(startDate);
      switch (data?.frequency) {
        case "day":
          endDate.setDate(startDate.getDate() + quantity - 1);
          break;
        case "week":
          endDate.setDate(startDate.getDate() + (quantity - 1) * 7);
          break;
        case "month":
          endDate.setMonth(startDate.getMonth() + quantity - 1);
          break;
        case "year":
          endDate.setFullYear(startDate.getFullYear() + quantity - 1);
          break;
        default:
          break;
      }
    } else if (duration === "customDates") {
      startDate = new Date(data?.startDate);
      endDate = new Date(data?.endDate);
    }

    const generatedEvents = generateEventDates(
      startDate,
      endDate,
      data.frequency,
      data.amount
    );

    setEvents(generatedEvents);
    handleClickOpen();
  };
  const generateEventDates = (startDate, endDate, frequency, amount) => {
    const events = [];
    let currentDate = new Date(startDate);

    while (currentDate.getTime() <= endDate.getTime()) {
      events.push({
        start: new Date(currentDate),
        end: new Date(currentDate),
        title: `Donation $${amount}`,
      });

      switch (frequency) {
        case "day":
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case "week":
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case "month":
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        case "year":
          currentDate.setFullYear(currentDate.getFullYear() + 1);
          break;
        default:
          break;
      }
    }

    return events;
  };
  const handleChangeAmount = (event) => {
    setamountType(event.target.value);
  };
  const handleChangeDuration = (event) => {
    setduration(event.target.value);
  };

  useEffect(() => {
    const devotee = JSON.parse(localStorage.getItem("devoteeData"));
    reset({
      first_name: devotee?.first_name,
      last_name: devotee?.last_name,
      email: devotee?.email,
      address: devotee?.address,
      country: devotee?.country,
      city: devotee?.city,
    });
    getData();
  }, []);

  useEffect(() => {
    const startDate = watch("startDate");
    const endDate = watch("endDate");
    const frequency = watch("frequency");
    const amount = watch("amount");
    const durationType = watch("duartionType");

    if (startDate && frequency && amount && durationType !== undefined) {
      calculateTotalAmount(startDate, endDate, frequency, quantity, amount);
    }
  }, [
    watch("startDate"),
    watch("endDate"),
    watch("frequency"),
    watch("amount"),
    watch("duartionType"),
    watch("duration"),
    quantity,
  ]);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Recurring Donations
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Services</Link>
          </li>
          <li className="breadcrumb-item active">Recurring Donations</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-1">
            <span className="text-secundary">Recurring Donations</span>
          </h1>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="row">
                <>
                  <div className="container">
                    <Box
                      sx={{
                        "& .MuiTextField-root": {
                          minWidth: "100% !important",
                        },
                        "& .dropdown-margin-top": {
                          marginTop: "1px !important",
                        },
                        "& .accordion-background": {
                          boxShadow: "none !important",
                        },
                        "& .accordion-background::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                    >
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {/* User Details */}
                        <AccordionDetails>
                          <div className="row">
                            <div className="col-lg-3 col-md-3">
                              <TextField
                                {...register("company_name")}
                                fullWidth
                                id="outlined-basic"
                                label="Company Name"
                                variant="outlined"
                              />
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <TextField
                                {...register("email")}
                                margin="normal"
                                fullWidth
                                label="Email *"
                                error={
                                  errors &&
                                  errors.email &&
                                  errors?.email?.message
                                }
                                helperText={
                                  errors &&
                                  errors.email &&
                                  errors?.email?.message
                                }
                              />
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <TextField
                                {...register(`first_name`)}
                                margin="normal"
                                fullWidth
                                label="First Name *"
                                error={errors?.first_name?.message}
                                helperText={errors?.first_name?.message}
                              />
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <TextField
                                {...register(`last_name`)}
                                margin="normal"
                                fullWidth
                                label="Last Name *"
                                error={errors?.last_name?.message}
                                helperText={errors?.last_name?.message}
                              />
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <Controller
                                control={control}
                                name="country"
                                render={({ field, fieldState }) => {
                                  return (
                                    <FormControl
                                      key={field.value}
                                      fullWidth
                                      error={
                                        errors && errors.country ? true : false
                                      }
                                      className="dropdown-margin-top"
                                    >
                                      <InputLabel id="demo-simple-select-label">
                                        Select Country
                                      </InputLabel>
                                      <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        label="Select Country *"
                                        defaultValue={field.value}
                                      >
                                        {countries?.map((item) => (
                                          <MenuItem
                                            key={item._id}
                                            value={item._id}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {errors && errors.country && (
                                        <FormHelperText error>
                                          {errors &&
                                          errors.country &&
                                          errors.country.message
                                            ? errors.country.message
                                            : ""}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  );
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <TextField
                                {...register("city")}
                                fullWidth
                                id="outlined-basic"
                                label="City"
                                variant="outlined"
                                error={
                                  errors && errors.city && errors.city.message
                                    ? errors.city.message
                                    : ""
                                }
                                helperText={
                                  errors && errors.city && errors.city.message
                                    ? errors.city.message
                                    : ""
                                }
                              />
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <TextField
                                {...register("address")}
                                fullWidth
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                error={
                                  errors &&
                                  errors.address &&
                                  errors.address.message
                                    ? errors.address.message
                                    : ""
                                }
                                helperText={
                                  errors &&
                                  errors.address &&
                                  errors.address.message
                                    ? errors.address.message
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                        <div className="container mb-4">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                              <span className="title-p">
                                Recurring Donations :
                              </span>
                            </div>
                            <div className="col-md-3">
                              <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel htmlFor="grouped-native-select">
                                  Select Donation
                                </InputLabel>
                                <Select
                                  native
                                  defaultValue=""
                                  id="grouped-native-select"
                                  label="Select Donation"
                                  {...register(`donationType`)}
                                  error={errors?.service?.message}
                                >
                                  <option aria-label="None" value="" />

                                  {donationsData &&
                                    donationsData.map((item, index) => (
                                      <option value={item.name}>
                                        {item.title}
                                      </option>
                                    ))}
                                </Select>
                                {errors?.pooja_id && (
                                  <FormHelperText error={true}>
                                    {errors.pooja_id.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </div>
                            <div className="col-md-6">
                              {/* occasion */}
                              <div className="container">
                                <div className="row d-flex align-items-center">
                                  <div className="col-md-6">
                                  <span className="title-p">
                                  Occasion :
                              </span>
                                  </div>

                                  <div className="col-md-6">
                                    <FormControl
                                      sx={{ m: 1, minWidth: 200 }}
                                      className="dropdown-margin-top"
                                    >
                                      <InputLabel id="demo-simple-select-label">
                                        Select Ocassion
                                      </InputLabel>
                                      <Select
                                        {...register(`ocassion`)}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Service"
                                        error={errors?.ocassion?.message}
                                      >
                                        <MenuItem value="birthday">
                                          Birthday
                                        </MenuItem>
                                        <MenuItem value="marriage">
                                          Marriage
                                        </MenuItem>
                                        <MenuItem value="wedding_anniversary">
                                          Wedding Anniversary
                                        </MenuItem>
                                        <MenuItem value="others">
                                          Others
                                        </MenuItem>
                                      </Select>
                                      {errors?.ocassion && (
                                        <FormHelperText error={true}>
                                          {errors.ocassion.message}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </div>
                                </div>
                                {watch("ocassion") === "others" ? (
                                  <div className="row d-flex align-items-center">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                      <TextField
                                        {...register(`custom_ocassion`)}
                                        margin="normal"
                                        fullWidth
                                        label="Enter Ocassion"
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* For Amount */}
                        <div className="container mb-4">
                          <div className="row d-flex">
                            <div className="col-md-3">
                              <div>
                                <span className="title-p">Amount :</span>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={amountType}
                                  onChange={handleChangeAmount}
                                >
                                  <div className="col-md-4">
                                    <p className="dur-title">
                                      <FormControlLabel
                                        className="radio-btn-mrgn radio-rgt"
                                        control={<Radio />}
                                        value="fixedAmount"
                                      />
                                      Fixed Amount
                                    </p>
                                  </div>
                                  <div className="col-md-4">
                                    <p className="dur-title">
                                      <FormControlLabel
                                        className="radio-btn-mrgn radio-rgt"
                                        control={<Radio />}
                                        value="customAmount"
                                      />
                                      Custom Amount
                                    </p>
                                  </div>
                                </RadioGroup>
                                {amountType == "fixedAmount" && (
                                  <div className="col-md-4">
                                    <Controller
                                      control={control}
                                      name="amount"
                                      render={({ field }) => (
                                        <RadioGroup
                                          {...field}
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                        >
                                          <FormControlLabel
                                            className="radio-btn-mrgn"
                                            value={51}
                                            control={<Radio />}
                                            label="$51"
                                          />
                                          <FormControlLabel
                                            className="radio-btn-mrgn"
                                            value={101}
                                            control={<Radio />}
                                            label="$101"
                                          />
                                          <FormControlLabel
                                            className="radio-btn-mrgn"
                                            value={1001}
                                            control={<Radio />}
                                            label="$1001"
                                          />
                                          <FormControlLabel
                                            className="radio-btn-mrgn"
                                            value={5001}
                                            control={<Radio />}
                                            label="$5001"
                                          />
                                        </RadioGroup>
                                      )}
                                    />
                                  </div>
                                )}
                                {amountType == "customAmount" && (
                                  <div className="col-md-4 mt-4">
                                    <TextField
                                      {...register(`amount`)}
                                      className="others"
                                      margin="normal"
                                      label="Others $"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* For Frequency */}
                        <div className="container mb-4">
                          <div className="row d-flex">
                            <div className="col-md-3">
                              <div>
                                <span className="title-p">Frequency : </span>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <Controller
                                control={control}
                                name="frequency"
                                render={({ field }) => (
                                  <RadioGroup
                                    {...field}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                      field.onChange(e); // Update form control value
                                      setFrequency(e.target.value); // Set local state
                                    }}
                                    value={field.value} // Ensure value is controlled by form
                                  >
                                    <FormControlLabel
                                      className="radio-btn-mrgn"
                                      value={"day"}
                                      control={<Radio />}
                                      label="Daily"
                                    />
                                    <FormControlLabel
                                      className="radio-btn-mrgn"
                                      value={"week"}
                                      control={<Radio />}
                                      label="Weekly"
                                    />
                                    <FormControlLabel
                                      className="radio-btn-mrgn"
                                      value={"month"}
                                      control={<Radio />}
                                      label="Monthly"
                                    />
                                    <FormControlLabel
                                      className="radio-btn-mrgn"
                                      value={"year"}
                                      control={<Radio />}
                                      label="Yearly"
                                    />
                                  </RadioGroup>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        {/* For Duration */}
                        <div className="container mb-4">
                          <div className="row d-flex">
                            <div className="col-md-3">
                              <div>
                                <span className="title-p">Duration :</span>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <Controller
                                control={control}
                                name="duartionType"
                                render={({ field }) => (
                                  <RadioGroup
                                    {...field}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                      field.onChange(e); // Update form control value
                                      setduration(e.target.value);
                                    }}
                                    value={field.value} // Ensure value is controlled by form
                                  >
                                    <div className="col-md-4">
                                      <p className="dur-title">
                                        <FormControlLabel
                                          className="radio-btn-mrgn radio-rgt"
                                          control={<Radio />}
                                          value="fixedDuration"
                                        />
                                        Fixed Duration
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <p className="dur-title">
                                        <FormControlLabel
                                          className="radio-btn-mrgn radio-rgt"
                                          control={<Radio />}
                                          value="fixedQuantity"
                                        />
                                        Fixed Quantity
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <p className="dur-title">
                                        <FormControlLabel
                                          className="radio-btn-mrgn radio-rgt"
                                          control={<Radio />}
                                          value="customDates"
                                        />
                                        Custom Dates
                                      </p>
                                    </div>
                                  </RadioGroup>
                                )}
                              />
                            </div>
                            
                              <div className="row">
                                <div className="col-md-3" style={{marginRight:14}}></div>
                                {watch("duartionType") === "fixedDuration" && (
                                  <div className="col-md-4 mt-4">
                                    <Controller
                                      control={control}
                                      name="duration"
                                      render={({ field }) => (
                                        <RadioGroup
                                          {...field}
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                        >
                                          <FormControlLabel
                                            className="radio-btn-mrgn"
                                            value={1}
                                            control={<Radio />}
                                            label="1 Year"
                                          />
                                          <FormControlLabel
                                            className="radio-btn-mrgn"
                                            value={3}
                                            control={<Radio />}
                                            label="3 Years"
                                          />
                                        </RadioGroup>
                                      )}
                                    />
                                  </div>
                                )}
                                {watch("duartionType") === "fixedQuantity" && (
                                  <div className="col-md-4 mt-3">
                                    <div>
                                      <IconButton
                                        color="error"
                                        aria-label="decrease quantity"
                                        onClick={decrementQuantity}
                                      >
                                        <RemoveCircle />
                                      </IconButton>
                                      {quantity}
                                      <IconButton
                                        color="success"
                                        aria-label="increase quantity"
                                        onClick={incrementQuantity}
                                      >
                                        <AddCircle />
                                      </IconButton>
                                    </div>
                                  </div>
                                )}
                                {/* starting date  */}
                                <div className="col-md-4 mt-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer components={["DatePicker"]}>
                                      <Controller
                                        control={control}
                                        name={`startDate`}
                                        render={({ field }) => {
                                          return (
                                            <DatePicker
                                              {...field}
                                              label="Start Date"
                                            />
                                          );
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </div>
                                {watch("duartionType") === "customDates" && (
                                  <div className="col-md-4 mt-1">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["DatePicker"]}
                                      >
                                        <Controller
                                          control={control}
                                          name={`endDate`}
                                          render={({ field }) => {
                                            return (
                                              <DatePicker
                                                {...field}
                                                label="End Date"
                                              />
                                            );
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        
                        {/* schedule */}
                        <div className="container mb-4">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                              <div>
                                <span className="title-p">Schedule :</span>{" "}
                              </div>
                            </div>

                            <div className="col-md-4 mb-2 mt-2">
                              <Button
                                onClick={() => {
                                  viewShedule(watch());
                                }}
                              >
                                View Schedule of Donations
                              </Button>
                              <Dialog
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                  style: { maxWidth: "90%", width: "90%" },
                                }}
                              >
                                <DialogTitle>Schedule</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>
                                    Quantity: {quantity}
                                  </DialogContentText>
                                  <div style={{ height: 500, width: "100%" }}>
                                    <Calendar
                                      localizer={localizer}
                                      events={events}
                                      startAccessor="start"
                                      endAccessor="end"
                                      defaultDate={new Date()}
                                    />
                                  </div>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose} color="primary">
                                    Close
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        </div>
                        {/* price */}
                        <div className="container mb-4">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                              <span className="title-p">Donation Amount :</span>
                            </div>

                            <div className="col-md-3">${totalAmount}</div>
                          </div>
                        </div>
                        {/* Card Details */}
                        <div className="container mb-4">
                          <div className="row d-flex align-items-center">
                            <div className="col-md-3">
                              <span className="title-p">Card Details:</span>
                            </div>
                            <div className="col-md-6">
                              <CardElement
                                options={{
                                  style: { base: { fontSize: "16px" } },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* For Amount */}
                        <div className="container mb-4">
                          <h2 class="temple-title mt-5">
                            Do you want a single or a recurring payment? Please
                            choose the payment type
                          </h2>
                          <div className="row d-flex">
                            <div className="col-md-3">
                              <div>
                                <span className="title-p">Payment Type :</span>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <Controller
                                control={control}
                                name="paymentType"
                                render={({ field }) => (
                                  <RadioGroup
                                    {...field}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                      field.onChange(e);
                                    }}
                                    value={field.value}
                                  >
                                    <div className="col-md-4">
                                      <p className="dur-title">
                                        <FormControlLabel
                                          className="radio-btn-mrgn radio-rgt"
                                          control={<Radio />}
                                          value="single"
                                        />
                                        Single Charge
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="dur-title">
                                        <FormControlLabel
                                          className="radio-btn-mrgn radio-rgt"
                                          control={<Radio />}
                                          value="recurring"
                                        />
                                        Recurring Charge
                                      </p>
                                    </div>
                                  </RadioGroup>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="warning text-white btn-slider slider-btn col-lg-3 col-md-6 mt-3 py-3 pay-btn"
                          type="submit"
                        >
                          Payment
                        </Button>
                      </form>
                    </Box>
                  </div>
                </>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="pledge">
                <h3 className="pledge-h3">Pledge Count</h3>
                <h2 className="pledge-h2">150</h2>
                <h4 className="pledge-h4">(Target: 1000)</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RecurringDonations() {
  const stripePromise = loadStripe(
    "pk_test_51Ov90gHRheZN4G232MXqbtyoaPdDuwxnE0YcmhitJws87jxWrE1GLPg10gCbe2OILWfZ22iTtCz8sCL851i62kCe003rhdtm3v"
  );

  return (
    <>
      <Elements stripe={stripePromise}>
        <RecurringDonationsForm />
      </Elements>
    </>
  );
}

export default RecurringDonations;
